import {memo} from "react";
import {
	Pagination,
	Table
} from "antd";
import {
	Image,
	StatusLabel
} from "~/components";
import {
	arrayToMap,
	numberFormat
} from "~/utils";
import {useUtilities} from "../../../../hooks";

function PayTable({items = [], listGroup, onDetail, onManager, onExpense, pagination, onPaginationChange, openModal}) {

	const {utilities} = useUtilities();

	const groupMap = arrayToMap(listGroup);

	const handleDetailClick = (item, service) => {
		onDetail(item, service);
		openModal('detail');
	}

	const handleManagerClick = (item) => {
		onManager(item);
		openModal('manager');
	}

	const handleExpenseClick = (item, type) => {
		onExpense(item, type);
		openModal('expense');
	}

	const columns = [
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", width: '300px', render: (_, item) => (
			<div className="d-flex gap align-items">
				<div className="avatar"><Image src={item.user?.avatar} /></div>
				<div>
					<b className={(item.role == 'salesManager') ? 'color-theme' : undefined }>{item.user?.firstname + ' ' + item.user?.lastname}</b>
					<p>{item.user?.username}</p>
					<p>{(item.groupId != 0) && groupMap[item.groupId]?.name } {<StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>}</p>
				</div>
			</div>
		)},
		{ title: 'Lương cơ bản', dataIndex: 'salary', key: "salary", width: '120px', render: (_, item) => (numberFormat(item?.salary))},
		{ title: 'Ngày công', dataIndex: 'dayWorking', key: "dayWorking", width: '110px', render: (_, item) => (
			<>{item?.dayWorking} / {item?.day}</>
		)},
		{ title: 'Lương thực tế', dataIndex: 'pay', key: "pay", width: '130px', render: (_, item) => (numberFormat(item?.pay))},
		{ title: 'Website', dataIndex: 'website', key: "website", width: '100px', render: (_, item) => (
			<div className="pointer" onClick={() => handleDetailClick(item, 'website')}>{numberFormat(item?.commission?.website)}</div>
		)},
		{ title: 'Host', dataIndex: 'host', key: "host", width: '100px', render: (_, item) => (
			<div className="pointer" onClick={() => handleDetailClick(item, 'host')}>{numberFormat(item?.commission?.host)}</div>
		)},
		{ title: 'SSL', dataIndex: 'ssl', key: "ssl", width: '100px', render: (_, item) => (
			<div className="pointer" onClick={() => handleDetailClick(item, 'ssl')}>{numberFormat(item?.commission?.ssl)}</div>
		)},
		{ title: 'Email', dataIndex: 'Email', key: "Email", width: '100px', render: (_, item) => (
			<div className="pointer" onClick={() => handleDetailClick(item, 'email')}>{numberFormat(item?.commission?.email)}</div>
		)},
		{ title: 'Lương QL', dataIndex: 'manager', key: "manager", width: '130px', render: (_, item) => (
			<div className="pointer" onClick={() => handleManagerClick(item)}>{numberFormat(item?.manager)}</div>
		)},
		{ title: 'Trừ', dataIndex: 'minus', key: "minus", width: '100px', render: (_, item) => (
			<div className="pointer" onClick={() => handleExpenseClick(item, 'minus-wage')}>{numberFormat(item?.minusPay)}</div>
		)},
		{ title: 'Cộng', dataIndex: 'plus', key: "plus", width: '100px', render: (_, item) => (
			<div className="pointer" onClick={() => handleExpenseClick(item, 'plus-wage')}>{numberFormat(item?.plusPay)}</div>
		)},
		{ title: 'Thực lảnh', dataIndex: 'received', key: "received", width: '150px', render: (_, item) => (
			<>{numberFormat(item?.received)}</>
		)},
	];

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:'55vh', x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(PayTable);