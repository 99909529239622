import style from './PersonnelTable.module.scss';
import className from 'classnames/bind';
import { useState } from "react";
import Tippy from '@tippyjs/react/headless';
import {Button, Icon} from "~/components";
import {PopperWrapper} from "~/components/Popper";
import {useCan} from "~/hooks";

const cn = className.bind(style);

function PersonnelAction({item, setItemEdit, openModal}) {

	const can = {
		edit: useCan('personnelEdit'),
		editPay: useCan('personnelPay'),
		editRole: useCan('personnelRole'),
		editRoleDepartment: useCan('personnelRoleDepartment'),
		editLeader: useCan('personnelLeader'),
		switchGroup: useCan('personnelSwitchGroup'),
		resetPass: useCan('personnelPassword'),
		delete: useCan('personnelBlock'),
	}

	const [showActionBox, setShowActionBox] = useState(false);

	const closeActionButton = () => {
		setShowActionBox(false);
	};

	const handleButtonClick = {
		pay: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('pay');
		},
		leader: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('leader');
		},
		switchGroup: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('group');
		},
		resetPass: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('password');
		},
		edit: () => {
			setItemEdit(item);
			openModal('edit');
		},
		delete: () => {
			setItemEdit(item);
			openModal('delete');
		},
		role: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('role');
		},
		card: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('card');
		}
	}

	const renderActionButton = () => {
		return (
			<PopperWrapper className={cn('action-wrapper')}>
				{(can.editRole || can.editRoleDepartment) && <Button leftIcon={Icon.briefcase} onClick={handleButtonClick.role}>Chức Vụ</Button>}
				{can.editLeader && <Button leftIcon={Icon.plusUser} onClick={handleButtonClick.leader}>Đội Nhóm</Button>}
				{can.switchGroup && <Button leftIcon={Icon.switch} onClick={handleButtonClick.switchGroup}>Phân chuyển nhóm</Button>}
				{can.resetPass && <Button leftIcon={Icon.edit} onClick={handleButtonClick.resetPass}>Reset mật khẩu</Button>}
			</PopperWrapper>
		)
	}
	return (
		<div className="table-action d-flex">
			<Button background white leftIcon={Icon.card} onClick={handleButtonClick.card}></Button>
			{can.edit && <Button background blue leftIcon={Icon.edit} onClick={handleButtonClick.edit}></Button>}
			{can.delete && <Button background red noneBorder leftIcon={Icon.delete} onClick={handleButtonClick.delete}></Button>}
			{can.editPay && <Button leftIcon={Icon.landmark} onClick={handleButtonClick.pay}></Button>}
			{
				(can.editLeader || can.switchGroup || can.resetPass) && <Tippy
					interactive
					visible={showActionBox}
					render={renderActionButton}
					onClickOutside={closeActionButton}
					placement="left" offset={[0, 10]}
				><div onClick={() => setShowActionBox(true)} className={cn('action-btn')}>{Icon.menuVertical}</div></Tippy>
			}

		</div>
	)
}

export default PersonnelAction;