import request from "~/utils/http";

const payApi = {
	gets : async (params) => {
		const url = 'pays';
		return await request.get(url, {params});
	},
	revenues : async (params) => {
		const url = `pays/revenues/${params.id}`;
		return await request.get(url, {params});
	},
	manager : async (params) => {
		const url = `pays/manager/${params.id}`;
		return await request.get(url, {params});
	},
	expense : async (params) => {
		const url = `pays/expense/${params.id}`;
		return await request.get(url, {params});
	},
	report : async (params) => {
		const url = `pays/report`;
		return await request.get(url, {params});
	},
};

export default payApi;