import {useState} from "react";
import {
	Button,
	StatusLabel,
	Icon
} from "~/components";
import {Col, Row} from "antd";
import {
	renderDate,
} from "~/utils";
import {
	useCan,
	useUtilities
} from "~/hooks";
import DOMPurify from "dompurify";
import {Comments} from "../../../../components";

function UploadWebsiteFormInfo({item, modalOpen, onClickAssignStatus, onClickDevStatus}) {

	const {utilities} = useUtilities();

	const [loadingAssignStatus, setLoadingAssignStatus] = useState(false);

	const [loadingDevStatus, setLoadingDevStatus] = useState(false);

	const can = {
		edit: useCan('uploadWebsiteEdit'),
		devStatus: useCan('uploadWebsiteDevStatus'),
		assignStatus: useCan('uploadWebsiteAssignStatus'),
	};

	const handleAssignStatusClick = async (status) => {
		setLoadingAssignStatus(true);
		if(!onClickAssignStatus) return;
		await onClickAssignStatus(status, item);
		setLoadingAssignStatus(false);
	}

	const handleDevStatusClick = async (status) => {
		setLoadingDevStatus(true);
		if(!onClickDevStatus) return;
		await onClickDevStatus(status, item);
		setLoadingDevStatus(false);
	}

	const handleEditClick = () => {
		modalOpen('addEdit');
	}


	return (

		<Row className="mb-2" gutter={10}>
			<Col className="gutter-row" span={12}>
				<div className="form">
					<p className="heading">Thông tin</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Tên miền</Col>
						<Col className="gutter-row" span={18}>{`${item.domain}`}</Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Url Demo</Col>
						<Col className="gutter-row" span={18}>{`${item.url}`}</Col>
					</Row>
					{
						item.type == 2 && <Row className="mb-2" gutter={10}>
							<Col className="gutter-row" span={6}>Mở đến</Col>
							<Col className="gutter-row" span={18}>{renderDate(item.deadline)}</Col>
						</Row>
					}
					<p className="heading">Website</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Loại</Col>
						<Col className="gutter-row" span={18}>
							{(item.websiteType == 1) && <b>Website công ty</b>}
							{(item.websiteType == 2) && <b>Website khách hàng</b>}
						</Col>
					</Row>
					{
						item.websiteType == 1 && <>
							<Row className="mb-2" gutter={10}>
								<Col className="gutter-row" span={6}>Hợp đồng</Col>
								<Col className="gutter-row" span={18}><StatusLabel small type="green" bold>{item.websiteContract.code}</StatusLabel> {`${item.websiteContract.name}`}</Col>
							</Row>
							<Row className="mb-2" gutter={10}>
								<Col className="gutter-row" span={6}>Tình trạng</Col>
								<Col className="gutter-row" span={18}>
									{(item.websiteContract?.id && item.websiteContract.amountRemaining == 0) ?
										<p className="color-green font-bold">Đã thanh toán</p>
										:
										<p className="color-red font-bold">Chưa thanh toán</p>
									}
								</Col>
							</Row>
						</>
					}
					{
						item.websiteType == 2 && <Row className="mb-2" gutter={10}>
							<Col className="gutter-row" span={6}>Thông tin</Col>
							<Col className="gutter-row" span={18} style={{wordBreak : 'break-word'}}>
								<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.websiteCustomer) }} />
							</Col>
						</Row>
					}
					<p className="heading">Host</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Loại</Col>
						<Col className="gutter-row" span={18}>
							{(item.hostType == 1) && <b>Host công ty</b>}
							{(item.hostType == 2) && <b>Host khách hàng</b>}
							{(item.hostType == 3) && <b>Host công ty hỗ trợ</b>}
						</Col>
					</Row>
					{
						item.hostType == 1 && <>
							<Row className="mb-2" gutter={10}>
								<Col className="gutter-row" span={6}>Hợp đồng</Col>
								<Col className="gutter-row" span={18}><StatusLabel small type="red" bold>{item.hostContract.code}</StatusLabel> {`${item.hostContract.name}`}</Col>
							</Row>
							<Row className="mb-2" gutter={10}>
								<Col className="gutter-row" span={6}>Tình trạng</Col>
								<Col className="gutter-row" span={18}>
									{(item.hostContract?.id && item.hostContract.amountRemaining == 0) ?
										<p className="color-green font-bold">Đã thanh toán</p>
										:
										<p className="color-red font-bold">Chưa thanh toán</p>
									}
								</Col>
							</Row>
							<Row className="mb-2" gutter={10}>
								<Col className="gutter-row" span={6}>Gói dung lượng</Col>
								<Col className="gutter-row" span={18}><StatusLabel small type={utilities.services[item.hostContract.service].color}>{utilities.services[item.hostContract.service].options[item.hostContract.option].label}</StatusLabel></Col>
							</Row>
						</>
					}
					{
						item.hostType == 2 && <Row className="mb-2" gutter={10}>
							<Col className="gutter-row" span={6}>Thông tin</Col>
							<Col className="gutter-row" span={18} style={{wordBreak : 'break-word'}}>
								<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.hostCustomer) }} />
							</Col>
						</Row>
					}
					<p className="heading">Tên miền</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Loại</Col>
						<Col className="gutter-row" span={18}>
							{(item.domainType == 1) && <b>Tên miền công ty</b>}
							{(item.domainType == 2) && <b>Tên miền khách hàng</b>}
						</Col>
					</Row>
					{
						item.domainType == 1 && <>
							<Row className="mb-2" gutter={10}>
								<Col className="gutter-row" span={6}>Hợp đồng</Col>
								<Col className="gutter-row" span={18}>{`${item.domainContract.code} ${item.domainContract.name}`}</Col>
							</Row>
							<Row className="mb-2" gutter={10}>
								<Col className="gutter-row" span={6}>Tình trạng</Col>
								<Col className="gutter-row" span={18}>
									{(item.websiteContract?.id && item.websiteContract.amountRemaining == 0) ?
										<p className="color-green font-bold">Đã thanh toán</p>
										:
										<p className="color-red font-bold">Chưa thanh toán</p>
									}
								</Col>
							</Row>
						</>
					}
					{
						item.domainType == 2 && <Row className="mb-2" gutter={10}>
							<Col className="gutter-row" span={6}>Thông tin</Col>
							<Col className="gutter-row" span={18} style={{wordBreak : 'break-word'}}>
								<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.domainCustomer) }} />
							</Col>
						</Row>
					}
					<div className="form-group d-flex justify-content-end modal-bottom">
						{can.edit && <Button background blue leftIcon={Icon.edit} onClick={handleEditClick}>Cập nhật</Button>}
						{(can.assignStatus && item.accountingStatus == 'pending') && (<Button loading={loadingAssignStatus} onClick={() => handleAssignStatusClick('confirm')} background green leftIcon={Icon.success}>Duyệt yêu cầu</Button>)}
						{(can.devStatus && item.devStatus == 'up-wait') && (<Button loading={loadingDevStatus} onClick={() => handleDevStatusClick('success')} background green leftIcon={Icon.success}>Đã upload website</Button>)}
					</div>
				</div>
			</Col>
			<Col className="gutter-row" span={12}><Comments type="upload-website" objectId={item.id} /></Col>
		</Row>
	)
}

export default UploadWebsiteFormInfo;