import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import ActionBar from "~/layout/ActionBar";
import {Col, Row, Skeleton} from "antd";

import _ from "lodash"

import {
	RevenueReportTotal,
	RevenueReportGroup,
	RevenueReportSearch
} from "../../components";

import {
	apiError,
	handleRequest
} from "~/utils";

import {
	revenueApi
} from "~/api";

import {groupItemsSelector} from "../../../Personnel/groupSlice";

function RevenueReport() {

	//Group items
	const listGroup = useSelector(groupItemsSelector);

	const [items, setItems] = useState([]);

	const [loading, setLoading] = useState(false);

	const [filter, setFilter] = useState([]);

	//Load data
	useEffect(() => { handleLoad(); }, [filter]);

	const handleLoad = async () => {
		setLoading(true);
		let [error, response] = await handleRequest(revenueApi.report(filter));
		let message = apiError(`Lấy thông tin báo cáo thất bại`, error, response);
		if (!message) {
			setItems(response.data)
		}
		setLoading(false);
	}

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		setFilter(newFilter);
	};

	return (
		<>
			<ActionBar title={'Doanh thu'}></ActionBar>
			<div className={'container'}>
				<div className="content pd-1 pdb-3 form" style={{overflow:'auto'}}>
					<RevenueReportSearch filter={filter} listGroup={listGroup} onChange={handleFilterChange} />
					<Row gutter={10}>
						<Col className="gutter-row" md={16} span={24} lg={18}>
							{loading && <Skeleton />}
							{(!loading && !_.isEmpty(items)) &&
								Object.keys(items.groups).map((itemKey) => {
									let item = items.groups[itemKey];
									return <RevenueReportGroup key={`RevenueReportGroup${itemKey}`} item={item}/>
								})
							}
						</Col>
						<Col className="gutter-row" md={8} span={24} lg={6} >
							<div className="content-shadow pd-1 mt-0">
								{loading && <Skeleton />}
								{(!loading && !_.isEmpty(items)) && <RevenueReportTotal items={items} />}
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default RevenueReport;