import {useState} from "react";
import Tippy from '@tippyjs/react/headless';
import {Tooltip} from "antd";
import {
	Button,
	Icon,
	PopperWrapper
} from "~/components";

function ContractAction({item, setItemEdit, openModal, can}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const buttonHandlers = {
		info: () => {
			setItemEdit(item);
			openModal('info');
		},
		edit: () => {
			setItemEdit(item);
			openModal('addEdit');
			setShowActionBox(false);
		},
		delete: () => {
			setItemEdit(item);
			openModal('delete');
			setShowActionBox(false);
		},
		transfer: () => {
			setItemEdit(item);
			openModal('transfer');
			setShowActionBox(false);
		},
		service: () => {
			setItemEdit(item);
			openModal('service');
			setShowActionBox(false);
		},
		revenue: () => {
			setItemEdit(item);
			openModal('revenue');
			setShowActionBox(false);
		},
	};

	const actionButtons = [
		can.revenue && <Button key="revenue" leftIcon={Icon.plus} onClick={buttonHandlers.revenue}>Thêm doanh thu</Button>,
		can.edit && <Button key="edit" leftIcon={Icon.edit} onClick={buttonHandlers.edit}>Cập nhật thông tin</Button>,
		can.transfer && <Button key="transfer" leftIcon={Icon.switch} onClick={buttonHandlers.transfer}>Chuyển nhân viên</Button>,
		can.delete && <Button key="delete" leftIcon={Icon.delete} onClick={buttonHandlers.delete} outline red noneBorder> Xóa hợp đồng</Button>,
	];

	return (
		<div className="table-action d-flex">
			<Tooltip title="Thông tin"><Button background blue leftIcon={Icon.info} onClick={buttonHandlers.info} /></Tooltip>
			{
				(
					item.amountRemaining == 0
					&& item.serviceId == 0
					&& item.status == 'confirm'
				) && <Tooltip title="Cộng dịch vụ"><Button background green leftIcon={Icon.plus} onClick={buttonHandlers.service} /></Tooltip>}
			{(can.edit || can.delete || can.transfer) && (
				<Tippy
					interactive
					visible={showActionBox}
					onClickOutside={() => setShowActionBox(false)}
					render={() => (
						<PopperWrapper className="action-wrapper" style={{ minWidth: '220px', minHeight: 'auto' }}>
							{actionButtons}
						</PopperWrapper>
					)}
					placement="left"
					offset={[0, -10]}
				>
					<div onClick={() => setShowActionBox(!showActionBox)} className="action-btn">{Icon.menuVertical}</div>
				</Tippy>
			)}
		</div>
	)
}

export default ContractAction;