import {forwardRef} from "react";
import {Editor} from "@tinymce/tinymce-react";

const EditorField = forwardRef(({ name, label, value, defaultValue, onChange, errors, className, ...props }, ref) => {
	if(typeof value == 'undefined') value = defaultValue;
	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<Editor
				name={name}
				apiKey={process.env.REACT_APP_EDITOR_KEY}
				onEditorChange={(newValue) => {
					onChange(newValue);
				}}
				onInit={(evt, editor) => ref.current = editor}
				value={value}
				init={{
					height: 400,
					menubar: false,
					plugins: [
						'a11ychecker',
						'advlist',
						'advcode',
						'advtable',
						'autolink',
						'checklist',
						'export',
						'lists',
						'link',
						'image',
						'charmap',
						'preview',
						'anchor',
						'searchreplace',
						'visualblocks',
						'powerpaste',
						'fullscreen',
						'formatpainter',
						'insertdatetime',
						'media',
						'table',
						'help',
						'wordcount'
					],
					toolbar:
						'undo redo | casechange blocks | bold italic backcolor forecolor | ' +
						'alignleft aligncenter alignright alignjustify | ' +
						'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
					content_style:
						'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
				}}
				{...props}
			/>
			{errors && errors[name]?.message && (<p className="error-message">{errors[name]?.message}</p>)}
		</div>
	)
});

export default EditorField;