import {memo} from "react";
import {Button, Pagination, Table} from "antd";
import _ from "lodash"
import ServiceAction from "./ServiceAction";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {StatusLabel} from "~/components";
import {useDevice, useUtilities, useCan} from "~/hooks";

function ServiceTable({items = [], pagination, onPaginationChange, onChangeFilter, setItemEdit, openModal}) {

	const {utilities} = useUtilities();

	const {isMobile} = useDevice();

	const renderStatus = (status) => {
		return (
			<>
				{status == 'using' && <StatusLabel small type="green">Đang sử dụng</StatusLabel> }
				{status == 'willexpired' && <StatusLabel small type="yellow">Sắp hết hạn</StatusLabel> }
				{status == 'expired' && <StatusLabel small type="red">Hết hạn</StatusLabel> }
				{status == 'stop' && <StatusLabel small type="red">Đã đóng</StatusLabel> }
			</>
		)
	}
	const renderEmail = (extend) => {
		return (
			(extend?.id && extend?.emailSend != 0) ? <StatusLabel small type="green">Đã gửi</StatusLabel> : <StatusLabel small type="red">Chưa gửi</StatusLabel>
		)
	}
	const renderZalo = (extend) => {
		return (
			(extend?.id && extend?.zaloSend != 0) ? <StatusLabel small type="green">Đã gửi</StatusLabel> : <StatusLabel small type="red">Chưa gửi</StatusLabel>
		)
	}
	const renderCall = (supportId, supportTime) => {
		return (
			(supportId == 0)
			? <StatusLabel small type="red">Chưa gọi</StatusLabel>
			: <StatusLabel small type="green">Đã gọi lúc {renderDate(supportTime)}</StatusLabel>
		)
	}
	const renderSubdomain = (domains) => {
		return ((!_.isEmpty(domains)) && domains.map((domain) => {
			return <p>{domain}</p>;
		}))
	}

	const handleCustomerClick = (customerId) => {
		if (!onChangeFilter) return;
		onChangeFilter('customerId', customerId, true);
	}

	let columns = [
		{ title: 'Khách hàng', dataIndex: 'status', key: "status", width: '300px', render: (_, item) => (
			<>
				<p><b>{item.domain}</b></p>
				{renderSubdomain(item.subDomain)}
				<p><Button onClick={() => handleCustomerClick(item.customerId)}><b style={{color : 'var(--primary)'}}>KH{item.customerId}</b></Button> {item.customer?.name}</p>
				{
					isMobile &&
					<>
						{item.user?.id && <p>{`${item.user?.firstname} ${item.user?.lastname}`}</p>}
						{item.userShare?.id && <p>{`${item.userShare?.firstname} ${item.userShare?.lastname}`}</p>}
						<div>
							<StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>
							&nbsp;{item.option.length != 0 && <StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].options[item.option].label}</StatusLabel>}
						</div>
						<b>Phí gia hạn: {numberFormat(item?.price)}</b>
					</>
				}
			</>
		)},
		{ title: 'Phân công', dataIndex: 'user', key: "user", width: '200px', render: (_, item) => (
			<>
				{item.user?.id && <p>{`${item.user?.firstname} ${item.user?.lastname}`}</p>}
				{item.userShare?.id && <p>{`${item.userShare?.firstname} ${item.userShare?.lastname}`}</p>}
			</>
		)},
		{title: 'Phí gia hạn', dataIndex: 'price', key: "price", width: '150px', render: (_, item) => (
			<b>{numberFormat(item?.price)}</b>
		)},
		{title: 'Ngày', dataIndex: 'timeMobile', key: "timeMobile", width: '150px', render: (_, item) => (
			<>
				<p>ĐK: {renderDate(item?.register)}</p>
				<p>HH: {renderDate(item?.expired)}</p>
			</>
		)},
		{title: 'Chăm sóc', dataIndex: 'supportMobile', key: "supportMobile", width: '150px', render: (_, item) => (
			<>
				<p className="mb-1">Email: {renderEmail(item?.extend)}</p>
				<p className="mb-1">Zalo: {renderZalo(item?.extend)}</p>
				<p className="mb-1">Gọi điện: {renderZalo(item?.status)}</p>
			</>
		)},
		{title: 'Dịch vụ', dataIndex: 'service', key: 'service', width: '150px', render:(_, item) => (
			<div>
				<StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>
				&nbsp;{item.option.length != 0 && <StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].options[item.option].label}</StatusLabel>}
			</div>
		)},
		{title: 'Ngày ký', dataIndex: 'register', key: "register", render: (_, item) => (renderDate(item?.register)) },
		{title: 'Ngày hết hạn', dataIndex: 'expired', key: "expired", render: (_, item) => (renderDate(item?.expired)) },
		{title: 'Trạng thái', dataIndex: 'status', key: "status", render: (_, item) => (renderStatus(item?.status)) },
		{title: 'Email', dataIndex: 'email', key: "email", render: (_, item) => (renderEmail(item?.extend)) },
		{title: 'Zalo', dataIndex: 'zalo', key: "zalo", render: (_, item) => (renderZalo(item?.extend)) },
		{title: 'Gọi điện', dataIndex: 'call', key: "call", render: (_, item) => (renderCall(item?.supportId, item?.supportTime)) },
		{title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<ServiceAction
				key={item.id}
				item={item}
				openModal={openModal}
				setItemEdit={setItemEdit}
			/>
		)},
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'user'
				&& currentValue.key != 'service'
				&& currentValue.key != 'register'
				&& currentValue.key != 'expired'
				&& currentValue.key != 'email'
				&& currentValue.key != 'zalo'
				&& currentValue.key != 'call'
				&& currentValue.key != 'price'
			)
		})
	}
	else {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'supportMobile' && currentValue.key != 'timeMobile')
		})
	}

	if(!useCan('serviceExtend')) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'email'
				&& currentValue.key != 'zalo'
				&& currentValue.key != 'call'
			)
		})
	}

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:'55vh', x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(ServiceTable);