import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Modal,
	Button,
	Icon,
} from "~/components";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	useCan,
	useSocket
} from "~/hooks";
import {
	buyServiceApi
} from "~/api";
import {
	buyServiceActions,
	buyServiceErrorSelector,
	buyServiceFilterSelector,
	buyServiceItemsSelector,
	buyServiceLoadingSelector,
	buyServicePaginationSelector
} from "../buyServiceSlice";
import {
	BuyServiceTable,
	BuyServiceSearchBar,
	BuyServiceFormAddEdit,
	BuyServiceFormInfo,
	BuyServiceFormPa
} from "../components";
import _ from "lodash";

function BuyService() {

	const {socket}  = useSocket();

	const can = {
		add: useCan('buyServiceAdd'),
		edit: useCan('buyServiceEdit'),
		status: useCan('buyServiceStatus'),
		delete: useCan('buyServiceDelete'),
	};

	const dispatch  = useDispatch();

	const items     = useSelector(buyServiceItemsSelector);

	const loading   = useSelector(buyServiceLoadingSelector);

	const error     = useSelector(buyServiceErrorSelector);

	const pagination = useSelector(buyServicePaginationSelector);

	const filter    = useSelector(buyServiceFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [loadingDelete, setLoadingDelete] = useState(false);

	//Model
	const [openModal, setOpenModal] = useState({
		add         : false,
		edit        : false,
		delete      : false,
		info        : false,
		status      : false,
		buy         : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(buyServiceActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			data.id = item.id;
			messageAction = 'Cập nhật';
		}
		if (!isNaN(data.birthday)) {
			data.birthday = Math.round(new Date(+data.birthday).getTime() / 1000)
		} else if (data.birthday.search('GMT') != -1) {
			data.birthday = Math.round(new Date(data.birthday).getTime() / 1000)
		}
		let [error, response] = (item?.id) ? await handleRequest(buyServiceApi.update(data)) : await handleRequest(buyServiceApi.add(data));
		let message = apiError(`${messageAction} yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} yêu cầu thành công`});
			if(!item?.id) {
				dispatch(buyServiceActions.add(response.data));
			}
			else {
				dispatch(buyServiceActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Status
	const handleStatus = async (status, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(buyServiceApi.status({id: item.id, status}));
		let message = apiError(`Cập nhật trạng thái yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái yêu cầu thành công`});
			item.status = status;
			dispatch(buyServiceActions.update(item));
			handleModalClose('status')
			if(!_.isEmpty(response.data.notification)) {
				socket.emit("set-notification-user", response.data.notification);
			}
		}
	}

	//Buy PaVietNam
	const handleBuy = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để mua'});
			return;
		}
		data.birthday = strToTime(data.birthday);
		data.uiBirthday = strToTime(data.uiBirthday);
		data.adminBirthday = strToTime(data.adminBirthday);
		data.id = item.id;
		let [error, response] = await handleRequest(buyServiceApi.buy(data));
		let message = apiError(`Mua dịch vụ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Mua dịch vụ thành công`});
			item.status = 'success';
			dispatch(buyServiceActions.update(item));
			handleModalClose('buy')
		}
	}

	//Delete
	const handleDelete = async () => {
		setLoadingDelete(true);
		if (itemEdit?.id == 'undefined') {
			setLoadingDelete(false);
			notification.error({message: 'Lỗi', description: 'Không có thông yêu cầu án để xóa'});
			return;
		}
		if (itemEdit.status == 'confirm' || itemEdit.status == 'success') {
			notification.error({message: 'Lỗi', description: 'Yêu cầu đã duyệt không thể xóa'});
			setLoadingDelete(false);
			return;
		}
		let [error, response] = await handleRequest(buyServiceApi.delete(itemEdit.id));
		let message = apiError(`xóa yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa yêu cầu thành công`});
			dispatch(buyServiceActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
		setLoadingDelete(false);
	}

	//upload
	const handleUpload = async (data) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để cập nhật'});
			return;
		}
		data.id = itemEdit.id;
		let [error, response] = await handleRequest(buyServiceApi.fileUpload(data));
		let message = apiError(`Upload file thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Upload File thành công`});
			itemEdit.attachFile = response.data.attachFile;
			itemEdit.attachFileTotal = response.data.attachFileTotal;
			setItemEdit(itemEdit);
			dispatch(buyServiceActions.update(itemEdit));
		}
	}

	const handleUploadDelete = async (filename) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(buyServiceApi.fileRemove({id: itemEdit.id, filename: filename}));
		let message = apiError(`Xóa file thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Xóa file thành công`});
			itemEdit.attachFile = response.data.attachFile;
			itemEdit.attachFileTotal = response.data.attachFileTotal;
			setItemEdit(itemEdit);
			dispatch(buyServiceActions.update(itemEdit));
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(buyServiceActions.setFilter({...filter, page }));
	}
	const handleSearchChange = (newFilter) => {
		dispatch(buyServiceActions.setFilterWithDebounce(newFilter));
	};
	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(buyServiceActions.setFilter(newFilter));
	};

	//Modal
	const AddEditModal = () => {
		if(!can.add && !can.edit) return null;
		return (
			<Modal title="Mua dịch vụ" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
				<BuyServiceFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
			</Modal>
		)
	}
	const BuyModal = () => {
		if(!itemEdit?.id) return null;
		return (
			<Modal zIndex={99} title="Mua dịch vụ" size="xl" visible={openModal.buy} onCancel={() => {handleModalClose('buy')}}>
				<BuyServiceFormPa item={itemEdit} onHandleSubmit={handleBuy}/>
			</Modal>
		)
	}
	const InfoModal = () => {
		if(!itemEdit?.id) return null;
		return (
			<Modal zIndex={99} title="Thông tin yêu cầu" size="xl" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
				<BuyServiceFormInfo
					item={itemEdit}
					modalOpen={handleModalOpen}
					onClickStatus={handleStatus}
					onUpload={handleUpload}
					onRemoveFile={handleUploadDelete}
				/>
			</Modal>
		)
	}
	const DeleteModal = () => {
		if(!itemEdit?.id || !can.delete) return null;
		return (
			<Modal title="Xóa upload Website" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
				<p>Bạn muốn xóa yêu cầu <b>{itemEdit?.name}</b>?</p>
				<br />
				<div className="d-flex justify-content-end gap">
					<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
					<Button loading={loadingDelete} primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
				</div>
			</Modal>
		)
	}

	return (
		<Fragment>
			<ActionBar title={'Mua dịch vụ'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({}); handleModalOpen('addEdit')}}>Thêm mới</Button>}
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<BuyServiceSearchBar filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange} />
					{items && <BuyServiceTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
					/>}
				</div>
			</div>
			<AddEditModal />
			<InfoModal />
			<DeleteModal />
			<BuyModal />
		</Fragment>
	)
}
export default BuyService;