import request from "~/utils/http";

const userApi = {
	gets : async (params) => {
		const url = 'users';
		return await request.get(url, {params});
	},
	getAll : async (params) => {
		const url = 'users/all';
		return await request.get(url, {params});
	},
	search : async (params) => {
		const url = 'users/search';
		return await request.get(url, {params});
	},
	getCurrent : async () => {
		const url = 'users/current';
		return await request.get(url);
	},
	getById : async (id) => {
		const url = `users/${id}`;
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'users';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `users/${params.id}`;
		return await request.patch(url, params);
	},
	block : async (id) => {
		const url = `users/block/${id}`;
		return await request.post(url);
	},
	groupSwitch : async (params) => {
		const url = `users/switch/group/${params.id}`;
		return await request.post(url, params);
	},
	pay : async (params) => {
		const url = `users/pay/${params.id}`;
		return await request.post(url, params);
	},
	leader : async (params) => {
		const url = `users/leader/${params.id}`;
		return await request.post(url, params);
	},
	avatar : async (params) => {
		const url = `users/avatar`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	password : async (params) => {
		const url = `users/password/${params.id}`;
		return await request.post(url, params);
	},
};

export default userApi;