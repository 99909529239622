import {useEffect, useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import StatusLabel from "~/components/StatusLabel";
import {
	GroupRadioField,
} from "~/components/Forms";
import {
	LIST_PROJECT_STATUS
} from "~/app/constants";

function ProjectFormStatus({item, onHandleSubmit}) {

	const statusOptions = useMemo(() => {
		return [...Object.entries(LIST_PROJECT_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, []);

	const initialValues = {
		status: '',
	}

	const validationSchema = Yup.object().shape({
		status: Yup.string().required('Trạng thái dự án không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.status = item.status;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		onHandleSubmit(data.status);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Trạng thái</p>
			<Controller control={control} name="status" render={({field}) => (
				<GroupRadioField options={statusOptions} errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Chuyển trạng thái</Button>
			</div>
		</form>
	)
}

export default ProjectFormStatus;