import {useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	TextAreaField,
	SelectField
} from "~/components/Forms";
import {useUtilities} from "~/hooks";

function ServiceFormClose({item, onHandleSubmit}) {

	const {utilities} = useUtilities();

	const reasonStopReNewOptions = useMemo(() => {
		return [...Object.entries(utilities.reasonStopReNew).map(([key, reason]) => {
			return {
				value: key,
				label: reason.label
			};
		})];
	}, [utilities.reasonStopReNew]);

	const {control, handleSubmit, formState: { isSubmitting, errors }} = useForm({
		defaultValues: {},
		resolver: yupResolver(Yup.object().shape({
			note: Yup.string().required('Bạn chưa điền lý do đóng dịch vụ'),
		}))
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Lý do đóng dịch vụ</p>
			<Controller control={control} name="reason" render={({field}) => (
				<SelectField placeholder="Lý do đóng dịch vụ" style={{width: '100%'}} mode="multiple" options={reasonStopReNewOptions} errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="note" render={({field}) => (
				<TextAreaField placeholder="Chi tiết lý do" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end mt-2 modal-bottom">
				<Button primary type="submit">Tạo yêu cầu</Button>
			</div>
		</form>
	)
}

export default ServiceFormClose;