import request from "~/utils/http";

const workingDayApi = {
	gets : async (params) => {
		const url = 'working-days';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `working-days/${id}`;
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'working-days';
		return await request.post(url, params);
	},
	attendance : async (params) => {
		const url = 'working-days/attendance';
		return await request.post(url, params);
	},
	updateByUser : async (params) => {
		const url = `working-days/${params.id}/user`;
		return await request.patch(url, params);
	},
};

export default workingDayApi;