import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Icon,
	Loading,
	Button,
	Modal
} from "~/components";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	useCan,
	useSocket,
	useDevice
} from "~/hooks";

import {
	FormSalaryTable,
	FormSalaryAddEdit,
	FormSalaryInfo,
} from "../components";

import {
	formSalaryApi
} from "~/api";

import {
	formSalaryActions,
	formSalaryItemsSelector,
	formSalaryLoadingSelector,
	formSalaryErrorSelector,
	formSalaryPaginationSelector,
	formSalaryFilterSelector
} from "../formSalarySlice";
import _ from "lodash";

function FormSalary() {

	const {isMobile} = useDevice();

	const {socket} = useSocket();

	const canAdd    = useCan('formSalaryAdd');

	const dispatch  = useDispatch();

	const items     = useSelector(formSalaryItemsSelector);

	const loading   = useSelector(formSalaryLoadingSelector);

	const error     = useSelector(formSalaryErrorSelector);

	const pagination = useSelector(formSalaryPaginationSelector);

	const filter    = useSelector(formSalaryFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
		info      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(formSalaryActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		dispatch(formSalaryActions.fetchData(filter));
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin đơn ứng lương để cập nhật'});
				return;
			}
			data.id = item.id;
			messageAction = 'Cập nhật';
		}

		data.time = strToTime(data.time);

		let [error, response] = (item?.id) ? await handleRequest(formSalaryApi.update(data)) : await handleRequest(formSalaryApi.add(data));
		let message = apiError(`${messageAction} đơn xin ứng lương thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} đơn thành công`});
			if(item?.id) {
				dispatch(formSalaryActions.update(response.data.item));
			}
			else {
				dispatch(formSalaryActions.add(response.data.item));
				socket.emit("set-notification-menu-count", response.data.notification);
				socket.emit("set-notification-user", response.data.notification);
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn ứng lương để xóa'});
			return;
		}
		let [error, response] = await handleRequest(formSalaryApi.delete(itemEdit.id));
		let message = apiError(`xóa đơn nghỉ phép thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa đơn ứng lương thành công`});
			dispatch(formSalaryActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//status
	const handleStatus = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn ứng lương để cập nhật'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(formSalaryApi.status(data));
		let message = apiError(`Cập nhật trạng thái đơn ứng lương thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái đơn ứng lương thành công`});
			item.status         = response.data.item.status;
			item.statusCeo      = response.data.item.statusCeo;
			item.statusAssign   = response.data.item.statusAssign;
			item.statusManager  = response.data.item.statusManager;
			item.noteCeo        = response.data.item.noteCeo;
			item.noteAssign     = response.data.item.noteAssign;
			item.noteManager    = response.data.item.noteManager;
			dispatch(formSalaryActions.update(item));
			if(!_.isEmpty(response.data.notification)) {
				socket.emit('set-notification-user', response.data.notification);
			}
			handleModalClose('info')
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(formSalaryActions.setFilter({...filter, page }));
	}

	const ModalAddEdit = () => {
		if(!openModal.addEdit) return null;
		return (
			<Modal title="Đơn ứng lương" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
				<FormSalaryAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
			</Modal>
		)
	}
	const ModalInfo = () => {
		if(!itemEdit?.id || !openModal.info) return null;
		return (
			<Modal title="Thông tin" size="xl" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
				<FormSalaryInfo item={itemEdit} onHandleSubmit={handleStatus} />
			</Modal>
		)
	}
	const ModalDelete = () => {
		if(!itemEdit?.id || !openModal.delete) return null;
		return (
			<Modal title="Xóa đơn" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
				<p>Bạn muốn xóa <b>{itemEdit?.name}</b>?</p>
				<br />
				<div className="d-flex justify-content_end gap">
					<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
					<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
				</div>
			</Modal>
		)
	}

	return (
		<Fragment>
			<ActionBar title={'Đơn ứng lương'}>
				{canAdd && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>{!isMobile && 'Thêm mới'}</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <FormSalaryTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
					/>}
				</div>
				<ModalAddEdit />
				<ModalInfo />
				<ModalDelete />
			</div>
		</Fragment>
	)
}
export default FormSalary;