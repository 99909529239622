import {
	Button, Icon
} from "~/components";
import { Tooltip } from "antd";
import { Fragment } from "react";
import { useCan } from "~/hooks";

function CustomerAction({ item, setItemEdit, openModal, handleStatus}) {
	const { status } = item;

	const can = {
		edit: useCan('customerEdit'),
		editMaster: useCan('customerEditMaster'),
		transfer: useCan('customerTransfer'),
		support: useCan('customerSupport'),
		delete: useCan('customerDelete'),
		status: useCan('customerStatus'),
	};

	const buttonHandlers = {
		status: () => {
			setItemEdit(item);
			handleStatus(item);
		},
		edit: () => {
			setItemEdit(item);
			openModal('addEdit');
		},
		delete: () => {
			setItemEdit(item);
			openModal('delete');
		},
		callSupport: () => {
			setItemEdit(item);
			openModal('callSupport');
		},
		transfer: () => {
			setItemEdit(item);
			openModal('transfer');
		},
	};

	function renderButtonStatus() {
		if (status === "pending") {
			return (
				<Fragment>
					{can.status && (
						<Tooltip title="Duyệt thông tin">
							<Button background green noneBorder leftIcon={Icon.success} onClick={buttonHandlers.status} />
						</Tooltip>
					)}
					{can.delete && (
						<Tooltip title="Xóa khách hàng">
							<Button background red noneBorder leftIcon={Icon.delete} onClick={buttonHandlers.delete} />
						</Tooltip>
					)}
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					{can.status && (
						<Tooltip title="Bỏ duyệt thông tin">
							<Button background white noneBorder leftIcon={Icon.arrowLeft} onClick={buttonHandlers.status} />
						</Tooltip>
					)}
					{can.transfer && (
						<Tooltip title="Chuyển nhân viên">
							<Button background white noneBorder leftIcon={Icon.switch} onClick={buttonHandlers.transfer} />
						</Tooltip>
					)}
				</Fragment>
			);
		}
	}

	return (
		<div className="table-action d-flex">
			{renderButtonStatus()}
			{(can.edit || can.editMaster) && (
				<Tooltip title="Cập nhật thông tin"><Button background blue noneBorder leftIcon={Icon.edit} onClick={buttonHandlers.edit} /></Tooltip>
			)}
			{can.support && (
				<Tooltip title="Gọi điện"><Button background yellow noneBorder leftIcon={Icon.support} onClick={buttonHandlers.callSupport} /></Tooltip>
			)}
		</div>
	);
}

export default CustomerAction;
