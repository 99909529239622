import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionBar from "~/layout/ActionBar";
import {
    Modal,
    Button,
    Loading,
    Icon
} from "~/components";
import {
    CandidateSearchBar,
    CandidateTable,
    CandidateFormAddEdit,
    CandidateFormAddUser
} from "~/features/Candidates/components";
import {
    candidateActions,
    candidateItemsSelector,
    candidateLoadingSelector,
    candidateFilterSelector,
    candidateErrorSelector,
    candidatePaginationSelector
} from "~/features/Candidates/candidatesSlice";
import {notification} from "antd";
import {candidateApi, userApi} from "~/api";
import {
    apiError,
    strToTime,
    handleRequest,
} from "~/utils";
import {
    useCan
} from "~/hooks";

function Candidates() {

    const useCanAdd = useCan('candidatesAdd');

    //Model
    const [openModal, setOpenModal] = useState({
        add   : false,
        delete : false,
        user   : false,
    });

    const handleModalOpen = (modal) => {
        openModal[modal] = true;
        setOpenModal({...openModal})
    }

    const handleModalClose = (modal) => {
        openModal[modal] = false;
        setOpenModal({...openModal});
    }

    const dispatch = useDispatch();

    const items = useSelector(candidateItemsSelector);

    const loading = useSelector(candidateLoadingSelector);

    const error = useSelector(candidateErrorSelector);

    const pagination = useSelector(candidatePaginationSelector);

    const filter = useSelector(candidateFilterSelector);

    const [api, contextHolder] = notification.useNotification();

    const [itemEdit, setItemEdit] = useState({});

    useEffect(() => {
        dispatch(candidateActions.fetchData(filter));
    }, [filter]);

    if (error) {
        notification.error({message: 'Lỗi', description: error});
    }
    //Thêm mới ứng viên click
    const handleBtnAddClick = () => {
        setItemEdit({});
        handleModalOpen('add');
    }

    //Xóa ứng viên
    const handleDeleteItem = async () => {
        let [error, response] = await handleRequest(candidateApi.delete(itemEdit?.id));
        let message = apiError(`Xóa ứng viên thất bại`, error, response);
        if(!message) {
            notification.success({message: 'Thành công', description: `Xóa ứng viên thành công`});
            dispatch(candidateActions.delete(itemEdit?.id));
        }
    }

    //Thêm mới hoặc cập nhật ứng viên
    const handleSaveItem = async (data, isEdit) => {

        let candidateItem = {...data};

        if(!isNaN(candidateItem.birthday)) {
            candidateItem.birthday = Math.round(new Date(+candidateItem.birthday).getTime() / 1000)
        }
        else if(candidateItem.birthday.search('GMT') != -1) {
            candidateItem.birthday = Math.round(new Date(candidateItem.birthday).getTime() / 1000)
        }

        if(!isNaN(candidateItem.appointment)) {
            candidateItem.appointment = Math.round(new Date(+candidateItem.appointment).getTime() / 1000)
        }
        else if(candidateItem.appointment.search('GMT') != -1) {
            candidateItem.appointment = Math.round(new Date(candidateItem.appointment).getTime() / 1000)
        }

        let messageAction = (!isEdit) ? 'Thêm mới' : 'Cập nhật';

        let [error, response] = (!isEdit) ? await handleRequest(candidateApi.add(candidateItem)) : await handleRequest(candidateApi.update(candidateItem));

        let message = apiError(`${messageAction} ứng viên thất bại`, error, response);

        if(!message) {
            notification.success({message: 'Thành công', description: `${messageAction} ứng viên thành công`});
            if(!isEdit) {
                dispatch(candidateActions.add(response.data));
            }
            else {
                dispatch(candidateActions.update(response.data));
            }
            handleModalClose('add');
        }
    }

    //Nhận việc
    const handleAddUser = async (data, item) => {

        if(item?.id == 'undefined') {
            api.error({ message: 'Lỗi', description: 'Không có ứng viên nào được chọn để nhận việc', placement: 'topRight' });
            return;
        }

        if(item.userId != 0) {
            api.error({ message: 'Lỗi', description: 'Ứng viên này đã nhận việc', placement: 'topRight' });
            return;
        }

        let userItem = {...data};

        userItem.birthday = strToTime(userItem.birthday);

        userItem.endTime = strToTime(userItem.endTime);

        userItem.candidateId = item.id;

        let [error, response] = await handleRequest(userApi.add(userItem));
        let message = apiError(`Ứng viên nhận việc thất bại`, error, response);
        if(!message) {
            notification.success({message: 'Thành công', description: `ứng viên nhận việc thành công`});
            item.userId = response?.data?.id;
            dispatch(candidateActions.update(item));
            handleModalClose('user');
        }
    }

    //Filters
    const handlePaginationChange = (page) => {
        dispatch(candidateActions.setFilter({
            ...filter, page
        }));
    }

    const handleSearchChange = (newFilter) => {
        dispatch(candidateActions.setFilterWithDebounce(newFilter));
    };

    const handleFilterChange = (newFilter) => {
        dispatch(candidateActions.setFilter(newFilter));
    };

    //Model
    const ModalAddEdit = () => {
        return (
            <Modal title="Thêm Ứng Viên" visible={openModal.add} onCancel={() => {handleModalClose('add')}}>
                {<CandidateFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />}
            </Modal>
        )
    }
    const ModalAddUser = () => {
        if(!itemEdit?.id) return null;
        return (
            <Modal title="Ứng Viên Nhận Việc" description="Thêm mới nhân viên" visible={openModal.user} onCancel={() => {handleModalClose('user')}}>
                {itemEdit && <CandidateFormAddUser item={itemEdit} onHandleSubmit={handleAddUser} />}
            </Modal>
        )
    }
    const ModalDelete = () => {
        if(!itemEdit?.id) return null;
        return (
            <Modal title="Xóa Ứng Viên" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
                <p>Bạn muốn xóa ứng viên <strong>{itemEdit?.fullName}</strong> ? </p>
                <br />
                <div className="d-flex justify-content_end gap">
                    <Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
                    <Button primary leftIcon={Icon.delete} onClick={handleDeleteItem}> Xóa </Button>
                </div>
            </Modal>
        )
    }

    return (
        <>
            {contextHolder}
            <ActionBar title={'Ứng Viên'}>
                {useCanAdd && <Button outline leftIcon={Icon.plusCircle} onClick={handleBtnAddClick}>Thêm mới</Button>}
            </ActionBar>
            <div className="container">
                <div className="content">
                    {loading && <Loading/>}
                    <CandidateSearchBar filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange}/>
                    {items && <CandidateTable
                        onPaginationChange={handlePaginationChange}
                        setItemEdit={setItemEdit}
                        items={items}
                        pagination={pagination}
                        openModal={handleModalOpen}
                    />}
                </div>
            </div>
            <ModalAddEdit />
            <ModalAddUser />
            <ModalDelete />
        </>
    )
}

export default Candidates;