import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import Loading from "~/components/Loading";
import {
	saleWorkingActions,
	saleWorkingErrorSelector,
	saleWorkingFilterSelector,
	saleWorkingItemsSelector,
	saleWorkingLoadingSelector,
	saleWorkingPaginationSelector
} from "../saleWorkingSlice";
import {
	useCan
} from "~/hooks";

import SaleWorkingTable from "../components/Table/SaleWorkingTable";

function SaleConsultation() {

	const canAdd = useCan('salesEventAdd');

	const canEdit = useCan('salesEventEdit');

	const canAssign = useCan('salesEventAssign');

	const dispatch  = useDispatch();

	const items     = useSelector(saleWorkingItemsSelector);

	const loading   = useSelector(saleWorkingLoadingSelector);

	const error     = useSelector(saleWorkingErrorSelector);

	const pagination = useSelector(saleWorkingPaginationSelector);

	const filter    = useSelector(saleWorkingFilterSelector);

	useEffect(() => {
		dispatch(saleWorkingActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(saleWorkingActions.setFilter({...filter, page }));
	}

	return (
		<Fragment>
			<ActionBar title={'Tư vấn'}></ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{/*{<ExpenseSearchBar filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange} />}*/}
					{items && <SaleWorkingTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
					/>}
				</div>
			</div>
		</Fragment>
	)
}

export default SaleConsultation;