import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectField,
	DateField,
	TextAreaField
} from "~/components/Forms";

function TasksFormEdit({item, project, onHandleSubmit}) {

	const deviceOptions = [
		{value: 'desktop', label: 'Desktop'},
		{value: 'tablet', label: 'Tablet'},
		{value: 'mobile', label: 'Mobile'}
	];

	const initialValues = {
		name: '',
		deadline: new Date(),
		device : 'desktop'
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Nội dung lỗi không được để trống'),
		deadline: Yup.string().required('Bạn chưa chọn deadline'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.name = item.name;
			initialValues.deadline = item.deadline*1000;
			initialValues.device = item.device;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		onHandleSubmit(data, item, project);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin Tasks</p>
			<Controller control={control} name="name" render={({ field }) => (
				<TextAreaField label="Tasks" rows={5} errors={errors} {...field}/>
			)}/>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="device" render={({field}) => (
						<SelectField label="Thiết bị" options={deviceOptions} errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="deadline" render={({field }) => (
						<DateField label="Deadline" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Cập nhật tasks</Button>
			</div>
		</form>
	)
}

export default TasksFormEdit;