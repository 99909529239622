import {FontAwesomeIcon} from "../index";

const Icon = {
	loading: <FontAwesomeIcon icon="fa-light fa-loader" spin />,
	edit: <FontAwesomeIcon icon="fa-light fa-pen" />,
	support: <FontAwesomeIcon icon="fa-light fa-headphones" />,
	switch: <FontAwesomeIcon icon="fa-light fa-exchange" />,
	delete: <FontAwesomeIcon icon="fa-light fa-trash-can" />,
	success: <FontAwesomeIcon icon="fa-light fa-check" />,
	close: <FontAwesomeIcon icon="fa-light fa-xmark" />,
	moto: <FontAwesomeIcon icon="fa-light fa-moped" />,
	commentSlash: <FontAwesomeIcon icon="fa-light fa-comment-slash" />,
	commentCheck: <FontAwesomeIcon icon="fa-light fa-message-check" />,
	menuVertical: <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />,
	copy: <FontAwesomeIcon icon="fa-light fa-copy" />,
	info: <FontAwesomeIcon icon="fa-light fa-info" />,
	plus: <FontAwesomeIcon icon="fa-light fa-plus" />,
	plusCircle: <FontAwesomeIcon icon="fal fa-plus-circle" />,
	plusUser: <FontAwesomeIcon icon="fal fa-plus-circle" />,
	camera: <FontAwesomeIcon icon="fa-solid fa-camera" />,
	shopping: <FontAwesomeIcon icon="fa-light fa-basket-shopping" />,
	print: <FontAwesomeIcon icon="fa-light fa-print" />,
	hourglass: <FontAwesomeIcon icon="fa-light fa-hourglass-half" />,
	hourglassClock: <FontAwesomeIcon icon="fa-light fa-hourglass-clock" />,
	filter: <FontAwesomeIcon icon="fa-light fa-filter" />,
	search: <FontAwesomeIcon icon="fa-thin fa-magnifying-glass" />,
	arrowLeft: <FontAwesomeIcon icon="fa-thin fa-arrow-rotate-left" />,
	arrowRotateLeft: <FontAwesomeIcon icon="fa-thin fa-arrow-rotate-left" />,
	reload: <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />,
	save: <FontAwesomeIcon icon="fa-light fa-floppy-disk" />,
	send: <FontAwesomeIcon icon="fa-light fa-paper-plane" />,
	landmark: <FontAwesomeIcon icon="fa-light fa-landmark" />,
	briefcase: <FontAwesomeIcon icon="fa-light fa-briefcase" />,
	user: <FontAwesomeIcon icon="fa-light fa-user" />,
	users: <FontAwesomeIcon icon="fa-light fa-users" />,
	dola: <FontAwesomeIcon icon="fa-light fa-money-check-dollar-pen" />,
	laptopFile: <FontAwesomeIcon icon="fa-light fa-laptop-file" />,
	upload: <FontAwesomeIcon icon="fa-light fa-upload"/>,
	powerOff: <FontAwesomeIcon icon="fa-light fa-power-off" />,
	calendar: <FontAwesomeIcon icon="fa-light fa-calendar-lines-pen" />,
	headphones: <FontAwesomeIcon icon="fa-light fa-headphones"/>,
	wallet: <FontAwesomeIcon icon="fa-light fa-wallet"/>,
	chart: <FontAwesomeIcon icon="fa-light fa-chart-mixed" />,
	point: <FontAwesomeIcon icon="fa-light fa-hundred-points" />,
	earth: <FontAwesomeIcon icon="fa-light fa-earth-asia" />,
	lock: <FontAwesomeIcon icon="fa-light fa-lock" />,
	logout: <FontAwesomeIcon icon="fa-light fa-arrow-right-from-bracket" />,
	bell: <FontAwesomeIcon icon="fa-duotone fa-bell" />,
	setting: <FontAwesomeIcon icon="fa-light fa-gear" />,
	card: <FontAwesomeIcon icon="fa-light fa-address-card" />,
	server: <FontAwesomeIcon icon="fa-light fa-server" />,
	fingerprint: <FontAwesomeIcon icon="fa-light fa-fingerprint" />,
}

export default Icon;