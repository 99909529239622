import {memo, useState} from "react";
import {Tooltip} from "antd";
import {
	Icon,
	Button
} from "~/components";
import {useCan} from "~/hooks";

function ServiceToDoAction({item = [], handleStatus, handleFinished}) {

	const can = {
		status: useCan('serviceToDoStatus'),
		finished: useCan('serviceToDoFinished'),
	}

	const [loadingStatus, setLoadingStatus] = useState(false);
	const [loadingStatusCancel, setLoadingStatusCancel] = useState(false);

	const [loadingFinished, setLoadingFinished] = useState(false);
	const [loadingFinishedCancel, setLoadingFinishedCancel] = useState(false);

	const buttonHandlers = {
		status: async (item, status) => {
			if(status == 'cancel') {
				setLoadingStatusCancel(true);
			}
			else {
				setLoadingStatus(true);
			}
			await handleStatus(item, status);
			if(status == 'cancel') {
				setLoadingStatusCancel(false);
			}
			else {
				setLoadingStatus(false);
			}
		},
		finished: async (item, status) => {
			if(status == 'cancel') {
				setLoadingFinishedCancel(true);
			}
			else {
				setLoadingFinished(true);
			}
			await handleFinished(item, status);
			if(status == 'cancel') {
				setLoadingFinishedCancel(false);
			}
			else {
				setLoadingFinished(false);
			}
		},
	};

	return (
		<>
			{
				can.status && <>
					{
						item.status != 'confirm' && <Tooltip title="Duyệt">
							<Button loading={loadingStatus} background blue noneBorder small leftIcon={Icon.success} onClick={() => buttonHandlers.status(item, 'confirm')} />
						</Tooltip>
					}
					{
						item.status != 'cancel' && <Tooltip title="Hủy">
							<Button loading={loadingStatusCancel} background red noneBorder small leftIcon={Icon.close} onClick={() => buttonHandlers.status(item, 'cancel')} />
						</Tooltip>
					}
				</>
			}
			{
				can.finished && <>
					{
						item.finished != 'success' && <Tooltip title="Đã hoàn thành">
							<Button loading={loadingFinished} background blue noneBorder small leftIcon={Icon.success} onClick={() => buttonHandlers.finished(item, 'success')} />
						</Tooltip>
					}
					{
						item.finished != 'cancel' && <Tooltip title="Hủy">
							<Button loading={loadingFinishedCancel} background red noneBorder small leftIcon={Icon.close} onClick={() => buttonHandlers.finished(item, 'cancel')} />
						</Tooltip>
					}
				</>
			}
		</>
	)
}

export default memo(ServiceToDoAction);