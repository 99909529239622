import {useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	SelectUserAll,
	DateRangeField
} from "~/components/Forms";
import {
	useCan
} from "~/hooks";
import {
	Button,
	Icon,
	Notification
} from "~/components";
import {strToTime} from "~/utils";

function ConsultationSearchMobile({listGroup, filter, onChange}) {

	const can = {
		viewAll : useCan('salesEventListAll'),
		viewGroup : useCan('salesEventListGroup'),
		viewLeader : useCan('salesEventListLeader'),
	}

	const [searchBar, setSearchBar] = useState(false);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả các nhóm' }, ...Object.entries(listGroup).map(([item]) => {
			return {
				value : item.id,
				label : item.name
			};
		})];
	}, [listGroup]);

	const typeOptions = [
		{value : '', label : 'Tất cả khách'},
		{value : 'new', label : 'Khách mới'},
		{value : 'old', label : 'Khách củ'},
	];

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const onSubmit = (data) => {
		if (!onChange) return;
		data.time[0] = strToTime(data.time[0]);
		data.time[1] = strToTime(data.time[1]);
		const newFilter = {
			...filter,
			time: data.time,
			group: data.group,
			userId: data.userId,
			type: data.type,
			page: 1,
		};
		setSearchBar(false);
		onChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<Controller control={control} name="time" render={({ field }) => (
						<DateRangeField label="Chọn ngày hẹn" placeholder errors={errors} {...field}/>
					)}/>
					{(can.viewAll || can.viewGroup) &&
						<Controller control={control} name="group" render={({field}) => (
							<SelectField label="Chọn nhóm" options={groupOptions} errors={errors} {...field} />
						)}/>
					}
					{(can.viewAll || can.viewGroup || can.viewLeader) &&
						<Controller control={control} name="userId" render={({field}) => (
							<SelectUserAll label="Nhân viên" options={[{value: '', label: 'Tất cả'}]} errors={errors} {...field}/>
						)}/>
					}
					<Controller control={control} name="type" render={({ field }) => (
						<SelectField label="Loại khách hàng" options={typeOptions} errors={errors} {...field} />
					)}/>
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default ConsultationSearchMobile;