import request from "~/utils/http";

const formPaymentApi = {
	gets : async (params) => {
		const url = 'forms/payment';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'forms/payment';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `forms/payment/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `forms/payment/${id}`;
		return await request.delete(url);
	},
	status : async (params) => {
		const url = `forms/payment/status/${params.id}`;
		return await request.post(url, params);
	},
};

export default formPaymentApi;