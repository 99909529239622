import StatusLabel from "~/components/StatusLabel";
import Image from "~/components/Image";
import {Pagination, Table, Tooltip} from "antd";
import WorkingDayAction from "./WorkingDayAction";
import {useDevice, useUtilities} from "~/hooks";
import {memo} from "react";
import dayjs from "dayjs";
import {FontAwesomeIcon, Icon} from "../../../../components";

function WorkingDayTable({filter, items = [], pagination, setItemEdit, onPaginationChange, setOpenModal}) {

	const {utilities} = useUtilities();

	const {isMobile} = useDevice();

	const renderNumberWorking = (number = 0) => {

		if(isNaN(number)) number = parseInt(number);

		const style = {fontSize:'15px'}

		if(number == 0 && number <= 2) {
			return <StatusLabel bold type="green">{number}</StatusLabel>
		}
		if(number >= 3 && number <= 5) {
			return <StatusLabel bold type="yellow" style={style}>{number}</StatusLabel>
		}
		if(number > 5) {
			return <StatusLabel bold type="red" style={style}>{number}</StatusLabel>
		}
		return <StatusLabel bold type="green">{number}</StatusLabel>
	}

	const renderIconWorking = (type) => {

		if(type == 'workOnTime') {
			return <Tooltip title="Đúng giờ"><StatusLabel bold type="green">{Icon.success}</StatusLabel></Tooltip>
		}
		if(type == 'goLate') {
			return <Tooltip title="Đi trể"><StatusLabel bold background type="yellow"><FontAwesomeIcon icon="fa-light fa-person-running" /></StatusLabel></Tooltip>
		}
		if(type == 'halfDayOff') {
			return <Tooltip title="Nghỉ nữa ngày"><StatusLabel bold type="red"><FontAwesomeIcon icon="fa-light fa-minus" /></StatusLabel></Tooltip>
		}
		if(type == 'halfDayOffNo') {
			return <Tooltip title="Nghỉ nữa ngày không phép"><StatusLabel bold type="red"><FontAwesomeIcon icon="fa-light fa-minus" /></StatusLabel></Tooltip>
		}
		if(type == 'dayOff') {
			return <Tooltip title="Nghỉ phép"><StatusLabel background type="red">{Icon.close}</StatusLabel></Tooltip>
		}
		if(type == 'dayOffNo') {
			return <Tooltip title="Nghỉ không phép"><StatusLabel background type="red"><FontAwesomeIcon icon="fa-duotone fa-circle-xmark" /></StatusLabel></Tooltip>
		}

		return null
	}

	const handleTimeKeepingClick = (item) => {
		setItemEdit(item);
		setOpenModal(((prevState) => ({
			...prevState, timeKeeping: true
		})));
	}

	let columns = [
		{ title: 'Nhân viên', dataIndex: 'fullname', key: "fullname", fixed: true, width: '250px', render: (_, item) => (
			<div className="d-flex gap align-items">
				<div className="avatar"><Image src={item?.avatar} /></div>
				<div>
					<b>{item?.firstname + ' ' + item?.lastname}</b>
					<p>{item?.username}</p>
					{isMobile && <StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>}
				</div>
			</div>
		)},
		{ title: 'Chức vụ', dataIndex: 'role', key: "role", width: '200px', render: (_, item) => (<StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>) },

	];

	let date = dayjs();

	if(filter?.time) {
		let time = new Date(filter?.time*1000);
		if(time instanceof Date) {
			date = date.date(1)
			date = date.month(time.getMonth())
			date = date.year(time.getFullYear())
		}
	}

	const lastDay = date.endOf('month');

	const month = date.get('month') + 1;

	for(let day = 1; day <= lastDay.get('date'); day++) {
		let newDate = date.date(day);
		let dayOfWeek = newDate.day();
		if(dayOfWeek == 0) dayOfWeek = 'CN';
		if(dayOfWeek == 1) dayOfWeek = 'T2';
		if(dayOfWeek == 2) dayOfWeek = 'T3';
		if(dayOfWeek == 3) dayOfWeek = 'T4';
		if(dayOfWeek == 4) dayOfWeek = 'T5';
		if(dayOfWeek == 5) dayOfWeek = 'T6';
		if(dayOfWeek == 6) dayOfWeek = 'T7';
		columns.push({
			title: () => {
				return <>{dayOfWeek} <br />{day}/{month}</>
			},
			dataIndex: `day${day}_${month}`,
			key: `day${day}_${month}`,
			width: '70px',
			render: (_, item) => (
				renderIconWorking(item.details[day])
			)
		})
	}
	columns.push({ title: 'Đi trể', dataIndex: 'goLate', key: "goLate", fixed: 'right',width: '60px', render: (_, item) => (
		renderNumberWorking(item?.working.goLate)
	)})
	columns.push({ title: 'Nghỉ', dataIndex: 'dayOff', key: "dayOff", fixed: 'right',width: '65px', render: (_, item) => (
		renderNumberWorking((item?.working.dayOff*1 + item.working.dayOffNo*1 + (item?.working.halfDayOff*1 + item.working.halfDayOffNo*1)/2))
	)})
	columns.push({ title: 'Nghỉ phép năm', dataIndex: 'offPaidHoliDays', key: "offPaidHoliDays", fixed: 'right', width: '65px', render: (_, item) => (
		renderNumberWorking(item?.working.halfPaidHoliDays/2 + item?.working.offPaidHoliDays*1)
	)})
	columns.push({ title: 'Tổng ngày nghỉ', dataIndex: 'totalOff', fixed: 'right', width: '65px', key: "totalOff", render: (_, item) => (
		renderNumberWorking(item?.totalOff)
	)})
	columns.push({ title: '#', dataIndex: 'action', key: "action", fixed: 'right', width: '60px', render: (_, item) => (
		<WorkingDayAction
			item={item}
			onClickTimeKeeping={handleTimeKeepingClick}
		/>
	)})

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'role'
				&& currentValue.key != 'halfDayOff'
				&& currentValue.key != 'dayOff'
				&& currentValue.key != 'goLate'
				&& currentValue.key != 'totalOff'
			)
		})
	}
	else {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'workingDay')
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:'55vh', x: 'max-content' }} pagination={false}/>
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(WorkingDayTable);