import request from "~/utils/http";

const serviceApi = {
	get : async (id) => {
		const url = `services/${id}`;
		return await request.get(url);
	},
	gets : async (params) => {
		const url = 'services';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = `services/${params.id}`;
		return await request.post(url, params);
	},
	close : async (params) => {
		const url = `services/close/${params.id}`;
		return await request.post(url, params);
	},
	extend : async (params) => {
		const url = `services/extend/${params.id}`;
		return await request.post(url, params);
	},
	extendPa : async (id) => {
		const url = `services/extend-pa/${id}`;
		return await request.post(url);
	},
	update : async (params) => {
		const url = `services/update/${params.id}`;
		return await request.post(url, params);
	},
	upgrade : async (params) => {
		const url = `services/upgrade/${params.id}`;
		return await request.post(url, params);
	},
	domains : async (id) => {
		const url = `services/domain/${id}`;
		return await request.get(url);
	},
	hostInfo : async (id) => {
		const url = `services/host/${id}`;
		return await request.get(url);
	},
	hostAdd : async (params) => {
		const url = `services/host/${params.id}`;
		return await request.post(url, params);
	},
	hostEdit : async (params) => {
		const url = `services/host/${params.id}`;
		return await request.patch(url, params);
	},
	updateDomain : async (params) => {
		const url = `services/domain/${params.id}`;
		return await request.post(url, params);
	},
};

export default serviceApi;