import {Fragment, useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import * as Yup from "yup";
import {Col, Row, Timeline} from "antd";
import {
	Button,
	Loading,
	Image,
	StatusLabel,
	Icon
} from "~/components";
import {
	TextAreaField,
	GroupRadioField,
	GroupCheckBoxField
} from "~/components/Forms";
import {
	callSupportApi,
	customerApi
} from "~/api";
import {
	apiError,
	renderDate,
	numberFormat,
	handleRequest
} from "~/utils";
import {useUtilities} from "~/hooks";

function ServiceFormCallSupport({item, onHandleSubmit}) {

	const {utilities} = useUtilities();

	const [timeLineSupport, setTimeLineSupport] = useState([]);

	const [customer, setCustomer] = useState([]);

	useEffect(() => {
		fetchCallSupport().then();
		fetchCustomer().then();
	}, [item]);

	const fetchCallSupport = async () => {
		let [error, response] = await handleRequest(callSupportApi.getByCustomer(item.customerId));
		let message = apiError(`Load lịch sử chăm sóc khách hàng thất bại`, error, response);
		if (!message) setTimeLineSupport(response.data);
	}

	const fetchCustomer = async () => {
		let [error, response] = await handleRequest(customerApi.get(item.customerId));
		let message = apiError(`Load khách hàng thất bại`, error, response);
		if (!message) setCustomer(response.data);
	}

	const ServiceItem = ({service}) => {
		return (
			<>
				<p>{service.domain} hết hạn {renderDate(service?.expired)}</p>
				<StatusLabel small type={utilities.services[service.service].color}>{utilities.services[service.service].label}</StatusLabel>
				&nbsp;{service.option.length != 0 && <StatusLabel small type={utilities.services[service.service].color}>{utilities.services[service.service].options[service.option].label}</StatusLabel>}
				&nbsp;<span className="font-nomarl">Phí gia hạn {numberFormat(service?.price)}</span>
			</>
		)
	}

	const supportTypeOptions = [
		{ value: 1, label: 'Hỗ trợ'},
		{ value: 2, label: 'Gia hạn'},
		{ value: 3, label: 'Ghi chú'},
	];

	const serviceOptions = [{
		value: 'all',
		label: 'Tất cả dịch vụ'
	}];

	if(!_.isEmpty(customer)) {
		for (const [key, service] of Object.entries(customer.services)) {
			serviceOptions.push({
				value : service.id,
				label : <ServiceItem service={service} />
			})
		}
	}

	const initialValues = {
		type: 2,
		message: '',
		services: [item.id]
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Bạn chưa chọn loại gọi điện'),
		message: Yup.string().required('Kết quả cuộc gọi không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const handleSetMessage = (message) => {
		setValue('message', message);
	}

	const renderTimeLineUser = (line) => {

		return (
			<div className="d-flex gap align-items" key={line.user.id}>
				<div className="avatar"><Image src={line?.user?.avatar} /></div>
				<div>
					<b>{line?.user?.firstname + ' ' + line?.user?.lastname}</b>
				</div>
				<div>
					{ (line.type == 1) && <StatusLabel small type="green">Hỗ trợ</StatusLabel>}
					{ (line.type == 2) && <StatusLabel small type="blue">Gia hạn</StatusLabel>}
					{ (line.type == 3) && <StatusLabel small type="white">Ghi chú</StatusLabel>}
				</div>
				<div>Gọi lúc <b>{line.hour}</b></div>
			</div>
		)
	}

	const renderTimeLine = (timeLines) => {
		if(Object.keys(timeLines).length > 0) {
			return (
				<Timeline>
					{
						timeLines.map((timeLine) => {
							return (
								<Fragment key={`timeLine-${timeLine.date}`}>
									<Timeline.Item dot={Icon.hourglass} color="red">{timeLine.date}</Timeline.Item>
									{
										timeLine.list.map((timeMessage) => {
											return (
												<Timeline.Item key={`timeLine-item-${timeMessage.id}`}>
													{renderTimeLineUser(timeMessage)}
													<p style={{color : '#999', marginTop : '10px'}}>{timeMessage.message}</p>
												</Timeline.Item>
											)
										})
									}
								</Fragment>
							)
						})
					}
				</Timeline>
			)
		}
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item, timeLineSupport, setTimeLineSupport);
	}

	const buttonStyle = {'fontSize' : '9px', 'fontWeight' : '400', 'padding' : '5px'};

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Row gutter={10}>
				<Col span={12}>
					<p className="heading">Loại cuộc gọi</p>
					<Controller control={control} name="type" render={({ field }) => (
						<GroupRadioField options={supportTypeOptions} errors={errors} {...field}/>
					)}/>
					<p className="heading">Dịch vụ</p>
					<Controller control={control} name="services" render={({ field }) => (
						<GroupCheckBoxField className="d-block" options={serviceOptions} errors={errors} {...field}/>
					)}/>
					<p className="heading">Kết quả cuộc gọi</p>
					<Controller control={control} name="message" render={({field}) => (
						<TextAreaField placeholder="Nội dung chi tiết cuộc gọi" errors={errors} {...field}/>
					)}/>
					<Button type="button" small white style={buttonStyle} onClick={() => handleSetMessage('Khách không nghe máy')}>Khách không nghe máy</Button>
					<Button type="button" small white style={buttonStyle} onClick={() => handleSetMessage('Khách tắt máy ngang')}>Khách tắt máy ngang</Button>
					<Button type="button" small white style={buttonStyle} onClick={() => handleSetMessage('Khách đang đi đường')}>Khách đang đi đường</Button>
					<Button type="button" small white style={buttonStyle} onClick={() => handleSetMessage('Gọi lại sau')}>Gọi lại sau</Button>
					<div className="form-group d-flex justify-content-end mt-2">
						<Button primary type="submit">Lưu cuộc gọi</Button>
					</div>
				</Col>
				<Col span={12}>
					<p className="heading">Lịch sử cuộc gọi</p>
					{renderTimeLine(timeLineSupport)}
				</Col>
			</Row>

			<hr />
		</form>
	)
}

export default ServiceFormCallSupport;