import className from 'classnames/bind';
import style from './Auth.module.scss';
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {notification} from "antd";
import {AuthLoginForm} from "~/features/Auth/components";
import {authErrorSelector, authLoadingSelector} from "~/features/Auth/authSlice";
import {
	Loading,
} from "~/components";
import utilitiesApi from "~/api/utilitiesApi";
import {apiError, handleRequest} from "~/utils";

import {globalNavigate} from "~/routes/GlobalHistory";

const cn = className.bind(style);

function Login() {

	const error = useSelector(authErrorSelector);

	const loading = useSelector(authLoadingSelector);

	const [loadRanks, setLoadRanks] = useState(false);

	const [ranks, setRanks] = useState({
		top1: {
			avatar: '',
			name: ''
		},
		top2: {
			avatar: '',
			name: ''
		},
		top3: {
			avatar: '',
			name: ''
		},
	});

	useEffect(() => {
		async function fetchData() {
			setLoadRanks(true);
			let [error, response] = await handleRequest(utilitiesApi.ranks());
			let message = apiError(`Lấy bảng sếp hạng thất bại`, error, response);
			if (!message) {
				if(response.data[0]) {
					ranks.top1.avatar = response.data[0]?.avatar;
					ranks.top1.name = response.data[0]?.lastname;
				}
				if(response.data[1]) {
					ranks.top2.avatar = response.data[1]?.avatar;
					ranks.top2.name = response.data[1]?.lastname;
				}
				if(response.data[3]) {
					ranks.top3.avatar = response.data[3]?.avatar;
					ranks.top3.name = response.data[3]?.lastname;
				}

				setRanks(ranks)
			}
			setLoadRanks(false);
		}
		fetchData().then();
	}, [ranks]);

	if (error) {
		notification.error({message: 'Thất bại', description: error});
	}

	const isLoggedIn = Boolean(localStorage.getItem('access_token'));

	if (isLoggedIn) {
		globalNavigate("/");
	}

	return (
		<div className={cn('login')}>
			<div className={cn('login-wrapper')}>
				<div className={cn('login-info')}>
					<div className={cn('login-ranks')}>
						<div className={cn('columns', 'rank-2')}>
							<div className={cn('avatar')}>
								<div className={cn('avatar-inner')}><img src={ranks.top2.avatar} alt="" /></div>
								<div className={cn('medal')}><small></small></div>
							</div>
							<div className={cn('txt-top')}>Top <span>2</span></div>
							<div className={cn('txt-name')}>{ranks.top2.name}</div>
						</div>
						<div className={cn('columns', 'rank-1')}>
							<div className={cn('avatar')}>
								<div className={cn('avatar-inner')}><img src={ranks.top1.avatar} alt="" /></div>
								<div className={cn('medal')}><small></small></div>
							</div>
							<div className={cn('txt-top')}>Top <span>1</span></div>
							<div className={cn('txt-name')}>{ranks.top1.name}</div>
						</div>
						<div className={cn('columns', 'rank-3')}>
							<div className={cn('avatar')}>
								<div className={cn('avatar-inner')}><img src={ranks.top3.avatar} alt="" /></div>
								<div className={cn('medal')}><small></small></div>
							</div>
							<div className={cn('txt-top')}>Top <span>3</span></div>
							<div className={cn('txt-name')}>{ranks.top3.name}</div>
						</div>
					</div>
				</div>
				<div className={cn('login-form')}>
					{(loading || loadRanks) && <Loading/>}
					<h3><span>CMS</span> Siêu Kinh Doanh</h3>
					<h4>Nền tảng cho doanh nghiệp</h4>
					<AuthLoginForm />
				</div>
			</div>
		</div>
	)
}

export default Login;