import className from "classnames/bind";
import style from "../../style/SaleReport.module.scss";
import {
	numberFormat
} from "~/utils";
import {Col, Row} from "antd";
import _, {ceil} from "lodash";
import {StatusLabel} from "../../../../components";
import {FileList} from "../../../../components/Forms";
import SaleReportEvent from "./ReportEvent";
import SaleReportConsultation from "./ReportConsultation";
const cn = className.bind(style);
function SaleReportDetailUser({reportData}) {
	const renderPercent = (number, total) => {
		const percent = (total == 0) ? 0 : ceil((number/total)*100);
		return <StatusLabel small type={(percent > 50) ? 'green' : 'red'}>{percent}%</StatusLabel>
	}
	const renderAttachFile = () => {
		let files = [];
		if(!_.isEmpty(reportData.attachFile)) {
			for (const [path, name] of Object.entries(reportData.attachFile)) {
				files.push({
					path: path,
					name: name
				})
			}
		}
		return <FileList files={files} />
	}
	return (
		<div className={cn('report-detail')}>
			<h3 className="heading text-lg">Cam kết</h3>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>Cam kết tháng</Col>
				<Col className="gutter-row" span={4}>{numberFormat(reportData.mouth)}</Col>
				<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.revenue)}</Col>
				<Col className="gutter-row" span={4}>{renderPercent(reportData.revenue, reportData.mouth)}</Col>
			</Row>

			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>
					<p>Cam 3 ngày trước</p>
					<p style={{fontSize:'12px', color:'#ccc'}}>{reportData.threeDayLast.date}</p>
				</Col>
				<Col className="gutter-row" span={4}>{numberFormat(reportData.threeDayLast.price)}</Col>
				<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.threeDayLast.result)}</Col>
				<Col className="gutter-row" span={4}>{renderPercent(reportData.threeDayLast.result, reportData.threeDayLast.price)}</Col>
			</Row>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>
					<p>Cam 3 ngày tiếp</p>
					<p style={{fontSize:'12px', color:'#ccc'}}>{reportData.threeDayNow.date}</p>
				</Col>
				<Col className="gutter-row" span={4}>{numberFormat(reportData.threeDayNow.price)}</Col>
				<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.threeDayNow.result)}</Col>
				<Col className="gutter-row" span={4}>{renderPercent(reportData.threeDayNow.result, reportData.threeDayNow.price)}</Col>
			</Row>

			<h3 className="heading text-lg">Kết quả gọi điện</h3>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>Tìm nguồn</Col>
				<Col className="gutter-row" span={16}>{numberFormat(reportData.working.source)}</Col>
			</Row>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>Gọi điện</Col>
				<Col className="gutter-row" span={16}>{numberFormat(reportData.working.call)}</Col>
			</Row>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>Chi tiết</Col>
				<Col className="gutter-row" span={16}>{reportData.working.note}</Col>
			</Row>

			<h3 className="heading mb-1 text-lg">Lịch hẹn ({reportData.events.total})</h3>
			{
				Object.keys(reportData.events.data).map((itemKey) => {
					let item = reportData.events.data[itemKey];
					return <SaleReportEvent key={`SaleReportEvent${item.id}`} item={item} />
				})
			}

			<h3 className="heading mt-2 text-lg">Tư vấn ({reportData.consultation.total})</h3>
			{
				Object.keys(reportData.consultation.data).map((itemKey) => {
					let item = reportData.consultation.data[itemKey];
					return <SaleReportConsultation key={`SaleReportEvent${item.id}`} item={item} />
				})
			}
			<h3 className="heading mt-2 text-lg">Ghi chú</h3>
			<p>{reportData.note}</p>
			<h3 className="heading mt-2 text-lg">Đính kèm</h3>
			{renderAttachFile()}
		</div>
	)
}
export default SaleReportDetailUser;