import {Fragment} from "react";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectField,
	DateField,
	TextAreaField
} from "~/components/Forms";

function TasksFormAdd({project, onHandleSubmit}) {

	const deviceOptions = [
		{value: 'desktop', label: 'Desktop'},
		{value: 'tablet', label: 'Tablet'},
		{value: 'mobile', label: 'Mobile'}
	];

	const initialValues = {
		tasks : [{
			name: '',
			device: 'desktop',
			deadline: ''
		}]
	}

	const validationSchema = Yup.object().shape({
		tasks: Yup.array().of(
			Yup.object().shape({
				name: Yup.string().required('Nội dung lỗi không được để trống'),
				deadline: Yup.string().required('Bạn chưa chọn deadline'),
			})
		),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "tasks"
	});

	const handleAddTasks = () => {
		append({
			name: '',
			device: 'desktop',
			deadline: ''
		});
	}

	const onSubmit = async (data) => {
		onHandleSubmit(data, project);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			{fields.map(({ name, device, deadline }, index) => {
				return (
					<Fragment key={`TasksFormAdd_${index}`}>
						<p className="heading">Tasks</p>
						<Controller control={control} name={`tasks[${index}].name`} render={({ field }) => (
							<TextAreaField defaultValue={name} label="Tasks" rows={3} errors={errors} {...field}/>
						)}/>

						<Row gutter={10}>
							<Col className="gutter-row" span={8}>
								<Controller control={control} name={`tasks[${index}].device`} render={({field}) => (
									<SelectField defaultValue={device} options={deviceOptions} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={8}>
								<Controller control={control} name={`tasks[${index}].deadline`} render={({field }) => (
									<DateField defaultValue={deadline} placeholder="Deadline" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={8}>
								<Button primary type="button" onClick={() => remove(index)}>Xóa</Button>
							</Col>
						</Row>
						<hr />
					</Fragment>
				);
			})}
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button blue background onClick={handleAddTasks}>Thêm Task</Button>
				<Button primary type="submit">Lưu</Button>
			</div>
		</form>
	)
}

export default TasksFormAdd;