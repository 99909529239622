import className from "classnames/bind";
import style from "../../style/SaleReport.module.scss";
import {
	numberFormat
} from "~/utils";
import {
	Col, Row
} from "antd";
import {
	ceil
} from "lodash";
import {
	Image,
	StatusLabel
} from "~/components";
const cn = className.bind(style);
function SaleReportDetailGroup({reportData}) {
	const renderPercent = (number, total) => {
		const percent = (total == 0) ? 0 : ceil((number/total)*100);
		return <StatusLabel small type={(percent > 50) ? 'green' : 'red'}>{percent}%</StatusLabel>
	}
	const SaleReportRevenue = ({item}) => {
		return (
			<div className={cn('revenue-total')}>
				<Row className="mb-1" gutter={10}>
					<Col className="gutter-row" span={8}>
						<div className="d-flex gap align-items">
							<div className="avatar"><Image src={item?.avatar}/></div>
							<div>
								<b>{item?.name}</b>
							</div>
						</div>
					</Col>
					<Col className="gutter-row" span={12}>
						{numberFormat(item.total)}
					</Col>
				</Row>
			</div>
		)
	}
	return (
		<div className={cn('report-detail')}>
			<h3 className="heading text-lg">Cam kết</h3>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>Cam kết tháng</Col>
				<Col className="gutter-row" span={4}>{numberFormat(reportData.mouth)}</Col>
				<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.revenue)}</Col>
				<Col className="gutter-row" span={4}>{renderPercent(reportData.revenue, reportData.mouth)}</Col>
			</Row>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>
					<p>Cam 3 ngày trước</p>
					<p style={{fontSize:'12px', color:'#ccc'}}>{reportData.threeDayLast.date}</p>
				</Col>
				<Col className="gutter-row" span={4}>{numberFormat(reportData.threeDayLast.price)}</Col>
				<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.threeDayLast.result)}</Col>
				<Col className="gutter-row" span={4}>{renderPercent(reportData.threeDayLast.result, reportData.threeDayLast.price)}</Col>
			</Row>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}>
					<p>Cam 3 ngày tiếp</p>
					<p style={{fontSize:'12px', color:'#ccc'}}>{reportData.threeDayNow.date}</p>
				</Col>
				<Col className="gutter-row" span={4}>{numberFormat(reportData.threeDayNow.price)}</Col>
				<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.threeDayNow.result)}</Col>
				<Col className="gutter-row" span={4}>{renderPercent(reportData.threeDayNow.result, reportData.threeDayNow.price)}</Col>
			</Row>

			<h3 className="heading text-lg">Doanh số</h3>
			{
				Object.keys(reportData.revenueTeams).map((itemKey) => {
					let item = reportData.revenueTeams[itemKey];
					return <SaleReportRevenue key={`SaleReportRevenue${itemKey}`} item={item} />
				})
			}
		</div>
	)
}
export default SaleReportDetailGroup;