import {Fragment, useEffect} from "react";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import StatusLabel from "~/components/StatusLabel";
import {SelectField} from "~/components/Forms";
import {Col, Row} from "antd";
import {
	useCan
} from "~/hooks";

function WorkingDayFormTimeKeeping({item, time, onHandleSubmit}) {

	const canEditStatus = useCan('workingDayStatus');

	const options = [
		{value: 'workOnTime', label: <StatusLabel small type="green">Đúng giờ</StatusLabel>},
		{value: 'goLate', label: <StatusLabel small type="yellow">Đi làm trể</StatusLabel>},
		{value: 'halfDayOff', label: <StatusLabel small type="yellow">Nghỉ nữa buổi (Có phép)</StatusLabel>},
		{value: 'halfDayOffNo', label: <StatusLabel small type="yellow">Nghỉ nữa buổi (Không phép)</StatusLabel>},
		{value: 'halfPaidHoliDays', label: <StatusLabel small type="yellow">Nghỉ nữa buổi (Phép năm)</StatusLabel>},
		{value: 'dayOff', label: <StatusLabel small type="red">Nghỉ (Có phép)</StatusLabel>},
		{value: 'dayOffNo', label: <StatusLabel small type="red">Nghỉ (Không phép)</StatusLabel>},
		{value: 'offPaidHoliDays', label: <StatusLabel small type="red">Nghỉ (Phép năm)</StatusLabel>},
	]

	const weekday = ["Chủ Nhật","Thứ 2","Thứ 3","Thứ 4","Thứ 5","Thứ 6","Thứ 7"];

	const date = (time) ? new Date(time) : new Date();

	let day = date.getDate();

	let month = date.getMonth() + 1;

	month = (month < 10) ? '0'+month : month;

	let year = date.getFullYear();

	let listInput = [];

	for (let i = 1; i <= day; i++) {

		date.setDate(i)

		let iD = (i < 10) ? '0'+i : i;

		listInput.unshift({
			weekDayLabel : weekday[date.getDay()],
			weekDay : date.getDay(),
			time : `${iD}-${month}-${year}`,
			status: 'workOnTime'
		});
	}

	const initialValues = {
		timeKeeping: listInput,
	}

	const validationSchema = Yup.object().shape({})

	const { control, handleSubmit, formState: {isSubmitting, errors}, reset} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields} = useFieldArray({
		control,
		name: "timeKeeping"
	});

	useEffect(() => {
		if(item?.id) {
			listInput.forEach((inputItem, index) => {
				if(item.details[inputItem.time]) {
					listInput[index].status = item.details[inputItem.time];
				}
			})
			initialValues.timeKeeping = listInput;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		onHandleSubmit(data, item);
	}

	const WorkingDayItem = ({weekDay, control, index, status, errors}) => {
		return (
			<Fragment>
				{weekDay == 0 && <div className="form-group"><StatusLabel small type="red">Ngày nghỉ</StatusLabel></div>}
				{!canEditStatus && weekDay != 0 && options.find((item) => {return item.value === status})?.label}
				{(canEditStatus && weekDay != 0) &&
					<Controller control={control} name={`timeKeeping[${index}].status`} render={({ field }) => (
						<SelectField defaultValue={status} options={options} errors={errors} {...field} />
					)}/>
				}
			</Fragment>
		)
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			{fields.map(({weekDay, weekDayLabel, time, status}, index) => {
				return (
					<Row gutter={10} key={`${index}`}>
						<Col className="gutter-row" span={12}>
							<p><b>{`${weekDayLabel}`}</b></p>
							<p className="mb-1">{`${time}`}</p>
							{ (weekDay == 0) ? (<br />) : ''}
						</Col>
						<Col className="gutter-row" span={12}>
							<WorkingDayItem key={`${index}-${status}`} weekDay={weekDay} control={control} index={index} status={status} errors={errors} />
						</Col>
						{(weekDay == 1) ? <Col className="gutter-row" span={24}>< hr /></Col> : '' }
					</Row>
				);
			})}
			<hr />
			<div className="d-flex justify-content-end">
				{canEditStatus && <Button primary type="submit">Cập nhật</Button>}
			</div>
		</form>
	)
}

export default WorkingDayFormTimeKeeping;