import {Select} from "antd";
import {forwardRef} from "react";

const SelectField = forwardRef(({ children, name, value, defaultValue, onChange, label, options, placeholder, errors, ...props }, ref) => {
	if(label && typeof placeholder == 'undefined') placeholder = label;
	if(typeof value == 'undefined') value = defaultValue;

	function removeAccents(str) {
		return str.replaceAll(/[\u0300-\u036f]/g, '');
	}

	function filterOption(input, option) {
		const plainText = removeAccents(option.label);
		const searchValue = removeAccents(input);
		return plainText.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
	}

	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<Select name={name} value={value} showSearch filterOption={filterOption} {...props} onChange={onChange} options={options} dropdownStyle={{zIndex:10000}} placeholder={placeholder} ref={ref}>
				{children}
			</Select>
			{errors && errors[name]?.message && (<p className="error-message">{errors[name]?.message}</p>)}
		</div>
	)
});

export default SelectField;