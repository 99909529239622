import {memo} from "react";
import {
	Table
} from "antd";
import {
	Image,
	StatusLabel
} from "~/components";
import {
	arrayToMap,
	numberFormat,
	renderDate
} from "~/utils";

function PayDetailExpense({items = []}) {

	const typeList = {
		'none' : '',
		'minus-wage' : 'Trừ vào lương',
		'plus-wage' : 'Cộng vào lương',
	}

	const columns = [
		{ title: 'Mã đơn', dataIndex: 'code', key: "code", width: '100px', render: (_, item) => (<div>DT{item?.id}</div>)},
		{ title: 'Ngày chi', dataIndex: 'time', key: "time", width: '120px', render: (_, item) => (<div><p>{renderDate(item?.time)}</p></div>)},
		{title: 'Loại chi', dataIndex: 'category', key: 'category', width: '220px', render:(_, item) => (<div>{item.category.name}</div>)},
		{ title: 'Kiểu tính', dataIndex: 'wage', key: "wage", width: '180px', render: (_, item) => (<p><b>{typeList[item?.type]}</b></p>)},
		{ title: 'Số tiền chi', dataIndex: 'price', key: "price", width: '180px', render: (_, item) => (<p><b>{numberFormat(item?.price)}đ</b></p>)},
		{ title: 'Phương thức', dataIndex: 'status', key: "status", render: (_, item) => (item?.method)},
	];


	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:550 }} pagination={false} />
			</div>
		</div>
	)
}

export default memo(PayDetailExpense);