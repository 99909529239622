import {useEffect} from "react";
import {useForm, Controller, useWatch} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	SelectField,
	InputField,
	DateField,
	TextAreaField,
	SelectContractAll
} from "~/components/Forms";
import {Alert} from "antd";

function UploadWebsiteFormAddEdit({item, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	const typeOptions = [
		{value : 1, label : "Upload website đã bàn giao"},
		{value : 2, label : "Upload website thu tiền sau"},
		{value : 3, label : "Upload website hỗ trợ khách hàng"},
	];

	const domainTypeOptions = [
		{value : 1, label : "Tên miền công ty mua"},
		{value : 2, label : "Tên miền của khách hàng"},
	];

	const websiteTypeOptions = [
		{value : 1, label : "Website công ty mua"},
		{value : 2, label : "Website của khách hàng"},
	];

	const hostTypeOptions = [
		{value : 1, label : "Host công ty mua"},
		{value : 2, label : "Host của khách hàng"},
		{value : 3, label : "Host hỗ trợ (1GB)"},
	];

	const websiteOptions = (isEdit && item.websiteContract?.id) ? [{ label : `HD${item.websiteContract.id} ${item.websiteContract.name}`, value : item.websiteContract.id }] : [];

	const domainOptions = (isEdit && item.domainContract?.id) ? [{ label : `HD${item.domainContract.id} ${item.domainContract.name}`, value : item.domainContract.id }] : [];

	const hostOptions = (isEdit && item.hostContract?.id) ? [{ label : `HD${item.hostContract.id} ${item.hostContract.name}`, value : item.hostContract.id }] : [];

	const initialValues = {
		type: 1,
		url: '',
		deadline: new Date,
		websiteType: 1,
		websiteId: undefined,
		websiteCustomer: '',
		domainType: 1,
		domainId: undefined,
		domain: '',
		domainCustomer: '',
		hostType: 1,
		hostId: undefined,
		hostCustomer: '',
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Bạn chưa chọn loại yêu cầu'),
		deadline: Yup.string().when('type', {
			is: 2,
			then: Yup.string().required('Deadline không được bỏ trống'),
		}),
		websiteType: Yup.string().required('Bạn chưa chọn loại website'),
		websiteId: Yup.string().when('websiteType', {
			is: '1',
			then: Yup.string().required('Hợp đồng website không được bỏ trống'),
		}),
		domainType: Yup.string().required('Bạn chưa chọn loại tên miền'),
		domainId: Yup.string().when('domainType', {
			is: '1',
			then: Yup.string().required('Hợp đồng tên miền không được bỏ trống'),
		}),
		domain: Yup.string().when('domainType', {
			is: '2',
			then: Yup.string().required('Tên miền không được bỏ trống'),
		}),
		domainCustomer: Yup.string().when('domainType', {
			is: '2',
			then: Yup.string().required('Thông tin tên miền không được bỏ trống'),
		}),
		hostType: Yup.string().required('Bạn chưa chọn loại host'),
		hostId: Yup.string().when('hostType', {
			is: '1',
			then: Yup.string().required('Hợp đồng host không được bỏ trống'),
		}),
		hostCustomer: Yup.string().when('hostType', {
			is: '2',
			then: Yup.string().required('Thông tin host không được bỏ trống'),
		}),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const typeInput 		= useWatch({control, name: "type"});
	const websiteTypeInput 	= useWatch({control, name: "websiteType"});
	const domainTypeInput 	= useWatch({control, name: "domainType"});
	const hostTypeInput 	= useWatch({control, name: "hostType"});

	useEffect(() => {
		if(item?.id) {
			initialValues.type = item.type;
			initialValues.url = item.url;
			initialValues.deadline = item.deadline;
			initialValues.websiteType = item.websiteType;
			initialValues.websiteId = item.websiteId;
			initialValues.websiteCustomer = item.websiteCustomer;
			initialValues.domainType = item.domainType;
			initialValues.domainId = item.domainId;
			initialValues.domain = item.domain;
			initialValues.domainCustomer = item.domainCustomer;
			initialValues.hostType = item.hostType;
			initialValues.hostId = item.hostId;
			initialValues.hostCustomer = item.hostCustomer;
		}
		reset(initialValues);
	}, [item]);

	const handleTypeChange = (value) => {
		if(value == 1) {
			setValue('websiteType', 1)
		}
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Yêu cầu</p>
			<Controller control={control} name="type" render={({field}) => (
				<SelectField options={typeOptions} errors={errors} {...field} onChange={(value, event) => {
					handleTypeChange(value);
					field.onChange(value, event);
				}}/>
			)}/>
			<Controller control={control} name="url" render={({field}) => (
				<InputField label="Link demo" errors={errors} {...field}/>
			)}/>
			{
				(typeInput == 2) &&
				<>
					<Controller control={control} name="deadline" render={({field}) => (
						<DateField label="Ngày đóng link" errors={errors} {...field}/>
					)}/>
					<Alert
						message="* Nếu không thanh toán đủ theo ngày đã cam kết thì số tiền bàn giao còn lại sẽ trừ trục tiếp vào lương"
						type="error"
						closable
					/>
				</>
			}
			<p className="heading mt-1">Website</p>
			{
				(typeof typeInput != 'undefined' && typeInput == 3) &&
				<Controller control={control} name="websiteType" render={({field}) => (
					<SelectField label="Loại website" options={websiteTypeOptions} errors={errors} {...field}/>
				)}/>
			}
			{
				(websiteTypeInput == 1) &&
				<Controller control={control} name="websiteId" render={({ field }) => (
					<SelectContractAll service="website" label="Hợp đồng webiste" options={websiteOptions} errors={errors} {...field} />
				)}/>
			}
			{
				(websiteTypeInput == 2) &&
				<Controller control={control} name="websiteCustomer" render={({field}) => (
					<TextAreaField label="Thông tin host củ" errors={errors} {...field}/>
				)}/>
			}
			<p className="heading">Tên miền</p>
			<Controller control={control} name="domainType" render={({field}) => (
				<SelectField label="Loại tên miền" options={domainTypeOptions} errors={errors} {...field}/>
			)}/>
			{
				(domainTypeInput == 1) &&
				<Controller control={control} name="domainId" render={({ field }) => (
					<SelectContractAll service="domain" placeholder="Hợp đồng tên miền" options={domainOptions} errors={errors} {...field} />
				)}/>
			}
			{
				(domainTypeInput == 2) &&
				<Controller control={control} name="domain" render={({field}) => (
					<InputField label="Tên miền" errors={errors} {...field}/>
				)}/>
			}
			{
				(domainTypeInput == 2) &&
				<Controller control={control} name="domainCustomer" render={({field}) => (
					<TextAreaField label="Thông tin tên miền của khách" errors={errors} {...field}/>
				)}/>
			}
			<p className="heading">Host</p>
			<Controller control={control} name="hostType" render={({field}) => (
				<SelectField label="Loại host" options={hostTypeOptions} errors={errors} {...field}/>
			)}/>
			{
				(hostTypeInput == 1) &&
				<Controller control={control} name="hostId" render={({ field }) => (
					<SelectContractAll service="host" placeholder="Hợp đồng host" options={hostOptions} errors={errors} {...field} />
				)}/>
			}
			{
				(hostTypeInput == 2) &&
				<Controller control={control} name="hostCustomer" render={({field}) => (
					<TextAreaField label="Thông tin host của khách" errors={errors} {...field}/>
				)}/>
			}
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} yêu cầu</Button>
			</div>
		</form>
	)
}

export default UploadWebsiteFormAddEdit;