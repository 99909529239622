import {useState} from "react";
import {useForm, Controller, useWatch, useFieldArray} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Col, Row } from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	InputField,
	GroupRadioField,
	DateField,
	InputPriceField
} from "~/components/Forms";
import {v4 as uuidv4} from "uuid";

function SaleCommitFormAddEdit({item, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	const typeOptions = [
		{value : '3-day', label : 'Cam kết 3 Ngày'},
		{value : 'mouth', label : 'Cam kết tháng'},
	];

	const [typeValue, setTypeValue] = useState((isEdit) ? item.type : '3-day');

	const initialValues = {
		type: '3-day',
		timeStart: new Date(),
		price: 0,
		base: [],
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Bạn chưa chọn loại cam kết'),
		price: Yup.number().min(1000000, "Cam kết không được nhỏ hơn 1tr").required('Bạn chưa điền giá trị cam kết'),
		timeStart: Yup.string().required('Bạn chưa chọn thời gian cam kết'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({control, name: "base"});

	const typeInput = useWatch({control, name: "type"});

	if(typeInput != typeValue) setTypeValue(typeInput);

	const renderDateTime = (typeValue) => {
		if((typeValue == '3-day')) return (
			<Controller control={control} name="timeStart" render={({ field }) => (
				<DateField label="Từ ngày" errors={errors} {...field}/>
			)}/>
		)
		if((typeValue == 'mouth')) return (
			<Controller control={control} name="timeStart" render={({ field }) => (
				<DateField label="Tháng" picker="month" errors={errors} {...field}/>
			)}/>
		)
	}

	const handleAddBase = () => {
		append({ id: uuidv4(), title: '', price: 0});
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Loại cam kết</p>
			<Controller control={control} name="type" render={({ field }) => (
				<GroupRadioField options={typeOptions} errors={errors} {...field}/>
			)}/>
			<p className="heading">Thời gian</p>
			<Controller control={control} name="price" render={({ field }) => (
				<InputPriceField label="Cam kết" errors={errors} {...field}/>
			)}/>
			{renderDateTime(typeValue)}
			<p className="heading">Cơ sở</p>
			{fields.map(({ id, title, price }, index) => {
				return (
					<Row gutter={10} key={id}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name={`base.${index}.title`} render={({ field }) => (
								<InputField defaultValue={title} errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={8}>
							<Controller control={control} name={`base.${index}.price`} render={({ field }) => (
								<InputPriceField defaultValue={price} errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={4}>
							<Button primary type="button" onClick={() => remove(index)} style={{width:'100%'}}>Xóa</Button>
						</Col>
					</Row>
				);
			})}
			<div className="d-flex justify-content-end">
				<Button blue background type="button" onClick={handleAddBase}>Thêm cơ sở</Button>
			</div>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} cam kết</Button>
			</div>
		</form>
	)
}

export default SaleCommitFormAddEdit;