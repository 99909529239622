import {memo, useMemo} from "react";
import {
	SelectField,
	SelectUserAll,
	DateRangeField
} from "~/components/Forms";
import {
	useCan
} from "~/hooks";
import {
	strToTime
} from "~/utils";

function EventSearchBar({listGroup, filter, onChange}) {

	const canViewAll 		= useCan('salesEventListAll');

	const canViewGroup 		= useCan('salesEventListGroup');

	const canViewLeader 	= useCan('salesEventListLeader');

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả các nhóm' }, ...Object.entries(listGroup).map(([item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [listGroup]);

	const handleGroupChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			group: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleDateChange = (value) => {
		if (!onChange) return;
		let valueNew = [...value]
		valueNew[0] = strToTime(valueNew[0]);
		valueNew[1] = strToTime(valueNew[1]);
		const newFilter = {
			...filter,
			time: valueNew || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleUserChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			userId: value,
			page: 1,
		};
		onChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<DateRangeField onChange={(value) => handleDateChange(value)} />
					{(canViewAll || canViewGroup) && <SelectField placeholder="Chọn nhóm" options={groupOptions} onChange={(value) => handleGroupChange(value)} />}
					{(canViewAll || canViewGroup || canViewLeader) && <SelectUserAll value={filter.userId} placeholder="Nhân viên" options={[{value:'', label:'Tất cả'}]} onChange={handleUserChange} />}
				</form>
			</div>
		</div>
	)
}

export default memo(EventSearchBar);