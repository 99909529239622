import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {useSelector} from "react-redux";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectField,
} from "~/components/Forms";
import {groupItemsSelector} from "../../../Personnel/groupSlice";

function RevenueFormTransfer({item, onHandleSubmit}) {

	const listGroup = useSelector(groupItemsSelector);

	let groupOptions = [];

	for (const [key, value] of Object.entries(listGroup)) {
		groupOptions.push({value : value.id, label : value.name})
	}

	const initialValues = {
		groupId: '',
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
	});

	useEffect(() => {
		if (item?.id) {
			reset(initialValues)
		}
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="groupId" render={({field }) => (
				<SelectField errors={errors} options={groupOptions}  {...field} />
			)}/>
			<div className="form-group d-flex justify-content_end mt-2">
				<Button primary type="submit">Cập nhật thông tin</Button>
			</div>
		</form>
	)
}

export default RevenueFormTransfer;