import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectField,
	TextAreaField
} from "~/components/Forms";
import {useCurrentUser} from "~/hooks";

function SaleConsultationFormReport({item, onHandleSubmit}) {

	const currentUser = useCurrentUser();

	const typeOptions = [
		{value : 'new', label : 'Khách mới'},
		{value : 'old', label : 'Khách củ'},
	];

	const type2Options = [
		{value : 'tuvan', label : 'Tư vấn'},
		{value : 'bangiao', label : 'Bàn giao'},
	];

	const type3Options = [
		{value : 'meeting', label : 'Trực tiếp'},
		{value : 'online', label : 'Online'},
	];



	const initialValues = {
		type: 'new',
		type2: 'tuvan',
		type3: 'meeting',
		note:'',
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Bạn chưa chọn loại khách'),
		type2: Yup.string().required('Bạn chưa chọn loại tư vấn'),
		type3: Yup.string().required('Bạn chưa chọn hình thức tư vấn'),
		note: Yup.string().required('Bạn chưa điền kết quả cuộc tư vấn'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.type = item.type;
			initialValues.type2 = item.type2;
			initialValues.type3 = item.type3;
			if(item.userId == currentUser.id) {
				initialValues.note = item.result.user;
			}
			if(item.assignId == currentUser.id) {
				initialValues.note = item.result.assign;
			}
			if(item.supportId == currentUser.id) {
				initialValues.note = item.result.support;
			}
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Kết quả</p>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}><b>Người gọi điện ({item.user.lastname}):</b></Col>
				<Col className="gutter-row" span={8}><p>{item.result.user}</p></Col>
			</Row>
			<Row gutter={10} className="mb-2">
				<Col className="gutter-row" span={8}><b>Người tư vấn ({item.assign.lastname}):</b></Col>
				<Col className="gutter-row" span={8}><p>{item.result.assign}</p></Col>
			</Row>
			{
				item.supportId != 0 &&
				<Row gutter={10} className="mb-2">
					<Col className="gutter-row" span={8}><b>Người hỗ trợ ({item.support.lastname})</b></Col>
					<Col className="gutter-row" span={8}><p>{item.result.support}</p></Col>
				</Row>
			}

			<p className="heading">Báo cáo</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="type" render={({field}) => (
						<SelectField label="Loại khách" options={typeOptions} errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="type2" render={({ field }) => (
						<SelectField label="Loại tư vấn" options={type2Options} errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="type3" render={({ field }) => (
						<SelectField label="Hình thức tư vấn" options={type3Options} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<Controller control={control} name="note" render={({ field }) => (
				<TextAreaField label="Kết quả" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Báo cáo</Button>
			</div>
		</form>
	)
}

export default SaleConsultationFormReport;