import {useMemo, useEffect} from "react";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button,
	Loading,
	StatusLabel
} from "~/components";
import {
	DateField,
	TextAreaField
} from "~/components/Forms";

function FormPapersInfo({item, can, onHandleSubmit}) {

	const initialValues = {
		note2: undefined,
		time: new Date()
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(Yup.object().shape())
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	const RenderCode = ({codes}) => {
		return (
			codes.map((code) => {
				return (
					<StatusLabel small key={`code-${code}`} type="green"><span style={{fontSize:'18px'}}>{code}</span></StatusLabel>
				)
			})
		)
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin giấy tờ</p>
			{
				item.status == 'pending' && Object.values(item.content).map((content) => {
					return (
						<p key={`content-${content.typeId}`} className="d-flex mb-1 gap-3"><span style={{fontSize:'14px'}}>{content.typeName}</span> <StatusLabel small type="blue"><b style={{fontSize:'18px'}}>{content.number}</b></StatusLabel></p>
					)
				})
			}
			{
				item.status == 'success' && Object.values(item.code).map((content, index) => {
					return (
						<div key={`content-${index}`}>
							<p className="d-flex mb-1 gap-3">
								<b style={{fontSize:'14px'}}>{content.name}</b>
								<StatusLabel small type="blue"><b style={{fontSize:'18px'}}>{content.code.length}</b></StatusLabel>
							</p>
							<p className="d-flex mb-1 gap-1"><RenderCode codes={content.code}/></p>
							<hr />
						</div>
					)
				})
			}
			{
				item.note.length > 0 && <>
					<p className="heading">Ghi chú người xin</p>
					<p>{item.note}</p>
				</>
			}
			{
				(can.status && item.status == 'pending') && <>
					<p className="heading">Thông tin xuất giấy tờ</p>
					<Controller control={control} name="time" render={({ field }) => (
						<DateField label="Ngày xuất" errors={errors} {...field}/>
					)}/>
					<Controller control={control} name="note2" render={({ field }) => (
						<TextAreaField label="Ghi chú" errors={errors} {...field}/>
					)}/>
					<div className="form-group d-flex justify-content-end modal-bottom">
						<Button primary type="submit">Xuất giấy tờ</Button>
					</div>
				</>
			}
		</form>
	)
}

export default FormPapersInfo;