import style from '../Home.module.scss';
import className from 'classnames/bind';
import {notification} from "antd";
import {Button, Icon, Image, StatusLabel} from "~/components";
import {useUtilities} from "~/hooks";
import {workingDayApi} from "~/api";
import {apiError, handleRequest} from "~/utils";
import {useState} from "react";

const cn = className.bind(style);

function SidebarAccount({user}) {

	const {utilities} = useUtilities();

	const [attendanceLoad, setAttendanceLoad] = useState(false);

	function toRad(degrees) {
		return (degrees * Math.PI) / 180;
	}

	function distance(point1, point2) {
		const R = 6371; // bán kính Trái đất tính bằng km
		const dLat = toRad(point2.latitude - point1.latitude);
		const dLon = toRad(point2.longitude - point1.longitude);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(toRad(point1.latitude)) *
			Math.cos(toRad(point2.latitude)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c;
	}

	const attendance = {
		success : async (position) => {
			let companyLocation = {latitude: 10.806275, longitude: 106.7138619};
			let myLocation = {latitude: position.coords.latitude, longitude: position.coords.longitude};
			if (distance(companyLocation, myLocation) <= 0.1) {
				setAttendanceLoad(true);
				let [error, response] = await handleRequest(workingDayApi.attendance());
				let message = apiError(`Điểm danh thất bại`, error, response);
				if (!message) {
					notification.success({
						message: 'Thành công',
						description: response.data.message
					});
				}
				setAttendanceLoad(false);
			} else {
				notification.error({message: 'Lỗi', description: 'Bạn chưa thuộc vị trí để có thể điểm danh'});
			}
		},
		error : () => {
			notification.error({message: 'Lỗi', description: 'Trình duyệt của bạn đang sử dụng không hỗ trợ chức năng này'});
		}
	}

	const handleAttendance = () => {

		if (!navigator.geolocation) {
			notification.error({message: 'Lỗi', description: 'Trình duyệt của bạn đang sử dụng không hỗ trợ chức năng này'});
		}

		navigator.geolocation.getCurrentPosition(attendance.success, attendance.error);
	}

	return (
		<div className={cn('account')}>
			<div className={cn('account-avatar', {'avatar': true})}><Image src={user.avatar} /></div>
			<div className={cn('account-name', 'font-bold', 'text-sm')}><p>{user.firstname} {user.lastname}</p></div>
			<p className="">
				<StatusLabel type={utilities.roles[user.role]?.color}>{utilities.roles[user.role]?.title}</StatusLabel>
			</p>
			<p className="mt-1">
				<Button loading={attendanceLoad} background red onClick={handleAttendance} className={'w-100'} leftIcon={Icon.fingerprint}>Điểm Danh</Button>
			</p>
		</div>
	)
}

export default SidebarAccount;