import Image from "~/components/Image";
import {Pagination, Table} from "antd";
import PopperUserInfo from "~/components/PopperUserInfo";
import StatusLabel from "~/components/StatusLabel";
import {
	renderDate,
	numberFormat
} from "~/utils";
import FormPaymentAction from "./FormPaymentAction";
import {LIST_FORM_STATUS} from "~/app/constants";

function FormPaymentTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

	const columns = [
		{ title: 'Mã đơn', dataIndex: 'code', key: "code", width: '100px', render: (_, item) => (<div>PC{item?.id}</div>)},
		{ title: 'Người làm đơn', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			<PopperUserInfo user={item.user}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.user?.avatar} /></div>
					<div>
						<b>{`${item.user?.firstname} ${item.user?.lastname}`}</b>
						<p>{item.user?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Người duyệt đơn', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			item.assign?.id && <PopperUserInfo user={item.assign}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.assign?.avatar} /></div>
					<div>
						<b>{`${item.assign?.firstname} ${item.assign?.lastname}`}</b>
						<p>{item.assign?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Ngày đã chi', dataIndex: 'time', key: "time", width: '120px', render: (_, item) => (<p>{renderDate(item?.time)}</p>)},
		{ title: 'Số tiền', dataIndex: 'endDay', key: "endDay", width: '120px', render: (_, item) => (<p>{numberFormat(item?.price)}</p>)},
		{ title: 'Lý do', dataIndex: 'note', key: 'note', width: '220px', render:(_, item) => (<div>{item.note}</div>)},
		{ title: 'Trạng thái', dataIndex: 'status', key: 'status',width: '150px', render:(_, item) => (
			<StatusLabel pointer small type={LIST_FORM_STATUS[item.status].color}>{LIST_FORM_STATUS[item.status].label}</StatusLabel>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<FormPaymentAction
				key={item.id}
				item={item}
				setItemEdit={setItemEdit}
				openModal={openModal}
			/>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:'55vh', x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default FormPaymentTable;