import {useCallback, useMemo} from "react";
import {
	SelectUserAll,
	SelectContractAll,
	SelectField
} from "~/components/Forms";
import {
	LIST_REVENUE_STATUS
} from "~/app/constants";
import {StatusLabel} from "~/components";
import {useCan, useUtilities} from "~/hooks";

function RevenueSearchBar({filter, onChange}) {

	const {utilities} = useUtilities();

	const can = {
		listAll: useCan('revenueListAll'),
		listGroup: useCan('revenueListGroup'),
	};

	const serviceOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(utilities.services).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [utilities.services]);

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_REVENUE_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [LIST_REVENUE_STATUS]);

	const handleUserChange = useCallback((value) => {
		if (onChange) { onChange('userId', value); }
	}, [onChange]);

	const handleContractChange = useCallback((value) => {
		if (onChange) { onChange('contractId', value); }
	}, [onChange]);

	const handleServiceChange = useCallback((value) => {
		if (onChange) { onChange('service', value); }
	}, [onChange]);

	const handleStatusChange = useCallback((value) => {
		if (onChange) { onChange('status', value); }
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<SelectContractAll value={filter.contractId} placeholder="Hợp đồng" options={[{value:'', label:'Tất cả'}]} onChange={handleContractChange} />
					{(can.listAll || can.listGroup) && <SelectUserAll  value={filter.userId} placeholder="Nhân viên" options={[{value:'', label:'Tất cả'}]} onChange={handleUserChange} />}
					<SelectField value={filter.service} placeholder="Dịch vụ" options={serviceOptions} onChange={handleServiceChange}/>
					<SelectField value={filter.status} placeholder="Trạng thái" options={statusOptions} onChange={handleStatusChange}/>
				</form>
			</div>
		</div>
	)
}

export default RevenueSearchBar;