import _ from "lodash"
import {
	StatusLabel,
	Image
} from "~/components";
import {renderDate, arrayToMap, numberFormat} from "~/utils";
import {Pagination, Table} from "antd";
import PersonnelAction from "./PersonnelAction";
import {USER_STATUS} from "~/app/constants";
import {useCan, useDevice, useUtilities} from "~/hooks";

function PersonnelTable({items = [], listGroup, pagination, setItemEdit, openModal, onPaginationChange}) {

	const {utilities} = useUtilities();

	const {isMobile} = useDevice();

	const canEditPay = useCan('personnelPay');

	const groupMap   = arrayToMap(listGroup);

	let columns = [
		{ title: 'Họ tên', dataIndex: 'fullname', key: "fullname", width: '300px', render: (_, item) => (
			<>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item?.avatar} /></div>
					<div>
						<b>{item?.firstname + ' ' + item?.lastname}</b>
						<p>{item?.username} {isMobile && <StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>}</p>
						{(isMobile && item.group != 0) && <p className="font-bold">{groupMap[item.group]?.name}</p>}
						{isMobile && <p> {item?.phone}</p> }
						{isMobile && <p> {item?.email}</p> }
					</div>
				</div>
			</>
		)},
		{ title: 'Liên hệ', dataIndex: 'contact', key: "contact", render: (_, item) => (
			<>
				<p> {item?.phone}</p>
				<p> {item?.email}</p>
			</>
		)},
		{ title: 'Hợp đồng đang giữ', dataIndex: 'papers', key: "papers", width: '100px', render: (_, item) => (
			<p> {item?.papers}</p>
		)},
		{ title: 'Chức vụ', dataIndex: 'role', key: "role", width: '200px', render: (_, item) => (<StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>) },
		{ title: 'Nhóm', dataIndex: 'group', key: "group", width: '170px', render: (_, item) => ((item.group != 0) ? groupMap[item.group]?.name : utilities.roles[item.role]?.title) },
		{ title: 'Ngày sinh', dataIndex: 'birthday', key: "birthday", responsive: ['lg'], render: (_, item) => (renderDate(item?.birthday)) },
		{ title: 'Loại', dataIndex: 'status', key: "status", responsive: ['lg'], render: (_, item) => (
			<div>
				<StatusLabel small type={USER_STATUS[item.status]?.color}>{USER_STATUS[item.status]?.title}</StatusLabel>
				{(item.status != 'public' && item.status != 'trash') && <p className="mt-1" style={{fontSize:'13px', color:'#999'}}>{renderDate(item?.time)}</p> }
			</div>
		)},
		{ title: 'Lương', dataIndex: 'pay', key: "pay", render: (_, item) => (
			<div>
				{(item.pay != 0) ? <p>Cứng: <b>{numberFormat(item?.pay)}</b></p> : ''}
				{item.payType && <StatusLabel small type="green">Loại {item?.payType}</StatusLabel>}
			</div>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<PersonnelAction item={item} setItemEdit={setItemEdit} openModal={openModal}/>
		)},
	];

	if(!canEditPay) {
		_.remove(columns, (column) => {return column.key == 'pay'})
	}

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'contact'
				&& currentValue.key != 'role'
				&& currentValue.key != 'group'
			)
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:'55vh', x: 'max-content' }} pagination={false}
				/>
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default PersonnelTable;