import {forwardRef} from "react";
import style from "./Print.module.scss";
import className from "classnames/bind";
import {renderDate} from "~/utils";
const cn = className.bind(style);

export const PrintDomainQT = forwardRef(({ item, ...props }, ref) => {

	const printStyle = {
		p1 : {
			textAlign : 'center',
			margin : '0',
			fontSize: '14px'
		},
		p2 : {
			marginTop : '2px',
			fontSize: '14px'
		}
	}

	const today = new Date();
	const dd = String(today.getDate()).padStart(2, '0');
	const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	const yyyy = today.getFullYear();

	return (
		<div ref={ref} className={cn('declaration-domain-international')}>
			<h1>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</h1>
			<p className={cn('title-mini')}>Độc lập - Tự do - Hạnh phúc</p>
			<h3 className="text-center">BẢN KHAI ĐĂNG KÝ TÊN MIỀN QUỐC TẾ</h3>
			<p style={printStyle.p1}><i>(Dành cho cá nhân)</i></p>
			<table>
				<tr>
					<td style={{width: '40%'}}>
						<p className="font-bold">1.Tên miền đăng ký</p>
						<p><i>(Số lượng tên miền đăng ký)</i></p>
					</td>
					<td>{item.domain}</td>
				</tr>
				<tr>
					<td style={{width: '40%'}}>
						<p className="font-bold">2. Máy chủ DNS chuyển giao</p>
						<p><i>(Lưu ý: Mục này bắt buộc phải khai báo đầy đủ tối thiểu 02 máy chủ).</i></p>
					</td>
					<td>
						<p className="mb-1">Tên DNS Primary:………………………………………………..</p>
						<p className="mb-1">Địa chỉ IP : ………………………………………………………</p>
						<p className="mb-1">Tên DNS Secondary 1: ………………………………………….</p>
						<p className="mb-1">Địa chỉ IP : ………………………………………………………</p>
						<p className="mb-1">Tên DNS Secondary 2: ………………………………………….</p>
						<p className="mb-1">Địa chỉ IP : ………………………………………………………</p>
					</td>
				</tr>
				<tr>
					<td style={{width: '40%'}}>
						<p className="font-bold">3. Thông tin cá nhân</p>
						<p className="mb-1">Tên chủ thể</p>
						<p className="mb-1">Giới tính</p>
						<p className="mb-1">Ngày tháng Năm sinh </p>
						<p className="mb-1">Số CMND/Hộ chiếu</p>
						<p className="mb-1">Địa chỉ</p>
						<p className="mb-1">Tỉnh/thành phố</p>
						<p className="mb-1">Quốc gia</p>
						<p className="mb-1">Số điện thoại/Fax</p>
						<p className="mb-1">Email</p>
					</td>
					<td>
						<p></p>
						<p className="mb-1">{item.customer.name}</p>
						<p className="mb-1">{item.customer.gender}</p>
						<p className="mb-1">{renderDate(item.customer.birthday)}</p>
						<p className="mb-1">{item.customer.numberId}</p>
						<p className="mb-1">{item.customer.address}</p>
						<p className="mb-1">{item.customer.city}</p>
						<p className="mb-1">Việt Nam</p>
						<p className="mb-1">{item.customer.phone}</p>
						<p className="mb-1">{item.customer.email}</p>
					</td>
				</tr>
				<tr>
					<td style={{width: '40%'}}>
						<p className="font-bold">4. Người thanh toán</p>
						<p className="mb-1">Họ và tên</p>
						<p className="mb-1">Giới tính</p>
						<p className="mb-1">Địa chỉ</p>
						<p className="mb-1">Tỉnh/thành phố</p>
						<p className="mb-1">Quốc gia</p>
						<p className="mb-1">Số điện thoại/Fax</p>
						<p className="mb-1">Email</p>
					</td>
					<td>
						<p></p>
						<p className="mb-1">{item.customer.name}</p>
						<p className="mb-1">{item.customer.numberId}</p>
						<p className="mb-1">{item.customer.address}</p>
						<p className="mb-1">{item.customer.city}</p>
						<p className="mb-1">Việt Nam</p>
						<p className="mb-1">{item.customer.phone}</p>
						<p className="mb-1">{item.customer.email}</p>
					</td>
				</tr>
			</table>
			<p style={printStyle.p2}><b>Chủ thể đăng ký tên miền cam kết tuân thủ các quy định của pháp luật về quản lý và sử dụng tài nguyên Internet.</b></p>
			<table>
				<tr>
					<td style={{border:0, width: '60%'}}></td>
					<td style={{border:0}}>
						<p className="text-center"><i>Hồ Chí Minh, ngày {dd} tháng {mm} năm {yyyy}.</i></p>
						<p className="text-center"><b>Xác nhận của chủ thể đăng ký tên miền</b></p>
						<p className="text-center"><i>(Ký và ghi rõ họ tên)</i></p>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default PrintDomainQT;