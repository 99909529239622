import style from "../style/Personnel.module.scss";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import className from "classnames/bind";
import ActionBar from "~/layout/ActionBar";
import {notification} from "antd";
import {
	Loading,
	Button,
	Modal,
	Icon
} from "~/components";
import {
	PersonnelFormGroupSwitch,
	PersonnelFormEdit,
	PersonnelFormPay,
	PersonnelSearchBar,
	PersonnelSearchMobile,
	PersonnelTable,
	PersonnelFormLeader,
	PersonnelFormRole,
	PersonnelCard,
	PersonnelFormPass
} from "../components";

import {
	personnelErrorSelector,
	personnelItemsSelector,
	personnelLoadingSelector,
	personnelPaginationSelector,
	personnelFilterSelector,
	personnelActions
} from "../personnelSlice";
import {userApi} from "~/api";
import {
	apiError,
	strToTime,
	handleRequest
} from "~/utils";
import {
	useCan,
	useDevice,
	useGroup
} from "~/hooks";

const cn = className.bind(style);

function Personnel() {

	const {isMobile} = useDevice();

	const can = {
	    viewAll: useCan('personnelListAll'),
		viewByDepartment: useCan('personnelListDepartment'),
		viewByGroup: useCan('personnelListGroup'),
		viewGroup: useCan('groupListAll'),
		viewGroupByManager: useCan('groupListGroup'),
	}

	const dispatch = useDispatch();

	const items = useSelector(personnelItemsSelector);

	const loading = useSelector(personnelLoadingSelector);

	const error = useSelector(personnelErrorSelector);

	const pagination = useSelector(personnelPaginationSelector);

	const filter = useSelector(personnelFilterSelector);

	//Group items
	const listGroup = useGroup();

	const [itemEdit, setItemEdit] = useState({});

	//Modal show
	const [openModal, setOpenModal] = useState({
		edit    : false,
		group   : false,
		pay     : false,
		leader  : false,
		role    : false,
		delete  : false,
		card    : false,
		password: false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		if(can.viewAll || can.viewByDepartment || can.viewByGroup) {
			dispatch(personnelActions.fetchData(filter));
		}
	}, [filter, can.viewAll, can.viewByDepartment, can.viewByGroup]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		if(can.viewAll || can.viewByDepartment || can.viewByGroup) {
			dispatch(personnelActions.fetchData(filter));
		}
	}

	//Edit users
	const handleUserEdit = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhân viên để cập nhật'});
			return;
		}

		data.birthday = strToTime(data.birthday);

		data.id = item.id;

		let [error, response] = await handleRequest(userApi.update(data));

		let message = apiError(`Cập nhật thông tin nhân viên thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin nhân viên thành công`});
			dispatch(personnelActions.update(response.data));
			handleModalClose('edit');
		}
	}

	//Delete users
	const handleUserDelete = async () => {
		if(typeof itemEdit.id == 'undefined') {
			notification.error({ message: 'Lỗi', description: 'Không xác định được nhân viên cần cho nghỉ việc'});
		}
		if(itemEdit.status == 'trash') {
			notification.error({ message: 'Lỗi', description: 'Nhân viên này đã nghỉ việc'});
		}
		let [error, response] = await handleRequest(userApi.block(itemEdit.id));
		let message = apiError(`Cho nhân viên nghỉ việc thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cho nhân viên nghỉ việc thành công`});
			dispatch(personnelActions.delete(itemEdit.id));
			handleModalClose('delete');
		}
	}

	//Group Switch
	const handleUserGroupSwitch = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhân viên để cập nhật'});
			return;
		}

		if (data?.group == 'undefined' || data.group == 0) {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhóm cần chuyển'});
			return;
		}

		if (data.group == item.group) {
			notification.error({message: 'Lỗi', description: 'Nhóm của nhân viên không thay đổi'});
			return;
		}

		let userItem = { toGroup: data.group, id : item.id };

		let [error, response] = await handleRequest(userApi.groupSwitch(userItem));
		let message = apiError(`Cập nhật thông tin nhân viên thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin nhân viên thành công`});
			item.group = data.group;
			dispatch(personnelActions.update(item));
			handleModalClose('group');
		}
	}

	//Pay
	const handleUserPay = async (data, item) => {

		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhân viên để cập nhật'});
			return;
		}

		let userItem = {...data};

		if (userItem.payTime != "") {
			userItem.payTime = Math.round(new Date(userItem.payTime).getTime() / 1000)
		}
		if (userItem.payTypeTime != "") {
			userItem.payTypeTime = Math.round(new Date(userItem.payTypeTime).getTime() / 1000)
		}

		userItem.id = item.id;

		let [error, response] = await handleRequest(userApi.pay(userItem));
		let message = apiError(`Cập nhật thông tin nhân viên thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin nhân viên thành công`});
			if(typeof response.data.pay != 'undefined') item.pay = response.data.pay;
			if(typeof response.data.payType != 'undefined') item.payType = response.data.payType;
			if(typeof response.data.payManagerMilestone != 'undefined') item.payManagerMilestone = response.data.payManagerMilestone;
			dispatch(personnelActions.update(item));
			handleModalClose('pay');
		}
	}

	//Leader
	const handleUserLeader = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhân viên để cập nhật'});
			return;
		}

		let userItem = {...data};

		userItem.id = item.id;

		let [error, response] = await handleRequest(userApi.leader(userItem));
		let message = apiError(`Cập nhật thông tin nhân viên thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin nhân viên thành công`});
			item.leaderUserList = userItem.users;
			dispatch(personnelActions.update(item));
			handleModalClose('leader');
		}
	}

	//Role
	const handleUserRole = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhân viên để cập nhật'});
			return;
		}

		if (data?.role == 'undefined' || data.role == 0) {
			notification.error({message: 'Lỗi', description: 'Không có thông tin chức vụ cần chuyển'});
			return;
		}

		if (data.role == item.role) {
			notification.error({message: 'Lỗi', description: 'Chức vụ của nhân viên không thay đổi'});
			return;
		}

		let userItem = { role: data.role, id: item.id};

		let [error, response] = await handleRequest(userApi.update(userItem));
		let message = apiError(`Cập nhật thông tin nhân viên thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin nhân viên thành công`});
			item.role = data.role;
			item.department = response.data.department;
			dispatch(personnelActions.update(item));
			handleModalClose('role');
		}
	}

	const handleChangePassword = async (data, item) => {

		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhân viên để cập nhật'});
			return;
		}

		data.id = item.id;

		let [error, response] = await handleRequest(userApi.password(data));

		let message = apiError(`Cập nhật mật khẩu thất bại`, error, response);

		if (!message) {
			notification.success({
				message: 'Thành công', description: `Đổi mật khẩu thành công`
			});
			handleModalClose('password');
		}
	}

	//Search
	const SearchBar = (isMobile) ? PersonnelSearchMobile : PersonnelSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(personnelActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(personnelActions.setFilterWithDebounce(newFilter));
	};

	const handleFilterChange = (newFilter) => {
		dispatch(personnelActions.setFilter(newFilter));
	};

	//Modal
	const ModalEdit = () => {
	    if(!openModal.edit || !itemEdit?.id) return false;
		return (
			<Modal title="Cập nhật thông tin" visible={openModal.edit} onCancel={() => handleModalClose('edit')}>
				{itemEdit?.id && <PersonnelFormEdit item={itemEdit} onHandleSubmit={handleUserEdit} />}
			</Modal>
		)
	}
	const ModalGroupSwitch = () => {
		if(!openModal.group || !itemEdit?.id) return false;
		return (
			<Modal title="Chuyển nhóm" visible={openModal.group} onCancel={() => handleModalClose('group')}>
				<PersonnelFormGroupSwitch item={itemEdit} onHandleSubmit={handleUserGroupSwitch} listGroup={listGroup} />
			</Modal>
		)
	}
	const ModalPay = () => {
		if(!openModal.pay || !itemEdit?.id) return false;
		return (
			<Modal title="Lương" visible={openModal.pay} onCancel={() => handleModalClose('pay')}>
				<PersonnelFormPay item={itemEdit} onHandleSubmit={handleUserPay} />
			</Modal>
		)
	}
	const ModalRole = () => {
		if(!openModal.role || !itemEdit?.id) return false;
		return (
			<Modal title="Chức vụ" visible={openModal.role} onCancel={() => handleModalClose('role')}>
				<PersonnelFormRole item={itemEdit} onHandleSubmit={handleUserRole} />
			</Modal>
		)
	}
	const ModalLeader = () => {
		if(!openModal.leader || !itemEdit?.id) return false;
		return (
			<Modal title="Nhóm trưởng" visible={openModal.leader} onCancel={() => handleModalClose('leader')}>
				<PersonnelFormLeader item={itemEdit} onHandleSubmit={handleUserLeader} />
			</Modal>
		)
	}

	const ModalPassword = () => {
		if(!openModal.password || !itemEdit?.id) return false;
		return (
			<Modal title="Đổi mật khẩu" visible={openModal.password} onCancel={() => handleModalClose('password')}>
				<PersonnelFormPass item={itemEdit} onHandleSubmit={handleChangePassword} />
			</Modal>
		)
	}

	const ModalDelete = () => {
		if(!openModal.delete || !itemEdit?.id) return false;
		return (
			<Modal title="Xóa Nhân Viên" visible={openModal.delete} onCancel={() => handleModalClose('delete')}>
				<p>Bạn muốn cho nhân viên <b>{itemEdit.firstname+' '+itemEdit.lastname}</b> nghỉ việc ?</p>
				<div className="d-flex justify-content-end gap modal-bottom pd-1">
					<Button white leftIcon={Icon.close} onClick={() => handleModalClose('delete')}> Đóng </Button>
					<Button primary leftIcon={Icon.delete} onClick={handleUserDelete}> Đồng ý </Button>
				</div>
			</Modal>
		)
	}
	const ModalCard = () => {
		if(!openModal.card || !itemEdit?.id) return null;
		return (
			<Modal title="Thẻ nhân viên" visible={openModal.card} onCancel={() => handleModalClose('card')}>
				<PersonnelCard item={itemEdit} />
			</Modal>
		)
	}

	return (
		<>
			<ActionBar title={'Nhân viên'}>
				<Button background blue to="/personnel">{Icon.user}</Button>
				{(can.viewGroup || can.viewGroupByManager) && <Button outline blue to="/personnel/group">{Icon.users}</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className={cn('container')}>
				<div className={cn('content')}>
					{loading && <Loading/>}
					<SearchBar listGroup={listGroup} filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange} />
					{items && <PersonnelTable
						items={items}
						listGroup={listGroup}
						onDelete={handleUserDelete}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						pagination={pagination}
					/>}
				</div>
				<ModalEdit />
				<ModalGroupSwitch />
				<ModalPay />
				<ModalRole />
				<ModalLeader />
				<ModalDelete />
				<ModalCard />
				<ModalPassword />
			</div>
		</>
	)
}

export default Personnel;