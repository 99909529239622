import {useState, useMemo, useContext, useEffect} from "react";
import { AppContext } from "~/context/AppProvider";
import {Col, Row} from "antd";
import {
	Button,
	StatusLabel,
	Icon
} from "~/components";
import {FileList, FileUpload} from "~/components/Forms";
import {
	renderDate,
} from "~/utils";
import {
	useCan,
	useUtilities
} from "~/hooks";
import _ from "lodash";

function BuyServiceFormInfo({item, modalOpen, onClickStatus, onRemoveFile, onUpload}) {

	const {utilities} = useUtilities();

	const {country} = useContext(AppContext);

	const [loadingStatus, setLoadingStatus] = useState({
		success : false,
		cancel : false,
	});

	const can = {
		edit: useCan('buyServiceEdit'),
		devStatus: useCan('buyServiceDevStatus'),
		assignStatus: useCan('buyServiceAssignStatus'),
	};

	const companyAddress = useMemo(() => {
		if(item.for == 'canhan') return '';
		let province = '';
		let district = '';
		let ward = '';
		if(item.companyProvince == '') return '';
		if(country.city[item.companyProvince] != undefined) {
			province = country.city[item.companyProvince];
		}
		if(province == '' || item.companyDistrict == '') return province;

		if(country.district[item.companyDistrict] != undefined && country.district[item.province][item.companyDistrict] != undefined) {
			district = country.district[item.companyProvince][item.companyDistrict];
		}
		if(district == '') return province;
		if(item.companyWard == '') return district+', '+province;
		if(country.ward[item.companyDistrict] != undefined && country.ward[item.companyDistrict][item.companyWard] != undefined) {
			ward = country.ward[item.companyDistrict][item.companyWard];
		}
		if(ward == '') return district+', '+province;
		return ward+', '+district+', '+province;
	}, [
		item.for,
		item.companyProvince,
		item.companyDistrict,
		item.companyWard
	]);

	const address = useMemo(() => {
		let province = '';
		let district = '';
		let ward = '';
		if(item.province == '') return '';
		if(country.city[item.province] != undefined) {
			province = country.city[item.province];
		}
		if(province == '' || item.district == '') return province;

		if(country.district[item.province] != undefined && country.district[item.province][item.district] != undefined) {
			district = country.district[item.province][item.district];
		}
		if(district == '') return province;
		if(item.ward == '') return district+', '+province;
		if(country.ward[item.district] != undefined && country.ward[item.district][item.ward] != undefined) {
			ward = country.ward[item.district][item.ward];
		}
		if(ward == '') return district+', '+province;
		return ward+', '+district+', '+province;
	}, [
		item.province,
		item.district,
		item.ward,
	]);

	let [files, setFiles] = useState([]);

	useEffect(() => {
		if(item?.id) {
			files = [];
			if(!_.isEmpty(item.attachFile)) {
				for (const [path, name] of Object.entries(item.attachFile)) {
					files.push({path, name})
				}
			}
			setFiles(files);
		}
	}, [item]);

	const handleRemoveFileClick = (filename) => {
		setFiles(files.filter(file => file.name !== filename))
		onRemoveFile(filename);
	}
	const handleStatusClick = async (status) => {
		setLoadingStatus(prevState => ({
			...prevState,
			[status]: true
		}));
		if (onClickStatus) {
			await onClickStatus(status, item);
		}
		setLoadingStatus(prevState => ({
			...prevState,
			[status]: false
		}));
	}
	const handleEditClick = () => {
		modalOpen('addEdit');
	}
	const renderFor = (ufor) => {
		if(ufor == 'canhan') {
			return <StatusLabel small type="green">Cá nhân</StatusLabel>
		}
		if(ufor == 'congty') {
			return <StatusLabel small type="blue">Công ty</StatusLabel>
		}

		return <StatusLabel small type="green">Không xác định</StatusLabel>
	}
	const renderCompanyInfo = (item, companyAddress) => {
		return (
			<>
				<p className="heading">Thông tin công ty</p>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Tên công ty</Col>
					<Col className="gutter-row" span={18}>{item.companyName}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Mã số thuế</Col>
					<Col className="gutter-row" span={18}>{item.taxCode}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Địa chỉ</Col>
					<Col className="gutter-row" span={18}>{`${item.companyAddress}, ${companyAddress}`}</Col>
				</Row>
			</>
		)
	}
	const renderCustomerInfo = (item, address) => {
		return (
			<>
				<p className="heading">Thông tin khách hàng</p>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Tên khách hàng</Col>
					<Col className="gutter-row" span={18}>{item.name}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Số CCCD/CMND</Col>
					<Col className="gutter-row" span={18}>{item.numberId}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Email</Col>
					<Col className="gutter-row" span={18}>{item.email}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Phone</Col>
					<Col className="gutter-row" span={18}>{item.phone}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Địa chỉ</Col>
					<Col className="gutter-row" span={18}>{`${item.address}, ${address}`}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Sinh nhật</Col>
					<Col className="gutter-row" span={18}>{renderDate(item.birthday)}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Giới tính</Col>
					<Col className="gutter-row" span={18}>{item.gender}</Col>
				</Row>
			</>
		)
	}
	const renderAttach = () => {
		return (
			<>
				<FileUpload name="file" files={files} setFiles={setFiles} onChange={onUpload} />
				<FileList files={files} removeFile={handleRemoveFileClick} />
			</>
		)
	}

	return (
		<div className="form">

			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" sm={24} xl={12}>
					<p className="heading">Thông tin</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Tên miền</Col>
						<Col className="gutter-row" span={18}><b>{`${item.domain}`}</b></Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Hợp đồng</Col>
						<Col className="gutter-row" span={18}><StatusLabel small type={utilities.services[item.contract.service].color}>{item.contract.code}</StatusLabel></Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Loại dịch vụ</Col>
						<Col className="gutter-row" span={18}><StatusLabel small type={utilities.services[item.contract.service].color}>{utilities.services[item.contract.service].label}</StatusLabel></Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Loại thông tin</Col>
						<Col className="gutter-row" span={18}>{renderFor(item.for)}</Col>
					</Row>
					{item.for == 'congty' && renderCompanyInfo(item, companyAddress)}
					{renderCustomerInfo(item, address)}
				</Col>
				<Col className="gutter-row" sm={24} xl={12}>
					<p className="heading">Đính kèm</p>
					{renderAttach()}
				</Col>
			</Row>
			<div className="form-group d-flex justify-content-center modal-bottom">
				{ can.edit && <Button background blue leftIcon={Icon.edit} onClick={handleEditClick}>Cập nhật</Button>}
				{
					(item.status == 'pending') && (<Button loading={loadingStatus.success} onClick={() => handleStatusClick('success')} background green leftIcon={Icon.success}>Đã mua</Button>)
				}
				{
					(item.status != 'cancel') && (<Button loading={loadingStatus.cancel} onClick={() => handleStatusClick('cancel')} background red leftIcon={Icon.close}>Hủy yêu cầu</Button>)
				}
			</div>
		</div>
	)
}

export default BuyServiceFormInfo;