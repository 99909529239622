import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import Tippy from '@tippyjs/react/headless';
import ActionBar from "~/layout/ActionBar";
import {
	Button, Icon, PopperWrapper, Loading, Modal
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	serviceToDoApi
} from "~/api";
import {
	serviceToDoActions,
	serviceToDoErrorSelector,
	serviceToDoFilterSelector,
	serviceToDoItemsSelector,
	serviceToDoLoadingSelector,
	serviceToDoPaginationSelector
} from "../serviceToDoSlice";
import {
	ServiceToDoTable,
	ToDoFormAddDomain,
} from "../components";
import {useDevice} from "~/hooks";

function ServiceToDo() {

	const {isMobile} = useDevice();

	const dispatch      = useDispatch();

	const items         = useSelector(serviceToDoItemsSelector);

	const loading       = useSelector(serviceToDoLoadingSelector);

	const error         = useSelector(serviceToDoErrorSelector);

	const pagination    = useSelector(serviceToDoPaginationSelector);

	const filter        = useSelector(serviceToDoFilterSelector);

	//Model
	const [openModal, setOpenModal] = useState({
		status   : false,
		domain : false
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(serviceToDoActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}
	//Thêm yêu cầu
	const handleToDoDomainAdd = async (data) => {
		let [error, response] = await handleRequest(serviceToDoApi.addDomain(data));
		let message = apiError(`Thêm yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm yêu cầu thành công`});
			dispatch(serviceToDoActions.add(response.data));
			handleModalClose('domain')
		}
	}

	const handleStatus = async (item, status) => {

		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu dịch vụ'});
			return;
		}
		let [error, response] = await handleRequest(serviceToDoApi.status({
			status: status, id : item.id
		}));
		let message = apiError(`Cập nhật trạng thái yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái yêu cầu thành công`});
			let newItem = JSON.parse(JSON.stringify(item));
			newItem.status          = response.data.status;
			dispatch(serviceToDoActions.update(newItem));
		}
	}

	const handleFinished = async (item, status) => {

		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu dịch vụ'});
			return;
		}
		let [error, response] = await handleRequest(serviceToDoApi.finished({
			status: status, id : item.id
		}));
		let message = apiError(`Cập nhật trạng thái yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái yêu cầu thành công`});
			let newItem = JSON.parse(JSON.stringify(item));
			newItem.userActionId    = response.data.userActionId;
			newItem.timeAction      = response.data.timeAction;
			newItem.userAction      = response.data.userAction;
			newItem.finished        = response.data.finished;
			dispatch(serviceToDoActions.update(newItem));
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(serviceToDoActions.setFilter({...filter, page }));
	}

	//Modal
	const ModalDomain = () => {
		if(!openModal.domain) return null;
		return (
			<Modal title="Cập nhật thông tin" visible={openModal.domain} onCancel={() => handleModalClose('domain')}>
				<ToDoFormAddDomain onHandleSubmit={handleToDoDomainAdd} />
			</Modal>
		)
	}

	return (
		<Fragment>
			<ActionBar title={'Công Việc'}>
				<div className="action-btn">
					<Tippy interactive placement="bottom" offset={[-40, 10]} arrow={true}
						render={() => (
							<PopperWrapper className="action-wrapper" style={{minWidth: '220px', }}>
								<Button key="upgrade" leftIcon={Icon.upload} onClick={() => handleModalOpen('domain')}>Thêm tên miền</Button>
							</PopperWrapper>
						)}
					>
						<div><Button outline leftIcon={Icon.plusCircle} onClick={() => handleModalOpen('add')}>{!isMobile && 'Thêm mới'}</Button></div>
					</Tippy>
				</div>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <ServiceToDoTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						openModal={handleModalOpen}
						handleStatus={handleStatus}
						handleFinished={handleFinished}
					/>}
				</div>
				<ModalDomain/>
			</div>
		</Fragment>
	)
}
export default ServiceToDo;