import {Pagination, Table} from "antd";
import {
	Image,
	StatusLabel,
	PopperUserInfo,
} from "~/components";
import BuyServiceAction from "./BuyServiceAction";
import {renderDate} from "~/utils";
import {useUtilities} from "~/hooks";

function BuyServiceTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

	const {utilities} = useUtilities();

	const renderFor = (ufor) => {

		if(ufor == 'canhan') {
			return <StatusLabel small type="green">Cá nhân</StatusLabel>
		}
		if(ufor == 'congty') {
			return <StatusLabel small type="blue">Công ty</StatusLabel>
		}

		return <StatusLabel small type="green">Không xác định</StatusLabel>
	}
	const columns = [
		{ title: 'Hợp đồng', dataIndex: 'contract', key: "contract", width: '400px', render: (_, item) => (
			<>
				{(item.contract?.id) && <p><StatusLabel small type="green">{item.contract.code}</StatusLabel> {item.contract.name}</p> }
				<p><b>{item.domain}</b></p>
				<p style={{fontSize:'11px', color:'#cccc'}}>Ngày tạo {renderDate(item?.created)}</p>
			</>
		)},
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", width: '150px', render: (_, item) => (
			item.user?.id &&
			<PopperUserInfo user={item.user}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.user?.avatar} /></div>
					<div>
						<b>{item.user?.lastname}</b>
						<p>{item.user?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{title: 'Loại', dataIndex: 'for', key: "for'", render: (_, item) => (renderFor(item?.for))},
		{title: 'Dịch vụ', dataIndex: 'service', key: 'service', render:(_, item) => (
			item.contract?.id && <StatusLabel small type={utilities.services[item.contract.service].color}>{utilities.services[item.contract.service].label}</StatusLabel>
		)},
		{title: 'Loại dịch vụ', dataIndex: 'serviceType', key: 'serviceType', render:(_, item) => (
			item.contract?.id &&
			<>
				{item.type == 'domain' && <StatusLabel small type={utilities.services[item.contract.service].color}>{(item.option == 'vn') ? 'Việt Nam' : 'Quốc Tế'}</StatusLabel>}
				{(item.type != 'domain' && typeof utilities.services[item.contract.service] != 'undefined') &&
					<StatusLabel small type={utilities.services[item.contract.service].color}>
						{utilities.services[item.contract.service]?.options[item.contract?.option]?.label}
					</StatusLabel>
				}
			</>
		)},
		{title: 'Đính kèm', dataIndex: 'attach', key: "attach", width: '150px', render: (_, item) => (
			<>
				{(item.attachFileTotal == 0) ? <StatusLabel small type="red">Không đính kèm</StatusLabel> : <StatusLabel small type="green">Có đính kèm</StatusLabel> }
			</>
		)},
		{title: 'Tình trạng', dataIndex: 'status', key: "status", width: '120px', render: (_, item) => (
			<>
				{(item.status == 'pending') && <StatusLabel small type="yellow">Đợi duyệt</StatusLabel>}
				{(item.status == 'success') && <StatusLabel small type="green">Đã mua</StatusLabel>}
				{(item.status == 'cancel') && <StatusLabel small type="red">Đã Hủy</StatusLabel>}
			</>
		)},
		{title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<BuyServiceAction
				key={`buyService-table-action-${item.id}`}
				item={item}
				setItemEdit={setItemEdit}
				openModal={openModal}
			/>
		)},
	];
	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:'55vh', x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default BuyServiceTable;