import StatusLabel from "~/components/StatusLabel";
import {Button, Pagination, Table} from "antd";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {
	LIST_CONTRACT_STATUS
} from "~/app/constants";
import ContractAction from "./ContractAction";
import {useDevice, useUtilities} from "~/hooks";
import ContractCustomerAction from "./ContractCustomerAction";

function ContractTable({items = [], filter, pagination, onPaginationChange, onChangeFilter, setItemEdit, openModal, can}) {

	const {utilities} = useUtilities();

	const {isMobile} = useDevice();

	const renderPercent = (price, amountRemaining) => {
		let percent = 0;
		let className   = "";
		if(amountRemaining == 0) {
			percent = 100;
			className = "color-green"
		}
		else if(amountRemaining == price) percent = 0;
		else percent = Math.round((price - amountRemaining)*100/price)

		return (
			<span className={className}>({percent}%)</span>
		)
	}

	const handleCustomerClick = (customerId) => {
		if (!onChangeFilter) return;
		onChangeFilter('customerId', customerId, true);
	}

	let columns = [
		{ title: 'Hợp đồng', dataIndex: 'code', key: "code", width: '400px', render: (_, item) => (
			<div>
				<b>{`${item?.code} ${item?.name}`}</b>
				<p>{isMobile && <Button onClick={() => handleCustomerClick(item.customerId)}><b style={{color : 'var(--primary)'}}>KH{item.customerId}</b></Button> } {item?.domain}</p>
				{isMobile &&
					<div>
						<StatusLabel small type={LIST_CONTRACT_STATUS[item.status].color}>{LIST_CONTRACT_STATUS[item.status].label}</StatusLabel>
						<StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>
					&nbsp;<StatusLabel small type={utilities.services[item.service].color}>{utilities.serviceTypes[item.serviceType].label}</StatusLabel>
					&nbsp;{item.option.length != 0 && <StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].options[item.option].label}</StatusLabel>}
					</div>
				}
			</div>
		)},
		{ title: 'Khách hàng', dataIndex: 'customer', key: "customer", width: '120px', render: (_, item) => (
			<ContractCustomerAction item={item} openModal={openModal} setItemEdit={setItemEdit} onChangeFilter={onChangeFilter}/>
		)},
		{ title: 'Phân công', dataIndex: 'user', key: "user", width: '200px', render: (_, item) => (
			<div>
				{item.user?.id && <p>{`${item.user?.firstname} ${item.user?.lastname}`}</p>}
				{item.userShare?.id && <p>{`${item.userShare?.firstname} ${item.userShare?.lastname}`}</p>}
			</div>
		)},
		{ title: 'Ngày ký', dataIndex: 'signing', key: "signing", width: '120px', render: (_, item) => (
			<div><p>{renderDate(item?.signing)}</p></div>
		)},
		{ title: 'Giá trị', dataIndex: 'price', key: "price", width: '180px', render: (_, item) => (
			<div>
				<p><b>{numberFormat(item?.price)}đ</b></p>
				<p className="color-grey">{(item?.amountRemaining == 0) ? 'Đã thanh toán' : numberFormat(item?.amountRemaining)} {renderPercent(item?.price, item?.amountRemaining)}</p>
			</div>
		)},
		{title: 'Dịch vụ', dataIndex: 'service', key: 'service', render:(_, item) => (
			<div>
				<StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>
				&nbsp;<StatusLabel small type={utilities.services[item.service].color}>{utilities.serviceTypes[item.serviceType].label}</StatusLabel>
				&nbsp;{item.option.length != 0 && <StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].options[item.option].label}</StatusLabel>}
			</div>
		)},
		{title: 'Trạng thái', dataIndex: 'status', key: "status", width: '140px', render: (_, item) => (
			<StatusLabel small type={LIST_CONTRACT_STATUS[item.status].color}>{LIST_CONTRACT_STATUS[item.status].label}</StatusLabel>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<ContractAction
				key={item.id}
				item={item}
				setItemEdit={setItemEdit}
				openModal={openModal}
				can={can}
			/>
		)},
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'customer'
				&& currentValue.key != 'status'
				&& currentValue.key != 'service'
			)
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:'55vh', x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default ContractTable;