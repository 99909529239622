import StatusLabel from "~/components/StatusLabel";
import {Pagination, Table} from "antd";
import CustomerAction from "./CustomerAction";
import {renderDate} from "~/utils";
import {useUtilities} from "~/hooks";


function CustomerTable({items = [], pagination, onPaginationChange, setItemEdit, openModal, handleStatus}) {

	const {utilities} = useUtilities();

	const listStatus = {
		'pending': {
			label: <StatusLabel type="yellow">Chờ xác nhận</StatusLabel>
		},
		'confirm': {
			label: <StatusLabel type="green">Đã xác nhận</StatusLabel>
		},
		'stop': {
			label: <StatusLabel type="red">Ngưng sử dụng</StatusLabel>
		},
	}

	const renderFor = (ufor) => {

		if(ufor == 'canhan') {
			return <StatusLabel small type="green">Cá nhân</StatusLabel>
		}
		if(ufor == 'congty') {
			return <StatusLabel small type="blue">Công ty</StatusLabel>
		}

		return <StatusLabel small type="green">Không xác định</StatusLabel>
	}

	const columns = [
		{ title: 'Mã KH', dataIndex: 'code', key: "code", width: '100px', render: (_, item) => (
			<div>
				<b>{item?.code}</b>
				<p>{renderFor(item?.for)}</p>
			</div>
		)},
		{ title: 'Khách hàng', dataIndex: 'name', key: "name", width: '200px', render: (_, item) => (
			<div>
				<b style={{color : 'var(--primary)'}}>{item.companyName}</b>
				<p><b>{item.name}</b></p>
				{item.user?.id && <p>{`${item.user?.firstname} ${item.user?.lastname}`}</p>}
				{item.userShare?.id && <p>{`${item.userShare?.firstname} ${item.userShare?.lastname}`}</p>}
			</div>
		)},
		{ title: 'Liên hệ', dataIndex: 'contact', key: "contact", width: '200px', render: (_, item) => (
			<div>
				<p>{item?.phone}</p>
				<p>{item?.email}</p>
			</div>
		)},
		{ title: 'Dịch vụ', dataIndex: 'serviceCount', key: "serviceCount", width: '100px', render: (_, item) => (
			<div><p>{item?.services.length}</p></div>
		)},
		{ title: 'Trạng thái', dataIndex: 'contact', key: "contact", width: '140px', render: (_, item) => (
			<p>{listStatus[item?.status].label}</p>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<CustomerAction key={item.id} item={item} setItemEdit={setItemEdit} openModal={openModal} handleStatus={handleStatus}/>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	const expandedRowRender = (record) => {
		const columns = [
			{title: 'Tên miền', dataIndex: 'domain', key: 'domain'},
			{title: 'Dịch vụ', dataIndex: 'service', key: 'service', render:(_, item) => (<StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>)},
			{title: 'Loại dịch vụ', dataIndex: 'type', key: 'type', render:(_, item) => (<StatusLabel small type={utilities.serviceTypes[item.type].color}>{utilities.serviceTypes[item.type].label}</StatusLabel>)},
			{title: 'Ngày ký', dataIndex: 'register', key: "register", responsive: ['lg'], render: (_, item) => (renderDate(item?.register)) },
			{title: 'Ngày hết hạn', dataIndex: 'register', key: "expired", responsive: ['lg'], render: (_, item) => (renderDate(item?.expired)) },
		];
		const dataSource= record.services.map(row => ({
			key: row.id, // I added this line
			...row
		}));

		return <Table columns={columns} dataSource={dataSource} pagination={false} />;
	};

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:'55vh', x: 'max-content' }} pagination={false}
					expandable={{expandedRowRender: expandedRowRender,}}
				/>
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default CustomerTable;