import style from '../style/Task.module.scss';
import {
	Fragment,
	useState,
	useEffect,
	useContext
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {Modal, Icon, Button, Loading} from "~/components";
import _ from "lodash"
import {
	projectTaskActions,
	projectTaskErrorSelector,
	projectTaskFilterSelector,
	projectTaskItemsSelector,
	projectTaskLoadingSelector,
	projectTaskPaginationSelector
} from "../projectTaskSlice";
import {
	ProjectList,
	TasksFormAdd,
	TasksFormEdit,
	TasksFormInfo,
	TasksFormStatus,
	TaskSearchBar,
	TaskSearchMobile
} from "../../Task/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	taskApi,
	userApi
} from "~/api";
import {
	useDevice,
	useSocket
} from "~/hooks";
import {AppContext} from "~/context/AppProvider";
import className from "classnames/bind";
const cn = className.bind(style);
function Task() {

	const {
		notificationCount,
		setNotificationCount
	} = useContext(AppContext);

	const {socket} = useSocket();

	const {isMobile} = useDevice();

	const {contractId, taskId} = useParams();

	const dispatch  = useDispatch();

	const items     = useSelector(projectTaskItemsSelector);

	const loading   = useSelector(projectTaskLoadingSelector);

	const error     = useSelector(projectTaskErrorSelector);

	const pagination = useSelector(projectTaskPaginationSelector);

	const filter    = useSelector(projectTaskFilterSelector);

	const [itemEdit, setItemEdit]   = useState({});

	const [project, setProject]     = useState(0);

	let [listAssign, setListAssign] = useState([]);

	let [listDesign, setListDesign] = useState([]);

	useEffect(() => {
		const loadAssignAndDesign = async () => {
			const [assignRes, designRes] = await Promise.all([
				handleRequest(userApi.search({'department': 'technical', 'status': 'public'})),
				handleRequest(userApi.search({'department': 'design', 'status': 'public'}))
			]);

			let [assignErr, assignData] = assignRes;
			let [designErr, designData] = designRes;

			let assignMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, assignErr, assignData);
			let designMsg = apiError(`Load danh sách nhân viên design thất bại`, designErr, designData);

			if (!assignMsg) setListAssign(assignData.data);
			if (!designMsg) setListDesign(designData.data);
		};

		loadAssignAndDesign().then();
	}, []);

	//Model
	const [openModal, setOpenModal] = useState({
		add         : false,
		edit        : false,
		delete      : false,
		info        : false,
		status      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	useEffect(() => {
		if(!isNaN(contractId)) {
			const newFilter = {
				...filter,
				contractId: contractId,
				page: 1
			};
			dispatch(projectTaskActions.setFilter(newFilter));
		}
	}, [contractId])

	//Load data
	useEffect(() => {
		let newFilter = {...filter, haveTask: true }
		dispatch(projectTaskActions.fetchData(newFilter));
	}, [filter]);

	//Load task detail
	useEffect(() => {
		if(!isNaN(taskId)) {
			setProject(items[0]);
			setItemEdit(items[0]?.tasks.find(item => item.id === parseInt(taskId)));
			handleModalOpen('info');
		}
	}, [taskId, items[0]])

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		let newFilter = {...filter, haveTask: true }
		dispatch(projectTaskActions.fetchData(newFilter));
	}

	//Submit
	const handleAdd = async (data, project) => {

		if (project?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để thêm tasks'});
			return;
		}

		data.projectId = project.id;

		if(_.isEmpty(data.tasks)) {
			notification.error({message: 'Lỗi', description: 'Không có tasks nào để thêm'});
			return;
		}

		for (const [index, task] of Object.entries(data.tasks)) {
			if (!isNaN(task.deadline)) {
				task.deadline = Math.round(new Date(+task.deadline).getTime() / 1000)
			} else if (task.deadline.search('GMT') != -1) {
				task.deadline = Math.round(new Date(task.deadline).getTime() / 1000)
			}
			data.tasks[index].deadline = task.deadline;
		}

		let [error, response] = await handleRequest(taskApi.adds(data));

		let message = apiError(`Thêm mới task thất bại`, error, response);

		if(!message) {

			notification.success({message: 'Thành công', description: `Thêm mới task thành công`});

			let newProject = JSON.parse(JSON.stringify(project));

			newProject.totalTask = response.data.project.totalTask;
			newProject.unfinishedTask = response.data.project.unfinishedTask;
			newProject.status = response.data.project.status;
			newProject.phase = response.data.project.phase;

			for (const [index, task] of Object.entries(response.data.tasks)) {
				newProject.tasks.unshift({...task})
			}
			setProject(newProject);

			dispatch(projectTaskActions.update(newProject));

			socket.emit("set-notification-menu-count", response.data.notification);

			handleModalClose('add')
		}
	}

	const handleEdit = async (data, item, project) => {

		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin tasks để cập nhật'});
			return;
		}

		data.id = item.id;

		if (!isNaN(data.deadline)) {
			data.deadline = Math.round(new Date(+data.deadline).getTime() / 1000)
		} else if (data.deadline.search('GMT') != -1) {
			data.deadline = Math.round(new Date(data.deadline).getTime() / 1000)
		}

		let [error, response] = await handleRequest(taskApi.update(data));
		let message = apiError(`Cập nhật task thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật task thành công`});
			let newProject = JSON.parse(JSON.stringify(project));
			for (const [index, task] of Object.entries(newProject.tasks)) {
				if(task.id === item.id) {
					if(response.data?.name) newProject.tasks[index].name = response.data.name;
					if(response.data?.deadline) newProject.tasks[index].deadline = response.data.deadline;
					if(response.data?.device) newProject.tasks[index].device = response.data.device;
				}
			}
			dispatch(projectTaskActions.update(newProject));
			handleModalClose('edit')
		}
	}

	//Delete
	const handleDelete = async (item, project) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để xóa'});
			return;
		}
		if (item.status != 'pending' && item.status != 'not-job') {
			notification.error({message: 'Lỗi', description: 'Trang thái hiện tại không cho xóa task'});
			return;
		}
		let [error, response] = await handleRequest(taskApi.delete(item.id));
		let message = apiError(`xóa task thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa task thành công`});

			let newProject = JSON.parse(JSON.stringify(project));
			newProject.totalTask = response.data.project.totalTask;
			newProject.unfinishedTask = response.data.project.unfinishedTask;
			newProject.tasks = newProject.tasks.filter(function(task) {
				return item.id !== task.id
			})
			setProject(newProject);
			dispatch(projectTaskActions.update(newProject));
			handleModalClose('delete')
		}
	}

	//status
	const handleStatus = async (status, item, project) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin task để cập nhật'});
			return;
		}
		if (item?.status == status) {
			notification.error({message: 'Lỗi', description: 'Trạng thái task không thay đổi'});
			return;
		}
		let [error, response] = await handleRequest(taskApi.status({ id: item.id, status}));
		let message = apiError(`Cập nhật trạng thái task thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái task thành công`});
			let newProject = JSON.parse(JSON.stringify(project));
			newProject.unfinishedTask = response.data.project.unfinishedTask;
			newProject.status = response.data.project.status;
			newProject.phase = response.data.project.phase;
			for (const [index, task] of Object.entries(newProject.tasks)) {
				if(task.id === item.id) {

					if(
						newProject.tasks[index].status != 'resolved' &&
						newProject.tasks[index].status != 'closed' &&
						newProject.tasks[index].status != 'not-job' &&
						(newProject.tasks[index].status != response.data.task.status) &&
						(
							response.data.task.status == 'resolved' ||
							response.data.task.status == 'closed' ||
							response.data.task.status == 'not-job'
						)
					) {
						setNotificationCount((prevState) => ({
							...prevState,
							tasks: prevState.tasks - 1,
						}))
					}

					newProject.tasks[index].status      = response.data.task.status;
					newProject.tasks[index].assignId    = response.data.task.assignId;
					newProject.tasks[index].successId   = response.data.task.successId;
					newProject.tasks[index].timeSuccess = response.data.task.timeSuccess;
				}
			}
			dispatch(projectTaskActions.update(newProject));
			handleModalClose('status')
		}
	}

	//upload
	const handleUpload = async (data, item, project) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin task để cập nhật'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(taskApi.fileUpload(data));
		let message = apiError(`Cập nhật task thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Upload File thành công`});
			let newProject = JSON.parse(JSON.stringify(project));
			for (const [index, task] of Object.entries(newProject.tasks)) {
				if(task.id === item.id) {
					newProject.tasks[index].attachFile      = response.data.attachFile;
					newProject.tasks[index].attachFileTotal = response.data.attachFileTotal;
				}
			}
			dispatch(projectTaskActions.update(newProject));
		}
	}

	const handleUploadDelete = async (filename, item, project) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(taskApi.fileRemove({id: item.id, filename: filename}));
		let message = apiError(`Cập nhật task thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật task thành công`});
			let newProject = JSON.parse(JSON.stringify(project));
			for (const [index, task] of Object.entries(newProject.tasks)) {
				if(task.id === item.id) {
					newProject.tasks[index].attachFile      = response.data.attachFile;
					newProject.tasks[index].attachFileTotal = response.data.attachFileTotal;
				}
			}
			dispatch(projectTaskActions.update(newProject));
		}
	}

	//Search
	const SearchBar = (isMobile) ? TaskSearchMobile : TaskSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(projectTaskActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(projectTaskActions.setFilter(newFilter));
	};

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(projectTaskActions.setFilter(newFilter));
	};

	//Modal
	const ModalAdd = () => {
		if(!project?.id || !openModal.add) return null;
		return (<Modal title="Thêm Task" visible={openModal.add} onCancel={() => {handleModalClose('add')}}>
			<TasksFormAdd project={project} onHandleSubmit={handleAdd} />
		</Modal>)
	}
	const ModalEdit = () => {
		if(!project?.id || !openModal.edit) return null;
		return <Modal title="Cập nhật Task" visible={openModal.edit} onCancel={() => {handleModalClose('edit')}}>
			<TasksFormEdit item={itemEdit} project={project} onHandleSubmit={handleEdit} />
		</Modal>
	}
	const ModalStatus = () => {
		if(!project?.id || !itemEdit?.id || !openModal.status) return null;
		return <Modal title="Trạng Thái Task" visible={openModal.status} onCancel={() => {handleModalClose('status')}}>
			<TasksFormStatus project={project} item={itemEdit} onHandleSubmit={handleStatus} />
		</Modal>
	}
	const ModalDelete = () => {
		if(!itemEdit?.id || !openModal.delete) return null;
		return <Modal title="Xóa dự án" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
			<p>Bạn muốn xóa task <b>{itemEdit?.name}</b>?</p>
			<br />
			<div className="d-flex justify-content-end gap">
				<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
				<Button primary leftIcon={Icon.delete} onClick={() => handleDelete(itemEdit, project)}> Xóa </Button>
			</div>
		</Modal>
	}

	return (
		<Fragment>
			<ActionBar title={'Tasks'}>
				<Button outline small onClick={handleReLoading} style={{fontSize:'20px'}}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className={cn('wrapper', 'pd-1')}>
					{loading && <Loading/>}
					<SearchBar filter={filter} onChange={handleFilterChange} onSearchChange={handleSearchChange} listAssign={listAssign} listDesign={listDesign} />
					{items && <ProjectList
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						setProject={setProject}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
					/>}
				</div>
				<ModalAdd />
				<ModalEdit />
				<ModalStatus />
				<ModalDelete />
				{
					(project?.id && itemEdit?.id && openModal.info) && <Modal title="Thông tin Task" size={'xl'} visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
						<TasksFormInfo project={project} item={itemEdit} onClickStatus={handleStatus} onUpload={handleUpload} onRemoveFile={handleUploadDelete} modalOpen={handleModalOpen} />
					</Modal>
				}
			</div>
		</Fragment>
	)
}

export default Task;