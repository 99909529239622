import {useState, useMemo, useCallback} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	SelectUserAll,
	SelectContractAll
} from "~/components/Forms";
import {
	StatusLabel,
	Button,
	Icon,
	Notification
} from "~/components";
import {useCan} from "~/hooks";
import {LIST_PROJECT_STATUS} from "~/app/constants";

function ProjectSearchMobile({filter, listAssign = [], listDesign = [], onChange, onSearchChange}) {

	const can = {
		listAll: useCan('projectListAll'),
		listGroup: useCan('projectListGroup'),
		listDesign: useCan('projectListPhaseDesign'),
		listProgram: useCan('projectListPhaseProgram'),
	};

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_PROJECT_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [LIST_PROJECT_STATUS]);

	const assignOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(listAssign).map(([key, assign]) => {
			return {
				value: assign.id,
				label: `${assign.firstname} ${assign.lastname}`
			};
		})];
	}, [listAssign]);

	const designOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(listDesign).map(([key, design]) => {
			return {
				value: design.id,
				label: `${design.firstname} ${design.lastname}`
			};
		})];
	}, [listDesign]);

	const [searchBar, setSearchBar] = useState(false);

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const handleContractChange = useCallback((value) => {
		if (onChange) { onChange('contractId', value); }
	}, [onChange]);

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			contractId: data.contractId,
			userId: data.userId,
			assignId: data.assignId,
			designId: data.designId,
			status: data.status,
			page: 1,
		};
		setSearchBar(false);
		onSearchChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
					<SelectContractAll service="website" placeholder="Hợp đồng" options={[{value:'', label:'Tất cả'}]} onChange={handleContractChange} />
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<Controller control={control} name="contractId" render={({field}) => (
						<SelectContractAll service="website" label="Hợp đồng" options={[{value:'', label:'Tất cả'}]} errors={errors} {...field} />
					)}/>
					{(can.listAll || can.listGroup || can.listProgram || can.listDesign)  &&
						<Controller control={control} name="userId" render={({ field }) => (
							<SelectUserAll label="Nhân viên" options={[{value:'', label:'Tất cả'}]} errors={errors} {...field} />
						)}/>
					}
					{(!can.listDesign)  &&
						<Controller control={control} name="assignId" render={({ field }) => (
							<SelectField label="Kỹ thuật" options={assignOptions} errors={errors} {...field} />
						)}/>
					}
					<Controller control={control} name="designId" render={({ field }) => (
						<SelectField label="Thiết kế" options={designOptions} errors={errors} {...field} />
					)}/>
					<Controller control={control} name="status" render={({field}) => (
						<SelectField label="Trạng thái" options={statusOptions} errors={errors} {...field} />
					)}/>
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default ProjectSearchMobile;