import {useEffect, useState} from "react";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import {Col, Row, Tabs} from "antd";
import {
	Button,
	Loading,
	StatusLabel,
	Icon
} from "~/components";
import {
	InputPriceField
} from "~/components/Forms";
import {
	ZaloTemplate,
	EmailTemplate
} from "../index";
import {useUtilities} from "~/hooks";

function ExtendFormEdit({item, handleEmailStatus, handleEmailSave, handleZaloStatus, handleZaloSend, handleEmailSend, onHandleSubmit}) {
	const {utilities} = useUtilities();

	const [loading, setLoading] = useState({
		emailStatus: false,
		zaloStatus: false,
		emailSend: false,
		zaloSend: false,
	});

	const initialValues = {
		services: [],
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
	});

	const {fields} = useFieldArray({
		control,
		name: "services"
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.services = JSON.parse(JSON.stringify(item.services));
		}
		reset(initialValues);
	}, [item]);

	const listTabs = [
		{
			key: 'emailTemplate',
			label: `Email`,
			children: <EmailTemplate item={item} handleEmailSave={handleEmailSave} />,
		},
		{
			key: 'zaloTemplate',
			label: `Zalo`,
			children: <ZaloTemplate item={item} />,
		},
	];

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	const onClickEmailStatus = async (status) => {
		setLoading({...loading, emailStatus : true});
		await handleEmailStatus(item, status);
		setLoading({...loading, emailStatus : false});
	}
	const onClickZaloStatus = async (status) => {
		setLoading({...loading, zaloStatus : true});
		await handleZaloStatus(item, status);
		setLoading({...loading, zaloStatus : false});
	}
	const onClickZaloSend = async () => {
		setLoading({...loading, zaloSend : true});
		await handleZaloSend(item);
		setLoading({...loading, zaloSend : false});
	}
	const onClickEmailSend = async () => {
		setLoading({...loading, emailSend : true});
		await handleEmailSend(item);
		setLoading({...loading, emailSend : false});
	}

	return (
		<Row className="mb-2" gutter={10}>
			<Col className="gutter-row" span={16}>
				<Tabs defaultActiveKey="1" items={listTabs}/>
			</Col>
			<Col className="gutter-row" span={8}>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					{isSubmitting && <Loading/>}
					<p className="heading">Dịch vụ</p>
					<Row gutter={10}>
						<Col className="gutter-row" span={14}>
							<label htmlFor="">Dịch vụ</label>
						</Col>
						<Col className="gutter-row" span={10}>
							<label htmlFor="">Phí gia hạn</label>
						</Col>
					</Row>
					{fields.map(({ id, service, domain, price, option }, index) => {
						return (
							<Row gutter={10} key={id}>
								<Col className="gutter-row" span={14}>
									<div className="service">
										<StatusLabel small type={utilities.services[service].color}>{utilities.services[service].label} {domain}</StatusLabel>
										&nbsp;{option.length != 0 && <StatusLabel small type={utilities.services[service].color}>{utilities.services[service].options[option].label}</StatusLabel>}
									</div>
								</Col>
								<Col className="gutter-row" span={10}>
									<Controller control={control} name={`services[${index}].price`} render={({ field }) => (
										<InputPriceField defaultValue={price} errors={errors} {...field}/>
									)}/>
								</Col>
							</Row>
						);
					})}
					<div className="form-group d-flex justify-content-end">
						<Button primary type="submit" leftIcon={Icon.save}>Lưu thông tin</Button>
					</div>
					<hr />
					<p className="heading">Email</p>
					<div className="form-group d-flex justify-content-start">
						{item.emailStatus == 'pending' && <Button loading={loading.emailStatus} background green type="button" onClick={() => onClickEmailStatus('pending')}>Bật tự động gửi</Button>}
						{item.emailStatus != 'pending' && <Button loading={loading.emailStatus} background white type="button" onClick={() => onClickEmailStatus('pending')}>Hủy gửi tự động</Button>}
						<Button loading={loading.emailSend} background blue type="button" leftIcon={Icon.send} onClick={onClickEmailSend}>Gửi</Button>
						<Button loading={loading.emailSend} background blue type="button" onClick={onClickEmailSend}>Gửi thử</Button>
					</div>
					<p className="mb-2" style={{fontStyle:'italic', fontSize:'12px'}}>Gửi test sẽ gửi email vào mail kythuat@sikido.vn</p>

					<p className="heading">Zalo</p>
					<div className="form-group d-flex justify-content-start">
						{item.zaloStatus == 'pending' && <Button loading={loading.zaloStatus} background green type="button" onClick={() => onClickZaloStatus('pending')}>Bật tự động gửi</Button>}
						{item.zaloStatus != 'pending' && <Button loading={loading.zaloStatus} background white type="button" onClick={() => onClickZaloStatus('pending')}>Hủy gửi tự động</Button>}
						<Button loading={loading.zaloSend} background blue type="button" leftIcon={Icon.send} onClick={onClickZaloSend}>Gửi</Button>
						<Button loading={loading.emailSend} background blue type="button" onClick={onClickZaloSend}>Gửi thử</Button>
					</div>
					<p className="mb-2" style={{fontStyle:'italic', fontSize:'12px'}}>Gửi test sẽ gửi tin zalo vào zalo 0397093243</p>
				</form>
			</Col>
		</Row>
	)
}

export default ExtendFormEdit;