import {notification} from "antd";

export const arrayToMap = (list) => {

	if(typeof list == "undefined") return [];

	const mapResult = [...list];

	return mapResult.reduce((map, item) => {
		if(typeof item.key !== 'undefined') map[item.key] = item;
		else if(typeof item.id !== 'undefined') map[item.id] = item;
		return map;
	}, {});
}

export function renderDate(time, format = 'fullTime') {
	time = time ? new Date(time*1000) : null;
	if(format == 'month') return time instanceof Date ? ("0" + (time.getMonth() + 1)).slice(-2)+'/'+time.getFullYear() : '';
	return time instanceof Date ? ("0" + time.getDate()).slice(-2)+'/'+("0" + (time.getMonth() + 1)).slice(-2)+'/'+time.getFullYear() : '';
}

export function strToTime(time) {
	if(typeof time === 'undefined') return time;
	if (!isNaN(time)) {
		time = Math.round(new Date(+time).getTime() / 1000)
	} else if (time.search('GMT') != -1) {
		time = Math.round(new Date(time).getTime() / 1000)
	}
	return time;
}

export function apiError(messageDefault, error, response = null) {

	let message = null;

	if(error) {
		message = messageDefault;

		if(error.code === 'ERR_NETWORK') {
			message = 'Kết nối server thất bại vui lòng thử lại';
		}
		if(error.code === 'ERR_BAD_REQUEST') {
			message = 'Lỗi server vui lòng liên hệ kỹ thuật';
		}
		if(error.code === 'ERR_TIMED_OUT') {
			message = 'Server phản hồi quá lâu vui lòng thử lại';
		}
		if(error?.response) {
			if(error.response?.message) message = error.response.message
			if(error.response?.data?.message) message = error.response.data.message
		}
	}
	else if(response == 'undefined' || response == null) {
		message = messageDefault;
	}
	else if(response?.status && response.status !== 200) {
		message = (response?.message) ? response.message : messageDefault;
	}
	else if(!response?.data) {
		message = (response?.message) ? response.message : messageDefault;
	}

	if(message) notification.error({message: 'Lỗi', description: message});

	return message;
}

export function numberFormat (number, decimals, dec_point, thousands_sep) {
	// Strip all characters but numerical ones.
	number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
	let n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function (n, prec) {
				var k = Math.pow(10, prec);
				return '' + Math.round(n * k) / k;
			};
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');
	}
	return s.join(dec);
}

export function numberToWords(number) {
	const units = ['', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];
	const teens = ['mười', 'mười một', 'mười hai', 'mười ba', 'mười bốn', 'mười năm', 'mười sáu', 'mười bảy', 'mười tám', 'mười chín'];
	const tens = ['', '', 'hai mươi', 'ba mươi', 'bốn mươi', 'năm mươi', 'sáu mươi', 'bảy mươi', 'tám mươi', 'chín mươi'];
	const thousands = ['', 'nghìn', 'triệu', 'tỷ', 'nghìn tỷ', 'triệu tỷ', 'tỷ tỷ'];

	if (number === 0) {
		return 'không';
	}

	let words = '';
	let power = 0;

	while (number > 0) {
		const chunk = number % 1000;

		if (chunk > 0) {
			let chunkWords = '';
			if (chunk < 10) {
				chunkWords = units[chunk];
			} else if (chunk < 20) {
				chunkWords = teens[chunk - 10];
			} else if (chunk < 100) {
				chunkWords = tens[Math.floor(chunk / 10)];
				if (chunk % 10 !== 0) {
					chunkWords += ' ' + units[chunk % 10];
				}
			} else {
				chunkWords = units[Math.floor(chunk / 100)] + ' trăm';
				if (chunk % 100 !== 0) {
					chunkWords += ' ' + tens[Math.floor((chunk % 100) / 10)];
					if (chunk % 10 !== 0) {
						chunkWords += ' ' + units[chunk % 10];
					}
				}
			}

			words = chunkWords + ' ' + thousands[power] + ' ' + words;
		}

		number = Math.floor(number / 1000);
		power++;
	}

	return words.trim();
}

export const handleRequest = promise => {
	return promise.then(data => ([undefined, data, null])).catch(error => ([error, undefined, null]));
}