import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Loading, Icon, Button, Modal
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	ExpenseGroupTable,
	ExpenseGroupFormAddEdit, ExpenseEventFormAddEdit
} from "../components";
import {
	expenseApi
} from "~/api";
import {
	expenseGroupActions,
	expenseGroupItemsSelector,
	expenseGroupLoadingSelector,
	expenseGroupErrorSelector,
	expenseGroupPaginationSelector,
	expenseGroupFilterSelector
} from "../expenseGroupSlice";

function ExpenseGroup() {

	const dispatch  = useDispatch();

	const items     = useSelector(expenseGroupItemsSelector);

	const loading   = useSelector(expenseGroupLoadingSelector);

	const error     = useSelector(expenseGroupErrorSelector);

	const pagination = useSelector(expenseGroupPaginationSelector);

	const filter    = useSelector(expenseGroupFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(expenseGroupActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data, isEdit) => {
		let messageAction = 'Thêm mới';
		if(isEdit) {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin thu chi để cập nhật'});
				return;
			}
			data.id = itemEdit.id;
			messageAction = 'Cập nhật';
		}
		let [error, response] = (isEdit) ? await handleRequest(expenseApi.groupUpdate(data)) : await handleRequest(expenseApi.groupAdd(data));
		let message = apiError(`${messageAction} thu chi thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} thu chi thành công`});
			if(!isEdit) {
				dispatch(expenseGroupActions.add(response.data));
			}
			else {
				setItemEdit(response.data);
				dispatch(expenseGroupActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin thu chi để xóa'});
			return;
		}
		let [error, response] = await handleRequest(expenseApi.groupDelete(itemEdit.id));
		let message = apiError(`xóa loại thu chi thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa loại thu chi thành công`});
			dispatch(expenseGroupActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(expenseGroupActions.setFilter({...filter, page }));
	}

	const ModalAddEdit = () => {
		return (
			<Modal title="Thu - Chi" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
				<ExpenseGroupFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
			</Modal>
		)
	}
	const ModalDelete = () => {
		if(!itemEdit?.id) return null;
		return (
			<Modal title="Xóa thu chi" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
				<p>Bạn muốn xóa <b>{itemEdit?.name}</b>?</p>
				<br />
				<div className="d-flex justify-content_end gap">
					<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
					<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
				</div>
			</Modal>
		)
	}

	return (
		<Fragment>
			<ActionBar title={'Doanh thu'}>
				<Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>
				<Button outline blue to="/expense">Thu chi</Button>
				<Button background blue to="/expense/group">Loại</Button>
				<Button outline blue to="/expense/event">Event</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <ExpenseGroupTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
					/>}
				</div>
				<ModalAddEdit />
				<ModalDelete />
			</div>
		</Fragment>
	)
}
export default ExpenseGroup;