import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	InputField,
	SelectField,
} from "~/components/Forms";

function ExpenseGroupFormAddEdit({item, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	const typeList = [
		{
			value : 'none',
			label : 'Tính vào chi phí',
		},
		{
			value : 'minus-wage',
			label : 'Trừ vào lương',
		},
		{
			value : 'plus-wage',
			label : 'Cộng vào lương',
		},
	]

	const initialValues = {
		name: '',
		wage: '',
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Bạn chưa điền tên nhóm'),
		wage: Yup.string().required('Bạn chưa chọn kiểu tính'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.name = item.name;
			initialValues.wage = item.wage;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		onHandleSubmit(data, isEdit);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="name" render={({field}) => (
				<InputField label="Tên nhóm" errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="wage" render={({ field }) => (
				<SelectField label="kiểu tính" options={typeList} errors={errors} {...field} />
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} loại chi phí</Button>
			</div>
		</form>
	)
}

export default ExpenseGroupFormAddEdit;