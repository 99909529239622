import style from '../style/Report.module.scss';
import {Link} from "react-router-dom";
import className from 'classnames/bind';
import ActionBar from "~/layout/ActionBar";
import Icon from "~/components/Icon";
import {useCan} from "~/hooks";
const cn = className.bind(style);

function Report() {
	const can = {
		'saleAnalytic': useCan('reportSaleAnalytic'),
		'salePoint': useCan('reportSalePoint'),
		'sale': useCan('reportSale'),
		'revenue': useCan('reportRevenue'),
		'extend': useCan('reportExtend'),
		'pay': useCan('reportPay'),
		'financial': useCan('reportFinancial'),
	}
	/*Sale: Hiệu quả công việc*/
	const ItemSaleAnalytic = () => {
	    if(!can.saleAnalytic) return null;
		return <div className={cn('item')}>
			<div className={cn('icon')}>{Icon.chart}</div>
			<div className={cn('info')}>
				<Link to={'sales/analytic'}>
					<h3 className={'text-lg'}>Hiệu Quả</h3>
					<p>Thống kê phân tích hiệu quả công việc</p>
				</Link>
			</div>
		</div>
	}
	/*Sale: Điểm tư vấn*/
	const ItemSalePoint = () => {
		if(!can.salePoint) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.point}</div>
			<div className={cn('info')}>
				<Link to={'sales/point'}>
					<h3 className={'text-lg'}>Điểm tư vấn</h3>
					<p>Thống kê điểm làm việc hằng ngày</p>
				</Link>
			</div>
		</div>)
	}
	/*Sale: Lịch hẹn*/
	const ItemSaleEvent = () => {
		if(!can.salePoint) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.point}</div>
			<div className={cn('info')}>
				<Link to={'sales/event'}>
					<h3 className={'text-lg'}>Lịch hẹn & tư vấn</h3>
					<p>Thống kê báo cáo cuộc hẹn, tư vấn</p>
				</Link>
			</div>
		</div>)
	}
	/*Sale: Báo cáo công việc*/
	const ItemSale = () => {
		if(!can.sale) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.chart}</div>
			<div className={cn('info')}>
				<Link to={'sales/report'}>
					<h3 className={'text-lg'}>Báo cáo hằng ngày</h3>
					<p>Gửi báo cáo công việc hằng ngày</p>
				</Link>
			</div>
		</div>)
	}
	/*Revenue: Doanh thu*/
	const ItemRevenue = () => {
		if(!can.revenue) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.dola}</div>
			<div className={cn('info')}>
				<Link to={'revenue'}>
					<h3 className={'text-lg'}>Doanh thu</h3>
					<p>Báo cáo doanh thu công ty</p>
				</Link>
			</div>
		</div>)
	}
	/*Extend: Gia hạn*/
	const ItemExtend = () => {
		if(!can.extend) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.dola}</div>
			<div className={cn('info')}>
				<Link to={'extend'}>
					<h3 className={'text-lg'}>Gia hạn</h3>
					<p>Báo cáo doanh thu gia hạn</p>
				</Link>
			</div>
		</div>)
	}
	/*Revenue: Lương*/
	const ItemPay = () => {
		if(!can.pay) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.wallet}</div>
			<div className={cn('info')}>
				<Link to={'pay'}>
					<h3 className={'text-lg'}>Lương</h3>
					<p>Báo cáo lương từng phòng ban</p>
				</Link>
			</div>
		</div>)
	}
	/*Revenue: Thu chi*/
	const ItemFinancial = () => {
		if(!can.financial) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.wallet}</div>
			<div className={cn('info')}>
				<Link to={'financial'}>
					<h3 className={'text-lg'}>Tài chính</h3>
					<p>Báo cáo tài chính tình hình thu chi, lương</p>
				</Link>
			</div>
		</div>)
	}
	return (
		<>
			<ActionBar title={'Báo cáo'}></ActionBar>
			<div className="container">
				<div className="content">
					<div className={cn('items', 'gap-1')}>
						<ItemSale />
						<ItemSaleEvent />
						<ItemSalePoint />
						<ItemRevenue />
						<ItemExtend />
						<ItemPay />
						<ItemFinancial />
					</div>
				</div>
			</div>
		</>
	)
}

export default Report;