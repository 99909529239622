import {forwardRef} from "react";
import {userApi} from "~/api";
import {DebounceSelect} from "../index";
import SearchUserOption from "./SearchUserOption";

const SelectUserAll = forwardRef(({ options, errors, ...props }, ref) => {
	const onSearch = async (keyword) => {
		return handleSearch(keyword, props?.department, props?.status)
	}
	const handleSearch = async (keyword, department, status) => {
		const response = await userApi.search({keyword, department, status});
		return response.data.map((user) => ({
			label: <SearchUserOption item={user}/>,
			value: user.id,
		}))
	}

	return (
		<DebounceSelect fetchOptions={onSearch} errors={errors} optionsDefault={options} {...props} ref={ref}/>
	)
});

export default SelectUserAll;