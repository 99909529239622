import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification, Skeleton} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Modal,
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	payApi
} from "~/api";
import {
	payActions,
	payErrorSelector,
	payFilterSelector,
	payItemsSelector,
	payLoadingSelector,
	payPaginationSelector
} from "../paySlice";
import {
	PayTable,
	PaySearchBar,
	PayDetailRevenue,
	PayDetailManager,
	PayDetailExpense
} from "../components";
import {
	groupItemsSelector
} from "../../Personnel/groupSlice";
import _ from "lodash";
import {Button, Icon} from "../../../components";

function Pay() {

	const dispatch  = useDispatch();

	const items     = useSelector(payItemsSelector);

	const loading   = useSelector(payLoadingSelector);

	const error     = useSelector(payErrorSelector);

	const pagination = useSelector(payPaginationSelector);

	const filter    = useSelector(payFilterSelector);

	//Group items
	const listGroup = useSelector(groupItemsSelector);

	const [itemData, setItemData] = useState({});

	const [itemType, setItemType] = useState('');

	const [loadingDetail, setLoadingDetail] = useState(false);

	//Model
	const [openModal, setOpenModal] = useState({
		detail : false,
		manager : false,
		expense : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(payActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		dispatch(payActions.fetchData(filter));
	}

	const handleLoadDetail = async (item, service) => {
		setLoadingDetail(true);
		if (item?.id == 'undefined') {
			setLoadingDetail(false);
			notification.error({message: 'Lỗi', description: 'Không có thông tin để load'});
			return;
		}
		let [error, response] = await handleRequest(payApi.revenues({id : item.id, service: service}));
		let message = apiError(`Load dữ liệu thất bại`, error, response);
		if(!message) {
			setItemData(response.data);
			setItemType('revenue');
		}
		setLoadingDetail(false);
	}

	const handleLoadManager = async (item) => {
		setLoadingDetail(true);
		if (item?.id == 'undefined') {
			setLoadingDetail(false);
			notification.error({message: 'Lỗi', description: 'Không có thông tin để load'});
			return;
		}
		let [error, response] = await handleRequest(payApi.manager({id : item.id}));
		let message = apiError(`Load dữ liệu thất bại`, error, response);
		if(!message) {
			setItemData(response.data);
			setItemType('manager');
		}
		setLoadingDetail(false);
	}

	const handleLoadExpense = async (item, type) => {
		setLoadingDetail(true);
		if (item?.id == 'undefined') {
			setLoadingDetail(false);
			notification.error({message: 'Lỗi', description: 'Không có thông tin để load'});
			return;
		}
		let [error, response] = await handleRequest(payApi.expense({id : item.id, type: type}));
		let message = apiError(`Load dữ liệu thất bại`, error, response);
		if(!message) {
			setItemData(response.data);
			setItemType('expense');
		}
		setLoadingDetail(false);
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(payActions.setFilter({...filter, page }));
	}

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(payActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Lương'}>
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<PaySearchBar filter={filter} listGroup={listGroup} onChange={handleFilterChange} />
					{items && <PayTable
						items={items}
						listGroup={listGroup}
						pagination={pagination}
						onDetail={handleLoadDetail}
						onManager={handleLoadManager}
						onExpense={handleLoadExpense}
						onPaginationChange={handlePaginationChange}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
					/>}
				</div>
				{
					<Modal size="xl" title="Chi tiết bảng lương" visible={openModal.detail} onCancel={() => {handleModalClose('detail')}}>
						{loadingDetail && <Skeleton />}
						{(!loadingDetail && itemType == 'revenue' && !_.isEmpty(itemData)) && <PayDetailRevenue items={itemData} />}
					</Modal>
				}
				{
					<Modal size="xl" title="Chi tiết lương quản lý" visible={openModal.manager} onCancel={() => {handleModalClose('manager')}}>
						{loadingDetail && <Skeleton />}
						{(!loadingDetail && itemType == 'manager' && !_.isEmpty(itemData)) && <PayDetailManager items={itemData} />}
					</Modal>
				}
				{
					<Modal size="xl" title="Chi tiết cộng trừ lương" visible={openModal.expense} onCancel={() => {handleModalClose('expense')}}>
						{loadingDetail && <Skeleton />}
						{(!loadingDetail && itemType == 'expense' && !_.isEmpty(itemData)) && <PayDetailExpense items={itemData} />}
					</Modal>
				}
			</div>
		</Fragment>
	)
}
export default Pay;