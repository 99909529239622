import {useEffect, useState} from "react";
import _ from "lodash"
import {Col, Row, Tabs} from "antd";
import {
	Button,
	Image,
	StatusLabel,
	Icon,
	Comments
} from "~/components";
import {
	FileUpload,
	FileList
} from "~/components/Forms";
import {
	LIST_PROJECT_PHASE,
} from "~/app/constants";
import {
	renderDate,
} from "~/utils";

function TasksFormInfo({item, project, modalOpen, onUpload, onRemoveFile, onClickStatus}) {

	let [files, setFiles] = useState([]);

	useEffect(() => {
		if(item?.id) {
			files = [];
			if(!_.isEmpty(item.attachFile)) {
				for (const [path, name] of Object.entries(item.attachFile)) {
					files.push({path, name})
				}
			}
			setFiles(files);
		}
	}, [item]);

	const handleStatusClick = (status) => {
		if(!onClickStatus) return;
		onClickStatus(status, item, project);
	}
	const handleEditClick = () => {
		modalOpen('addEdit');
	}
	const handleUploadClick = (data) => {
		onUpload(data, item, project)
	}
	const handleRemoveFileClick = (filename) => {
		setFiles(files.filter(file => file.name !== filename))
		onRemoveFile(filename, item, project);
	}

	const ProjectInfo = () => {
		return (
			<>
				<p className="heading">Thông tin</p>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Hợp đồng</Col>
					<Col className="gutter-row" span={18}>
						{`${project.contract.code} ${project.contract.name}`}
					</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Giai đoạn</Col>
					<Col className="gutter-row" span={18}>
						<StatusLabel type={LIST_PROJECT_PHASE[project.phase].color}>{LIST_PROJECT_PHASE[project.phase].label}</StatusLabel>
					</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Deadline</Col>
					<Col className="gutter-row" span={18}>
						{`${renderDate(project.deadline)}`}
					</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Ghi chú</Col>
					<Col className="gutter-row" span={18} style={{wordBreak : 'break-word'}}>
						{`${project.note}`}
					</Col>
				</Row>
				<p className="heading mt-2">Nhân viên</p>
				<Row className="mb-1"  gutter={10}>
					<Col className="gutter-row" span={6}>Kinh doanh</Col>
					<Col className="gutter-row" span={18}>
						{
							project.user?.id && <div className="d-flex gap align-items">
								<div className="avatar"><Image src={project.user?.avatar} /></div>
								<div>
									<b>{project.user?.firstname + ' ' + project.user?.lastname}</b>
								</div>
							</div>
						}
						{
							project.userShare?.id && <div className="d-flex gap align-items">
								<div className="avatar"><Image src={project.userShare?.avatar} /></div>
								<div>
									<b>{project.userShare?.firstname + ' ' + project.userShare?.lastname}</b>
								</div>
							</div>
						}
					</Col>
				</Row>
				<Row className="mb-1"  gutter={10}>
					<Col className="gutter-row" span={6}>Thiết kế</Col>
					<Col className="gutter-row" span={18}>
						{
							project.design?.id && <div className="d-flex gap align-items">
								<div className="avatar"><Image src={project.design?.avatar} /></div>
								<div>
									<b>{project.design?.firstname + ' ' + project.design?.lastname}</b>
								</div>
							</div>
						}
					</Col>
				</Row>
				<Row className="mb-1"  gutter={10}>
					<Col className="gutter-row" span={6}>Lập trình</Col>
					<Col className="gutter-row" span={18}>
						{
							project.assign?.id && <div className="d-flex gap align-items">
								<div className="avatar"><Image src={project.assign?.avatar} /></div>
								<div>
									<b>{project.assign?.firstname + ' ' + project.assign?.lastname}</b>
								</div>
							</div>
						}
					</Col>
				</Row>
			</>
		)
	}

	const TaskInfo = () => {
		return (
			<>
				<p className="heading">{project.name}</p>
				<p className="mb-1">{item.name}</p>
				<FileList files={files} removeFile={handleRemoveFileClick} />
				<FileUpload name="file" files={files} setFiles={setFiles} onChange={handleUploadClick} />
			</>
		)
	}

	const listTabs = [
		{
			key: 'taskInfo',
			label: `Task`,
			children: <TaskInfo />,
		},
		{
			key: 'projectInfo',
			label: `Dự án`,
			children: <ProjectInfo />,
		},
	];

	return (
		<div className="form">
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" xs={24} md={12}>
					<Tabs defaultActiveKey="1" items={listTabs}/>
				</Col>
				<Col className="gutter-row" xs={24} md={12}>
					<Comments type="task" objectId={item.id} />
				</Col>
			</Row>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button background blue leftIcon={Icon.edit} onClick={handleEditClick}>Chinh sữa</Button>
				{
					(item.status == 'pending') && (<Button onClick={() => handleStatusClick('resolved')} background green leftIcon={Icon.success}>Đã làm</Button>)
				}
			</div>
		</div>
	)
}

export default TasksFormInfo;