import {useCallback, useMemo} from "react";
import {
	InputField,
	SelectUserAll,
	SelectCustomerAll,
	SelectField
} from "~/components/Forms";
import {StatusLabel} from "~/components";
import {LIST_CONTRACT_STATUS} from "~/app/constants";
import {useCan, useUtilities} from "~/hooks";

function ContractSearchBar({filter, listGroup, onSearchChange, onChange}) {

	const {utilities} = useUtilities();

	const can = {
		listAll: useCan('contractListAll'),
		listGroup: useCan('contractListGroup'),
		add: useCan('contractAdd'),
		edit: useCan('contractEdit'),
		transfer: useCan('contractTransfer'),
		delete: useCan('contractDelete'),
		status: useCan('contractStatus'),
	};

	const serviceOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(utilities.services).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, []);

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_CONTRACT_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, []);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất các nhóm' }, ...Object.entries(listGroup).map(([key, group]) => {
			return {
				value: group.id,
				label: group.name
			};
		})];
	}, [listGroup]);

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleUserChange = useCallback((value) => {
		if(onChange) onChange('userId', value);
	}, [onChange]);

	const handleCustomerChange = useCallback((value) => {
		if(onChange) onChange('customerId', value);
	}, [onChange]);

	const handleServiceChange = useCallback((value) => {
		if(onChange) onChange('service', value);
	}, [onChange]);

	const handleStatusChange = useCallback((value) => {
		if(onChange) onChange('status', value);
	}, [onChange]);

	const handleGroupChange = useCallback((value) => {
		if(onChange) onChange('groupId', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField placeholder="Tên miền, tên, số hợp đồng" onChange={handleKeywordChange} />
					<SelectCustomerAll value={filter.customerId} placeholder="Khách hàng" options={[{value:'', label:'Tất cả'}]} onChange={handleCustomerChange} />
					{(can.listAll || can.listGroup) && <SelectField placeholder="Chọn nhóm" options={groupOptions} onChange={(value, event) => handleGroupChange(value)} />}
					{(can.listAll || can.listGroup) && <SelectUserAll value={filter.userId} placeholder="Nhân viên" options={[{value:'', label:'Tất cả'}]} onChange={handleUserChange} />}
					<SelectField value={filter.service} placeholder="Dịch vụ" options={serviceOptions} onChange={handleServiceChange}/>
					<SelectField value={filter.status} placeholder="Trạng thái" options={statusOptions} onChange={handleStatusChange}/>
				</form>
			</div>
		</div>
	)
}

export default ContractSearchBar;