import {useCallback} from "react";
import {
	DateField,
	SelectField,
	InputField,
	SelectCustomerAll
} from "~/components/Forms";
import {strToTime} from "~/utils";

function ServiceSearchBar({status, filter, onSearchChange, onChange}) {

	const serviceOptions = [
		{ value: 'host', label : 'Host' },
		{ value: 'ssl', label : 'SSL' },
		{ value: 'domain', label : 'Tên miền' },
		{ value: 'email', label : 'Email Server' },
	];

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleTimeChange = useCallback((value) => {
		value = strToTime(value);
		if(onChange) onChange('time', value);
	}, [onChange]);

	const handleServiceChange = useCallback((value) => {
		if(onChange) onChange('service', value);
	}, [onChange]);

	const handleCustomerChange = useCallback((value) => {
		if(onChange) onChange('customerId', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField placeholder="Tên miền" onChange={handleKeywordChange}/>
					<SelectCustomerAll value={filter.customerId} placeholder="Khách hàng" options={[{value:'', label:'Tất cả'}]} onChange={handleCustomerChange} />
					{status != 'will-expired' && <DateField name="time" picker="month" onChange={handleTimeChange}/>}
					<SelectField placeholder="Dịch vụ" options={serviceOptions} onChange={handleServiceChange} />
				</form>
			</div>
		</div>
	)
}

export default ServiceSearchBar;