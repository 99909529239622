import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Icon,
	Modal,
	Loading,
	Button,
} from "~/components";
import {
	projectErrorSelector,
	projectFilterSelector,
	projectItemsSelector,
	projectLoadingSelector,
	projectPaginationSelector,
	projectActions
} from "../projectSlice";
import {
	ProjectTable,
	ProjectSearchBar,
	ProjectSearchMobile,
	ProjectFormAddEdit,
	ProjectFormInfo,
	ProjectFormPhase,
	ProjectFormStatus,
	ProjectFormAssign
} from "../components";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	useCan,
	useDevice,
	useSocket
} from "~/hooks";
import {
	projectApi,
	userApi
} from "~/api";

function Project() {

	const {isMobile} = useDevice();

	const {socket} = useSocket();

	const { id } = useParams();

	const can = {
		add: useCan('projectAdd'),
		edit: useCan('projectEdit'),
		status: useCan('projectStatus'),
		assignDesign: useCan('projectAssignDesign'),
		assignDev: useCan('projectAssignDev'),
		phase: useCan('projectPhase'),
		delete: useCan('projectDelete'),
	};

	const dispatch  = useDispatch();

	const items     = useSelector(projectItemsSelector);

	const loading   = useSelector(projectLoadingSelector);

	const error     = useSelector(projectErrorSelector);

	const pagination = useSelector(projectPaginationSelector);

	const filter    = useSelector(projectFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
		info        : false,
		assign      : false,
		phase       : false,
		status      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	let [listAssign, setListAssign] = useState([]);

	let [listDesign, setListDesign] = useState([]);

	useEffect(() => {
		const loadAssignAndDesign = async () => {
			const [assignRes, designRes] = await Promise.all([
				handleRequest(userApi.search({'department': 'technical', 'status' : 'active'})),
				handleRequest(userApi.search({'department' : 'design', 'status' : 'active'}))
			]);

			let [assignErr, assignData] = assignRes;
			let [designErr, designData] = designRes;

			let assignMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, assignErr, assignData);
			let designMsg = apiError(`Load danh sách nhân viên design thất bại`, designErr, designData);

			if (!assignMsg) setListAssign(assignData.data);
			if (!designMsg) setListDesign(designData.data);
		};

		loadAssignAndDesign().then();
	}, []);

	//Load data
	useEffect(() => {
		let newFilter = {...filter}
		if(!isNaN(id)) {
			newFilter = {
				...filter,
				contractId: id,
				page: 1
			};
		}
		dispatch(projectActions.fetchData(newFilter));
	}, [filter, id]);

	//Load task detail
	useEffect(() => {
		if(!isNaN(id)) {
			setItemEdit(items.find(item => item.contractId === parseInt(id)));
			handleModalOpen('info');
		}
	}, [id, items])

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		let newFilter = {...filter}
		if(!isNaN(id)) {
			newFilter = {
				...filter,
				contractId: id,
				page: 1
			};
		}
		dispatch(projectActions.fetchData(newFilter));
	}

	//Submit
	const handleSaveItem = async (data, isEdit) => {
		let messageAction = 'Thêm mới';
		if(isEdit) {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
				return;
			}
			data.id = itemEdit.id;
			messageAction = 'Cập nhật';
		}
		data.deadline = strToTime(data.deadline);
		let [error, response] = (isEdit) ? await handleRequest(projectApi.update(data)) : await handleRequest(projectApi.add(data));
		let message = apiError(`${messageAction} dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} dự án thành công`});
			if(!isEdit) {
				dispatch(projectActions.add(response.data));
			}
			else {
				setItemEdit(response.data);
				dispatch(projectActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để xóa'});
			return;
		}
		if (itemEdit.status != 'pending') {
			notification.error({message: 'Lỗi', description: 'Dự án đã duyệt không thể xóa'});
			return;
		}
		let [error, response] = await handleRequest(projectApi.delete(itemEdit.id));
		let message = apiError(`xóa dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa dự án thành công`});
			dispatch(projectActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//status
	const handleStatus = async (status) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(projectApi.status({ id: itemEdit.id, status}));
		let message = apiError(`Cập nhật trạng thái dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái dự án thành công`});
			itemEdit.status = status;
			setItemEdit(itemEdit);
			dispatch(projectActions.update(itemEdit));
			handleModalClose('status')
		}
	}

	//phase
	const handlePhase = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(projectApi.phase(data));
		let message = apiError(`Cập nhật giai đoạn dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật giai đoạn dự án thành công`});
			item.phase = response.data.phase;
			item.deadline = response.data.deadline;
			item.status = response.data.status;
			setItemEdit(item);
			dispatch(projectActions.update(item));
			handleModalClose('phase')
		}
	}

	//assign
	const handleAssign = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có dự án để cập nhật'});
			return;
		}

		data.id = item.id;

		let [error, response] = await handleRequest(projectApi.assign(data));

		let message = apiError(`Phân công dự án không thành công`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Phân công dự án thành công`});
			item.assignId   = response.data.assignId;
			item.assign     = response.data.assign;
			item.designId   = response.data.designId;
			item.design     = response.data.design;
			socket.emit("set-notification-user", response.data.notification);
			socket.emit("set-notification-user", response.data.notificationAssets);
			dispatch(projectActions.update(item));
			handleModalClose('assign');
		}
	}

	const handleDeadline = async (date, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có dự án để cập nhật'});
			return;
		}

		date = date.get('date')+'-'+(date.get('month')+1)+'-'+date.get('year');

		let [error, response] = await handleRequest(projectApi.update({id : item.id, deadline: date}));
		let message = apiError(`Cập nhật deadline dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật deadline dự án thành công`});
			item.deadline = response.data.deadline;
			dispatch(projectActions.update(item));
		}
	}

	//upload
	const handleUpload = async (data) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
			return;
		}
		data.append('id', itemEdit.id);
		let [error, response] = await handleRequest(projectApi.fileUploadDev(data));
		let message = apiError(`Cập nhật dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Upload File thành công`});
			itemEdit.attachFile = response.data.attachFile;
			itemEdit.attachFileTotal = response.data.attachFileTotal;
			setItemEdit(itemEdit);
			dispatch(projectActions.update(itemEdit));
		}
	}

	const handleUploadDelete = async (filename) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(projectApi.fileRemoveDev({id: itemEdit.id, filename: filename}));
		let message = apiError(`Cập nhật dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật dự án thành công`});
			itemEdit.attachFile = response.data.attachFile;
			itemEdit.attachFileTotal = response.data.attachFileTotal;
			setItemEdit(itemEdit);
			dispatch(projectActions.update(itemEdit));
		}
	}

	//upload
	const handleUploadDesign = async (data) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
			return;
		}
		data.append('id', itemEdit.id);
		let [error, response] = await handleRequest(projectApi.fileUploadDesign(data));
		let message = apiError(`Cập nhật dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Upload File thành công`});
			itemEdit.attachFileDemo = response.data.attachFileDemo;
			itemEdit.attachFileDemoTotal = response.data.attachFileDemoTotal;
			setItemEdit(itemEdit);
			dispatch(projectActions.update(itemEdit));
		}
	}

	const handleUploadDesignDelete = async (filename) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(projectApi.fileRemoveDesign({id: itemEdit.id, filename: filename}));
		let message = apiError(`Cập nhật dự án thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật dự án thành công`});
			itemEdit.attachFileDemo = response.data.attachFileDemo;
			itemEdit.attachFileDemoTotal = response.data.attachFileDemoTotal;
			setItemEdit(itemEdit);
			dispatch(projectActions.update(itemEdit));
		}
	}

	//Search
	const SearchBar = (isMobile) ? ProjectSearchMobile : ProjectSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(projectActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(projectActions.setFilter(newFilter));
	};

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(projectActions.setFilter(newFilter));
	};

	const AddEditModal = () => {
		if((!can.add && !can.edit) || !openModal.addEdit) return null;
		return <Modal title="Dự án" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
			<ProjectFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
		</Modal>
	}
	const AssignModal = () => {
		if((!can.assignDev && !can.assignDesign) || !itemEdit?.id || !openModal.assign) return null;
		return <Modal zIndex={99} title="Phân công" visible={openModal.assign} onCancel={() => {handleModalClose('assign')}}>
			<ProjectFormAssign item={itemEdit} can={can} listAssign={listAssign} listDesign={listDesign} onHandleSubmit={handleAssign}/>
		</Modal>
	}
	const StatusModal = () => {
		if(!can.status || !itemEdit?.id || !openModal.status) return null;
		return <Modal zIndex={99} title="Trạng thái dự án" visible={openModal.status} onCancel={() => {handleModalClose('status')}}>
			<ProjectFormStatus item={itemEdit} onHandleSubmit={handleStatus}/>
		</Modal>
	}
	const PhaseModal = () => {
		if(!can.phase || !itemEdit?.id || !openModal.phase) return null;
		return <Modal zIndex={99} title="Giai đoạn dự án" visible={openModal.phase} onCancel={() => {handleModalClose('phase')}}>
			<ProjectFormPhase item={itemEdit} onHandleSubmit={handlePhase}/>
		</Modal>
	}
	const DeleteModal = () => {
		if(!can.delete || !itemEdit?.id || !openModal.delete) return null;
		return <Modal title="Xóa dự án" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
			<p>Bạn muốn xóa dự án <b>{itemEdit?.name}</b>?</p>
			<br />
			<div className="d-flex justify-content_end gap">
				<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
				<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
			</div>
		</Modal>
	}

	return (
		<Fragment>
			<ActionBar title={'Dự án'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({}); handleModalOpen('addEdit')}}>Thêm mới</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<SearchBar filter={filter} onChange={handleFilterChange} onSearchChange={handleSearchChange} listAssign={listAssign} listDesign={listDesign} />
					{items && <ProjectTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						isMobile={isMobile}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
						onChangeDeadline={handleDeadline}
					/>}
				</div>
			</div>
			<AddEditModal/>
			<AssignModal/>
			<StatusModal/>
			<PhaseModal/>
			<DeleteModal/>
			{
				(itemEdit?.id && openModal.info) && <Modal zIndex={99} size={'xl'} title="Thông tin dự án" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
					<ProjectFormInfo item={itemEdit}
					                 onClickStatus={handleStatus}
					                 onUpload={handleUpload}
					                 onRemoveFile={handleUploadDelete}
					                 onUploadDesign={handleUploadDesign}
					                 onRemoveFileDesign={handleUploadDesignDelete}
					                 modalOpen={handleModalOpen}/>
				</Modal>
			}
		</Fragment>
	)
}

export default Project;