import {call, put, takeLatest} from "redux-saga/effects";
import {saleConsultationActions} from "./saleConsultationSlice";
import {saleApi} from "~/api";

function* fetchSaleConsultationList(action) {
	try {
		const response = yield call(saleApi.eventGets, action.payload);
		yield put(saleConsultationActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch saleConsultation list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(saleConsultationActions.fetchDataFailed(message));
	}
}

export default function* saleConsultationSaga() {
	yield takeLatest(saleConsultationActions.fetchData.type, fetchSaleConsultationList);
}