import className from 'classnames/bind';
import style from './Menu.module.scss';
import Button from "~/components/Button";
import Dropdown from "~/components/Menu/Dropdown";
import FontAwesomeIcon from "~/components/FontAwesome";

const cn = className.bind(style);

function MenuItems({ items, depthLevel, className, classNameDropdown }) {
	return (
		<li key={items.key} className={cn('menu-item', className)}>
			{items.count != 0 && <span className={cn('count')}>{items.count}</span>}
			<Button leftIcon={items.icon} rightIcon={ depthLevel > 0 && items.submenu ? <FontAwesomeIcon icon="fa-thin fa-chevron-right" /> : '' } to={items.to} className={cn('')}>
				{items.title}
			</Button>
			{ items.submenu ? (
				<>
					<Dropdown submenus={items.submenu} className={cn('dropdown', classNameDropdown)} depthLevel={depthLevel} />
				</>
			) : null}
		</li>
	);
}

export default MenuItems;