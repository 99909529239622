import * as Yup from "yup";
import _ from "lodash";
import {useEffect, useState, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Button, Icon} from "~/components";
import {Alert, Col, Row} from "antd";
import {InputField, SelectField} from "~/components/Forms";
import {useUtilities, useCan} from "~/hooks";
import DOMPurify from "dompurify";
import {Comments} from "../../../../components";

function UploadWebsiteFormStep({item, onHandleHost, onHandleSource, onClickDevStatus}) {

	const [hostData, setHostData] = useState({});

	const [databaseData, setDatabaseData] = useState({});

	const [sourceData, setSourceData] = useState({
		source: '', database: ''
	});

	const [loadingDevStatus, setLoadingDevStatus] = useState(false);

	const can = {
		devStatus: useCan('uploadWebsiteDevStatus'),
	};

	const {utilities} = useUtilities();

	const sourceTypeOption = useMemo(() => {
		return [...Object.entries(utilities.devSource).map(([key, name]) => {
			return {
				value: key,
				label: name
			};
		})];
	}, [utilities.devSource]);

	let userName = item.domain.replace(/[^a-zA-Z0-9]/g, '').slice(0, 9);

	function generateRandomString(length) {
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return result;
	}

	const { control: controlHost, handleSubmit: handleSubmitHost, formState: { isSubmitting: isSubmittingHost, errors: errorsHost }, setValue} = useForm({
		defaultValues: {
			hostUser: userName,
			hostPass: '',
		},
		resolver: yupResolver(Yup.object().shape({
			hostUser: Yup.string().required('Bạn chưa điền tên đăng nhập host'),
			hostPass: Yup.string().required('Bạn chưa điền mật khẩu host'),
		}))
	});

	const { control: controlSource, handleSubmit: handleSubmitSource, formState: { isSubmitting: isSubmittingSource, errors: errorsSource }} = useForm({
		defaultValues: {
			sourcePath: 'sikidodemo.com',
			sourceFolder: '',
			sourceDb: '',
		},
		resolver: yupResolver(Yup.object().shape({
			sourcePath: Yup.string().required('Bạn chưa chọn đường dẫn source code'),
			sourceFolder: Yup.string().required('Bạn chưa điền folder source code'),
		}))
	});

	useEffect(() => {
		setHostData({})
		setDatabaseData({})
		setValue('hostPass', generateRandomString(10));
	}, [item])

	const onSubmitHost = async (data) => {
		let response = await onHandleHost(data, item);
		if(response !== false) {
			setHostData(response.data.host)
			setDatabaseData(response.data.database)
		}
	}

	const onSubmitSource = async (data) => {
		let response = await onHandleSource(data, item);
		if(response !== false) {
			sourceData.source = response.data.urlSource;
			sourceData.database = response.data.urlDatabase;
			setSourceData(sourceData)
		}
	}

	const handleDevStatusClick = async (status) => {
		setLoadingDevStatus(true);
		if(!onClickDevStatus) return;
		await onClickDevStatus(status, item);
		setLoadingDevStatus(false);
	}

	return (
		<Row className="mb-2" gutter={10}>
			<Col className="gutter-row" span={12}>
				<div className="form">
					<p className="heading">Thông tin</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Tên miền</Col>
						<Col className="gutter-row" span={18}>{`${item.domain}`}</Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Url Demo</Col>
						<Col className="gutter-row" span={18}>{`${item.url}`}</Col>
					</Row>
					<p className="heading">Thông tin host</p>
					{
						(item.hostType != 2 && item?.hostCreated == 0) && <form className="form" onSubmit={handleSubmitHost(onSubmitHost)}>
							<Controller control={controlHost} name="hostUser" render={({field}) => (
								<InputField label="Tên đăng nhập host" errors={errorsHost} {...field}/>
							)}/>
							<Controller control={controlHost} name="hostPass" render={({field}) => (
								<InputField label="Mật khẩu đăng nhập host" errors={errorsHost} {...field}/>
							)}/>
							<div className="form-group d-flex justify-content-end">
								<Button loading={isSubmittingHost} background red>Tạo host</Button>
							</div>
						</form>
					}
					{
						(item.hostType != 2 && item?.hostCreated != 0) && <Alert message="Host đã được chia" type="warning"/>
					}
					{
						(item.hostType == 2) && <div className="text-sm" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.hostCustomer) }} />
					}
					<Row className="mb-2" gutter={10}>
						{
							(!_.isEmpty(hostData)) && <Col className="gutter-row" span={12}>
								<div className="pd-1" style={{background:'#c6fbc5', borderRadius:'5px'}}>
									<p><b>Thông tin host</b></p>
									<p>Ip: {hostData?.ip}</p>
									<p>Username: {hostData?.username}</p>
									<p>Password: {hostData?.password}</p>
									<p>Package: {hostData?.package}</p>
								</div>
							</Col>
						}
						{
							(!_.isEmpty(databaseData)) && <Col className="gutter-row" span={12}>
								<div className="pd-1" style={{background:'#ffbdbd', borderRadius:'5px'}}>
									<p><b>Thông tin database</b></p>
									<p>Database: {databaseData?.databaseName}</p>
									<p>Username: {hostData?.username}</p>
									<p>Password: {hostData?.password}</p>
								</div>
							</Col>
						}
					</Row>
					<p className="heading">Nén Source Code</p>
					<form className="form" onSubmit={handleSubmitSource(onSubmitSource)}>
						<Controller control={controlSource} name="sourcePath" render={({ field }) => (
							<SelectField label="Đường dẫn" options={sourceTypeOption} errors={errorsSource} {...field}/>
						)}/>
						<Controller control={controlSource} name="sourceFolder" render={({field}) => (
							<InputField label="Tên thư mục demo" errors={errorsSource} {...field}/>
						)}/>
						<div className="form-group d-flex justify-content-end">
							<Button loading={isSubmittingSource} background red>Nén source</Button>
							{
								(sourceData.source) && <Button background blue href={sourceData.source} download>Tải source đã nén</Button>
							}
							{
								(sourceData.database) && <Button background red href={sourceData.database} download>Tải database đã nén</Button>
							}
						</div>
					</form>

					<div className="form-group d-flex justify-content-end modal-bottom">
						{
							(can.devStatus && item.devStatus == 'up-wait') && (<Button loading={loadingDevStatus} onClick={() => handleDevStatusClick('success')} background green leftIcon={Icon.success}>Đã upload website</Button>)
						}
					</div>
				</div>
			</Col>
			<Col className="gutter-row" span={12}><Comments type="upload-website" objectId={item.id} /></Col>
		</Row>
	)
}

export default UploadWebsiteFormStep;