import {useEffect, useState, Fragment, memo} from "react";
import _ from "lodash";
import DOMPurify from 'dompurify';
import {Col, Row, Tabs, Timeline} from "antd";
import {
	Button, Image, StatusLabel, Icon
} from "~/components";
import {
	FileUpload,
	FileList
} from "~/components/Forms";
import {
	LIST_PROJECT_PHASE,
} from "~/app/constants";
import {
	renderDate,
	apiError,
	handleRequest
} from "~/utils";
import {
	useCan
} from "~/hooks";
import {
	Comments
} from "~/components";
import {
	projectApi
} from "~/api";

function ProjectFormInfo({item, modalOpen, onUpload, onRemoveFile, onUploadDesign, onRemoveFileDesign, onClickStatus}) {

	const can = {
		edit: useCan('projectEdit'),
		status: useCan('projectStatus'),
	};

	let [files, setFiles] = useState([]);
	let [filesDesign, setFilesDesign] = useState([]);
	let [histories, setHistories] = useState([]);
	const [activeKey, setActiveKey] = useState('');

	useEffect(() => {
		setActiveKey('projectInfo')
	}, []);

	useEffect(() => {
		if(item?.id) {
			files = [];
			if(!_.isEmpty(item.attachFile)) {
				for (const [path, name] of Object.entries(item.attachFile)) {
					files.push({path, name})
				}
			}
			setFiles(files);

			filesDesign = [];
			if(!_.isEmpty(item.attachFileDemo)) {
				for (const [path, name] of Object.entries(item.attachFileDemo)) {
					filesDesign.push({path, name})
				}
			}
			setFilesDesign(filesDesign);
		}
	}, [item]);

	const handleStatusClick = (status) => {
		if(!onClickStatus) return;
		onClickStatus(status);
	}

	const handleEditClick = () => {
		modalOpen('addEdit');
	}

	const handleRemoveFileClick = (filename) => {
		setFiles(files.filter(file => file.name !== filename))
		onRemoveFile(filename);
	}

	const handleRemoveFileDesignClick = (filename) => {
		setFilesDesign(filesDesign.filter(file => file.name !== filename))
		onRemoveFileDesign(filename);
	}

	const fetchHistoriesData = async () => {
		let [error, response] = await handleRequest(projectApi.history(item.id));
		let message = apiError(`Load lịch sử thất bại`, error, response);
		if (!message) setHistories(response.data);
	}

	const handleTabsClick = (key) => {
		if(key == 'projectHistory') {
			fetchHistoriesData().then();
		}
		setActiveKey(key);
	}

	const ProjectInfo = () => {
		return (
			<>
				<p className="heading">Thông tin</p>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Hợp đồng</Col>
					<Col className="gutter-row" span={18}>
						{`${item.contract.code} ${item.contract.name}`}
					</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Giai đoạn</Col>
					<Col className="gutter-row" span={18}>
						<StatusLabel type={LIST_PROJECT_PHASE[item.phase].color}>{LIST_PROJECT_PHASE[item.phase].label}</StatusLabel>
					</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Deadline</Col>
					<Col className="gutter-row" span={18}>
						{`${renderDate(item.deadline)}`}
					</Col>
				</Row>
				<p className="heading mt-2">Nhân viên</p>
				<Row className="mb-1"  gutter={10}>
					<Col className="gutter-row" span={6}>Kinh doanh</Col>
					<Col className="gutter-row" span={18}>
						{
							item.user?.id && <div className="d-flex gap align-items">
								<div className="avatar"><Image src={item.user?.avatar} /></div>
								<div>
									<b>{item.user?.firstname + ' ' + item.user?.lastname}</b>
								</div>
							</div>
						}
						{
							item.userShare?.id && <div className="d-flex gap align-items">
								<div className="avatar"><Image src={item.userShare?.avatar} /></div>
								<div>
									<b>{item.userShare?.firstname + ' ' + item.userShare?.lastname}</b>
								</div>
							</div>
						}
					</Col>
				</Row>
				<Row className="mb-1"  gutter={10}>
					<Col className="gutter-row" span={6}>Thiết kế</Col>
					<Col className="gutter-row" span={18}>
						{
							item.design?.id && <div className="d-flex gap align-items">
								<div className="avatar"><Image src={item.design?.avatar} /></div>
								<div>
									<b>{item.design?.firstname + ' ' + item.design?.lastname}</b>
								</div>
							</div>
						}
					</Col>
				</Row>
				<Row className="mb-1"  gutter={10}>
					<Col className="gutter-row" span={6}>Lập trình</Col>
					<Col className="gutter-row" span={18}>
						{
							item.assign?.id && <div className="d-flex gap align-items">
								<div className="avatar"><Image src={item.assign?.avatar} /></div>
								<div>
									<b>{item.assign?.firstname + ' ' + item.assign?.lastname}</b>
								</div>
							</div>
						}
					</Col>
				</Row>
			</>
		)
	}

	const ProjectDesign = () => {
		return (
			<>
				<p className="heading">Đặc tả</p>
				<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.noteDesign) }} />

				<p className="heading">Đính kèm</p>
				<FileUpload name="file" files={filesDesign} setFiles={setFilesDesign} onChange={onUploadDesign} />
				<FileList files={filesDesign} removeFile={handleRemoveFileDesignClick} />
			</>
		)
	}

	const ProjectDev = () => {
		return (
			<>
				<p className="heading">Đặc tả</p>
				<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.note) }} />

				<p className="heading">Đính kèm</p>
				<FileUpload name="file" files={files} setFiles={setFiles} onChange={onUpload} />
				<FileList files={files} removeFile={handleRemoveFileClick} />
			</>
		)
	}

	const ProjectHistories = ({histories}) => {
		return (
			<>
				<p className="heading">Lịch sử hoạt động</p>
				<Timeline>
					{
						histories.map((timeLine) => {
							return (
								<Fragment key={`timeLine-${timeLine.date}`}>
									<Timeline.Item dot={Icon.hourglass} color="red">{timeLine.date}</Timeline.Item>
									{
										timeLine.list.map((timeMessage) => {
											return (
												<Timeline.Item key={`timeLine-item-${timeMessage.id}`}>
													<p style={{marginTop : '10px'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`<b>${timeMessage.hour}</b>  ${timeMessage.message}`) }} />
												</Timeline.Item>
											)
										})
									}
								</Fragment>
							)
						})
					}
				</Timeline>
			</>
		)
	}

	const ProjectLicense = () => {
		return (
			<>
				<p className="heading">Thông tin kết nối server</p>
				<Row gutter={10} className="mb-1">
					<Col span={8}><b>Key</b></Col>
					<Col span={16}>{item?.licenseKey}</Col>
				</Row>
				<Row gutter={10} className="mb-1">
					<Col span={8}><b>Secret</b></Col>
					<Col span={16}>{item?.licenseSecret}</Col>
				</Row>

				<p className="heading">Thông tin admin</p>
				<Row gutter={10} className="mb-1">
					<Col span={8}><b>Tài khoản</b></Col>
					<Col span={16}>{item?.adminUser}</Col>
				</Row>
				<Row gutter={10} className="mb-1">
					<Col span={8}><b>Mật khẩu</b></Col>
					<Col span={16}>{item?.adminPass}</Col>
				</Row>
			</>
		)
	}

	const listTabs = [
		{
			key: 'projectInfo',
			label: `Thông tin`,
			children: <ProjectInfo />,
		},
		{
			key: 'projectDesign',
			label: 'Đặc Tả Thiết Kế',
			children: <ProjectDesign />,
		},
		{
			key: 'projectDev',
			label: 'Đặc Tả Lập Trình',
			children: <ProjectDev />,
		},
		{
			key: 'projectHistory',
			label: `Lịch sử`,
			children: <ProjectHistories histories={histories} />,
		},
		{
			key: 'projectLicense',
			label: `License`,
			children: <ProjectLicense />,
		},
	];

	return (
		<div className="form">
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" xs={24} sm={24} md={12}>
					<Tabs activeKey={activeKey} key={activeKey} items={listTabs} onTabClick={handleTabsClick}/>
				</Col>
				<Col className="gutter-row" xs={24} sm={24} md={12}><Comments type={'project'} objectId={item.id} /></Col>
			</Row>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				{can.edit && <Button background blue leftIcon={Icon.edit} onClick={handleEditClick}>Cập nhật</Button>}
				{
					(can.status && item.status == 'pending') && (<Button onClick={() => handleStatusClick('confirm')} background green leftIcon={Icon.success}>Duyệt dự án</Button>)
				}
			</div>
		</div>
	)
}

export default memo(ProjectFormInfo);